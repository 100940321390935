//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Simple Table Component

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { evaluateConditionLogic } from 'rfbp_core/components/logic'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableColumns,
  TsInterface_TableData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
  TsType_TableSize,
  TsType_TableSortBy,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_RootData_UserPermissions,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import { dynamicSort, exhaustiveSort, filterByPartialValue, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiButtonVariants } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  tableAdditionalData: TsInterface_TableAdditionalData
  tableColumns: TsInterface_TableColumns
  tableData: TsInterface_TableData
  tableSettings: TsInterface_TableSettings
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_MANY = <Trans>many</Trans>
const s_OF = <Trans>of</Trans>
const s_ROWS_PER_PAGE = <Trans>Rows per page:</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const TableBasic = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_tableAdditionalData: TsInterface_TableAdditionalData = getProp(props, 'tableAdditionalData', {})
  let pr_tableColumns: TsInterface_TableColumns = getProp(props, 'tableColumns', {})
  let pr_tableData: TsInterface_TableData = getProp(props, 'tableData', [])
  let pr_tableSettings: TsInterface_TableSettings = getProp(props, 'tableSettings', {})
  let pr_paginationRowsPerPageOptions: number[] = getProp(pr_tableSettings, 'pagination_rows_per_page_options', [10, 20, 30, 40, 50])
  let pr_rowCount: number = getProp(pr_tableSettings, 'row_count_override', pr_tableData.length) // or -1

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_collapsedColumns, us_setCollapsedColumns] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filterView, us_setFilterView] = useState<boolean>(false)
  const [us_rowsPerPage, us_setRowsPerPage] = useState(getProp(pr_tableSettings, 'pagination_rows_per_page_default', 20))
  const [us_searchProperty, us_setSearchProperty] = useState<string>('')
  const [us_searchValue, us_setSearchValue] = useState<string>('')
  const [us_searchView, us_setSearchView] = useState<boolean>(false)
  const [us_selectedPage, us_setSelectedPage] = useState(0)
  const [us_sortDirection, us_setSortDirection] = useState(getProp(pr_tableSettings, 'sort_direction', 'asc'))
  const [us_sortProperty, us_setSortProperty] = useState(getProp(pr_tableSettings, 'sort_property_default', null))
  const [us_tableSearchData, us_setTableSearchData] = useState<TsInterface_TableData>([])
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_AuthenticatedUser } = useContext(Context_RootData_AuthenticatedUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // TODO - TEMP
    us_setFilterView(false)
  }, [us_setFilterView])

  // Other Variables
  const tableHooks: TsInterface_TableHooks = {
    uc_RootData_AuthenticatedUser: uc_RootData_AuthenticatedUser,
    uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
    uc_RootData_UserPermissions: uc_RootData_UserPermissions,
    uc_RootData_ClientKey: uc_RootData_ClientKey,
    uc_RootData_ClientUser: uc_RootData_ClientUser,
    uc_RootData_GlobalUser: uc_RootData_GlobalUser,
    ur_forceRerender: ur_forceRerender,
    un_routerNavigation: un_routerNavigation,
    uc_setRootData_ClientKey: uc_setRootData_ClientKey,
    uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
  }

  // Functions
  const returnTableSize = (size: TsType_TableSize): TsType_TableSize => {
    if (size !== 'small' && size !== 'medium') {
      size = 'small'
    }
    return size
  }

  const returnHeaderCSS = (column: TsInterface_TableColumn, columnIndex: number): string => {
    let headerCSS = ''
    if (column != null && column.header != null && column.header.header_css != null) {
      headerCSS = column.header.header_css(pr_tableAdditionalData)
    }
    if (us_collapsedColumns[columnIndex] === true) {
      headerCSS += ' tw-px-1'
    }
    return headerCSS
  }

  const returnHeaderSX = (column: TsInterface_TableColumn, columnIndex: number): TsInterface_UnspecifiedObject => {
    let headerSX: TsInterface_UnspecifiedObject = {}
    let headerCSS = ''
    if (column != null && column.header != null && column.header.header_css != null) {
      headerCSS = column.header.header_css(pr_tableAdditionalData)
    }
    if (headerCSS === '' && getProp(pr_tableSettings, 'sticky_header', false) === true) {
      headerSX = { backgroundColor: themeVariables.background_paper }
    }
    return headerSX
  }

  const returnCellCSS = (column: TsInterface_TableColumn, columnIndex: number, rowData: TsInterface_TableDataRow): string => {
    let cellClassName = ''
    if (column != null && column.cell != null && column.cell.cell_css != null) {
      cellClassName = column.cell.cell_css(rowData, pr_tableAdditionalData)
    }
    if (pr_tableSettings != null && pr_tableSettings.conditional_row_styles != null) {
      for (let loopConditionIndex in pr_tableSettings.conditional_row_styles) {
        let loopCondition = pr_tableSettings.conditional_row_styles[loopConditionIndex]
        if (evaluateConditionLogic(loopCondition.conditional_display, { rowData: rowData, tableAdditionalData: pr_tableAdditionalData }) === true) {
          cellClassName += ' '
          cellClassName += loopCondition.className
        }
      }
    }
    if (us_collapsedColumns[columnIndex] === true) {
      cellClassName += ' tw-px-1'
    }
    return cellClassName
  }

  const returnTableHeadCellSortDirection = (column: TsInterface_TableColumn) => {
    if (
      us_sortProperty != null &&
      column != null &&
      column.header != null &&
      column.header.header_sort_by != null &&
      us_sortProperty === column.header.header_sort_by
    ) {
      return us_sortDirection
    } else {
      return undefined
    }
  }

  const updateTableSort = (newSortByProperty: TsType_TableSortBy): void => {
    if (newSortByProperty === us_sortProperty) {
      if (us_sortDirection === 'asc') {
        us_setSortDirection('desc')
      } else {
        us_setSortDirection('asc')
      }
    } else {
      us_setSortDirection('asc')
      us_setSortProperty(newSortByProperty)
    }
  }

  const updatePageChange = (newPageNumber: number) => {
    us_setSelectedPage(newPageNumber)
  }

  const updateRowsPerPageChange = (rowsPerPage: number) => {
    us_setRowsPerPage(rowsPerPage)
    us_setSelectedPage(0)
  }

  const updateSearchInput = (newSearchValue: string) => {
    us_setSearchValue(newSearchValue)
    if (us_searchProperty != null && us_searchProperty !== '' && newSearchValue != null && newSearchValue !== '') {
      us_setTableSearchData(filterByPartialValue(pr_tableData, us_searchProperty, newSearchValue))
    } else {
      us_setTableSearchData([])
    }
  }

  const returnSX_TableContainer = (): TsInterface_UnspecifiedObject => {
    let tableContainerSX: TsInterface_UnspecifiedObject = {}
    if (getProp(pr_tableSettings, 'sticky_header', null) != null) {
      tableContainerSX['maxHeight'] = getProp(pr_tableSettings, 'sticky_table_height', '100%')
    }
    return tableContainerSX
  }

  // JSX Generation
  const rJSX_TablePagination = (): JSX.Element => {
    let tablePaginationJSX = <></>
    if (pr_tableSettings.paginated === true) {
      tablePaginationJSX = (
        <Box>
          <Divider />
          <TablePagination
            component="div"
            count={pr_rowCount}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${s_OF.props.children} ${count !== -1 ? count : `${s_MANY.props.children}`}`
            }}
            labelRowsPerPage={s_ROWS_PER_PAGE}
            onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPageNumber: number) => {
              updatePageChange(newPageNumber)
            }}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              updateRowsPerPageChange(parseInt(event.target.value, 10))
            }}
            page={us_selectedPage}
            rowsPerPage={us_rowsPerPage}
            rowsPerPageOptions={pr_paginationRowsPerPageOptions}
          />
        </Box>
      )
    }
    return tablePaginationJSX
  }

  const returnColumnMinimization = (column: TsInterface_TableColumn, columnIndex: number): JSX.Element => {
    let iconJSX = <></>
    if (pr_tableSettings != null && pr_tableSettings['collapsible_columns'] === true) {
      if (us_collapsedColumns[columnIndex] !== true) {
        iconJSX = (
          <Tooltip
            title={rLIB('Minimize')}
            placement="top"
          >
            <Box
              className="tw-inline-block tw-opacity-5 hover:tw-opacity-100 tw-cursor-pointer tw-align-top"
              sx={{ marginTop: '2px', marginLeft: '4px' }}
              onClick={() => {
                if (us_collapsedColumns[columnIndex]) {
                  us_setCollapsedColumns({ ...us_collapsedColumns, [columnIndex]: false })
                } else {
                  us_setCollapsedColumns({ ...us_collapsedColumns, [columnIndex]: true })
                }
              }}
            >
              <Icon icon="square-minus" />
            </Box>
          </Tooltip>
        )
      } else {
        iconJSX = (
          <Tooltip
            title={column.header.header_jsx(pr_tableAdditionalData)}
            placement="top"
          >
            <Box
              className="tw-inline-block tw-cursor-pointer tw-align-top"
              sx={{ marginTop: '2px', rotate: '270deg' }}
              onClick={() => {
                if (us_collapsedColumns[columnIndex]) {
                  us_setCollapsedColumns({ ...us_collapsedColumns, [columnIndex]: false })
                } else {
                  us_setCollapsedColumns({ ...us_collapsedColumns, [columnIndex]: true })
                }
              }}
            >
              <Icon icon="square-caret-down" />
            </Box>
          </Tooltip>
        )
      }
    }
    return iconJSX
  }

  const rJSX_TableHeadCell = (column: TsInterface_TableColumn, columnIndex: number): JSX.Element => {
    let tableHeadCellJSX = <></>
    if (us_collapsedColumns[columnIndex] !== true) {
      if (pr_tableSettings.sortable === true && column.header.header_sort_by != null) {
        tableHeadCellJSX = (
          <>
            <TableSortLabel
              active={us_sortProperty === column.header.header_sort_by}
              direction={us_sortDirection}
              onClick={() => {
                updateTableSort(column.header.header_sort_by)
              }}
            >
              {column.header.header_jsx(pr_tableAdditionalData)}
            </TableSortLabel>
            {returnColumnMinimization(column, columnIndex)}
          </>
        )
      } else {
        tableHeadCellJSX = (
          <>
            {column.header.header_jsx(pr_tableAdditionalData)}
            {returnColumnMinimization(column, columnIndex)}
          </>
        )
      }
    } else {
      tableHeadCellJSX = returnColumnMinimization(column, columnIndex)
    }
    return tableHeadCellJSX
  }

  const rJSX_TableHead = (): JSX.Element => {
    let tableHeadJSX = <TableHead></TableHead>
    if (pr_tableSettings.show_header !== false) {
      tableHeadJSX = (
        <TableHead>
          <TableRow>
            {objectToArray(pr_tableColumns).map((column: TsInterface_TableColumn, columnIndex: number) => (
              <TableCell
                key={columnIndex}
                className={returnHeaderCSS(column, columnIndex)}
                sx={returnHeaderSX(column, columnIndex)}
                sortDirection={returnTableHeadCellSortDirection(column)}
              >
                {rJSX_TableHeadCell(column, columnIndex)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )
    }
    return tableHeadJSX
  }

  const rJSX_CellContent = (row: TsInterface_TableDataRow, column: TsInterface_TableColumn, columnIndex: number): JSX.Element => {
    let cellContentJSX = <></>
    if (us_collapsedColumns[columnIndex] !== true) {
      cellContentJSX = column.cell.cell_jsx(row, pr_tableAdditionalData, tableHooks)
    }
    return cellContentJSX
  }

  const rJSX_TableBody = (): JSX.Element => {
    let rowData: TsInterface_TableData = []
    let searchable = getProp(pr_tableSettings, 'searchable', false)
    if (
      searchable === true &&
      us_searchView === true &&
      us_searchProperty != null &&
      us_searchProperty !== '' &&
      us_searchValue != null &&
      us_searchValue !== ''
    ) {
      // rowData = tableSearchData.sort( dynamicSort( sortProperty, sortDirection ))
      if (pr_tableSettings.paginated === true) {
        rowData = exhaustiveSort(objectToArray(us_tableSearchData), us_sortProperty, us_sortDirection).slice(
          us_selectedPage * us_rowsPerPage,
          us_selectedPage * us_rowsPerPage + us_rowsPerPage,
        )
      } else {
        rowData = us_tableSearchData.sort(dynamicSort(us_sortProperty, us_sortDirection))
      }
    } else {
      // rowData = tableData.sort( dynamicSort( sortProperty, sortDirection ))
      if (pr_tableSettings.paginated === true) {
        rowData = exhaustiveSort(objectToArray(pr_tableData), us_sortProperty, us_sortDirection).slice(
          us_selectedPage * us_rowsPerPage,
          us_selectedPage * us_rowsPerPage + us_rowsPerPage,
        )
      } else {
        rowData = pr_tableData.sort(dynamicSort(us_sortProperty, us_sortDirection))
      }
    }
    let tableBodyJSX = (
      <TableBody>
        {rowData.map((row: TsInterface_TableDataRow, rowIndex: number) => (
          <TableRow
            key={rowIndex}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            {objectToArray(pr_tableColumns).map((column: TsInterface_TableColumn, columnIndex: number) => (
              <TableCell
                onClick={() => {
                  if (column.cell.cell_onClick != null) {
                    column.cell.cell_onClick(row, pr_tableAdditionalData, tableHooks)
                  }
                }}
                key={columnIndex}
                className={returnCellCSS(column, columnIndex, row)}
              >
                {rJSX_CellContent(row, column, columnIndex)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    )
    return tableBodyJSX
  }

  const rJSX_SearchButton = (): JSX.Element => {
    let searchSettings = getProp(pr_tableSettings, 'search_settings_basic', {})
    let searchButtonColor = getProp(searchSettings, 'search_button_color', 'info')
    let searchButtonIcon = getProp(
      searchSettings,
      'search_button_icon',
      <Icon
        icon="magnifying-glass"
        type="solid"
      />,
    )
    let buttonVariant: TsType_MuiButtonVariants = 'outlined'
    if (us_searchView === true) {
      buttonVariant = 'contained'
    }
    let buttonDisabled = false
    if (us_filterView === true) {
      buttonDisabled = true
    }
    // SX
    let buttonSX: TsInterface_UnspecifiedObject = { minHeight: '36.5px', width: '50px', minWidth: '50px', paddingLeft: '25px' }
    // JSX
    let buttonJSX = (
      <Button
        sx={buttonSX}
        variant={buttonVariant}
        disabled={buttonDisabled}
        color={searchButtonColor}
        startIcon={searchButtonIcon}
        className="tw-mr-2"
        onClick={() => {
          if (us_searchView === true) {
            us_setSearchValue('')
            us_setSearchProperty('')
          }
          us_setSearchView(!us_searchView)
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_SearchPropertyDropdown = (): JSX.Element => {
    // Variables
    let searchSettings = getProp(pr_tableSettings, 'search_settings_basic', {})
    let searchPropertyOptions = getProp(searchSettings, 'search_property_options', [])
    // JSX
    let dropdownJSX = (
      <FormControl
        sx={{ marginTop: 0, marginBottom: 0 }}
        className="tw-mr-2 bp_thin_select_input"
      >
        <Select
          color="primary"
          value={us_searchProperty}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSearchProperty(event.target.value)
            }
          }}
          variant="outlined"
        >
          {searchPropertyOptions.map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_SearchTextInput = (): JSX.Element => {
    // Variables
    let searchSettings = getProp(pr_tableSettings, 'search_settings_basic', {})
    let searchButtonText = getProp(searchSettings, 'search_input_text', 'Search')
    let searchButtonIcon = getProp(
      searchSettings,
      'search_button_icon',
      <Icon
        icon="magnifying-glass"
        type="solid"
      />,
    )
    // JSX
    let inputJSX = (
      <FormControl sx={{ marginTop: 0, marginBottom: 0 }}>
        <TextField
          disabled={us_searchProperty === '' || us_searchProperty == null}
          className="bp_thin_text_input"
          sx={{ marginTop: 0, marginBottom: 0 }}
          color="primary"
          value={us_searchValue}
          margin="normal"
          placeholder={searchButtonText}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              updateSearchInput(event.target.value)
            }
          }}
          onBlur={(event: any) => {
            // if ( event != null && event.target != null && event.target.value != null ){
            // 	updateSearchInput( event.target.value )
            // }
          }}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">{searchButtonIcon}</InputAdornment>,
          }}
        />
      </FormControl>
    )
    return inputJSX
  }

  const rJSX_SearchInputs = (): JSX.Element => {
    // JSX
    let searchInputsJSX = <></>
    if (us_searchProperty == null || us_searchProperty === '') {
      searchInputsJSX = <Box className="tw-inline-block">{rJSX_SearchPropertyDropdown()}</Box>
    } else {
      searchInputsJSX = (
        <Box className="tw-inline-block">
          {rJSX_SearchPropertyDropdown()}
          {rJSX_SearchTextInput()}
        </Box>
      )
    }
    return searchInputsJSX
  }

  // const rJSX_FilterButton = (): JSX.Element => {
  // let filterSettings = getProp( tableSettings, "filter_settings", {} )
  // let filterButtonColor = getProp( filterSettings, "filter_button_color", "warning" )
  // let filterButtonIcon = getProp( filterSettings, "filter_button_icon", <Icon icon="filter" type="regular" /> )
  // let buttonVariant: TsType_MuiButtonVariants = "outlined"
  // if( filterView === true ){ buttonVariant = "contained" }
  // let buttonDisabled = false
  // if( searchView === true ){ buttonDisabled = true }
  // // SX
  // let buttonSX: TsInterface_UnspecifiedObject = { minHeight: "36.5px", width: "50px", minWidth: "50px", paddingLeft: "25px" }
  // // JSX
  // let buttonJSX =
  // <Button
  // 	sx={ buttonSX }
  // 	variant={ buttonVariant }
  // 	disabled={ buttonDisabled }
  // 	color={ filterButtonColor }
  // 	startIcon={ filterButtonIcon }
  // 	className="tw-mr-2"
  // 	onClick={ () => {
  // 		if( filterView === true ){

  // 			// TODO - wipe filter selections

  // 		}
  // 		setFilterView( !filterView )
  // 	}}
  // />
  // return buttonJSX
  // }

  // TODO
  // const rJSX_FilterInputs = (): JSX.Element => {
  // let filterInputsJSX = <></>

  // // TODO

  // return filterInputsJSX
  // }

  // TODO
  const rJSX_SearchAndFilterButtons = (): JSX.Element => {
    // Buttons
    let buttonsJSX = <></>
    let searchable = getProp(pr_tableSettings, 'searchable', false)
    let filterable = getProp(pr_tableSettings, 'filterable', false)
    let hasButtonsOrInputs = false
    if (searchable === true && filterable === true) {
      buttonsJSX = (
        <Box className="tw-inline-block">
          {rJSX_SearchButton()}
          {/* { rJSX_FilterButton() } */}
        </Box>
      )
      hasButtonsOrInputs = true
    } else if (searchable === true && filterable === false) {
      buttonsJSX = <Box className="tw-inline-block">{rJSX_SearchButton()}</Box>
      hasButtonsOrInputs = true
    } else if (searchable === false && filterable === true) {
      buttonsJSX = <Box className="tw-inline-block">{/* { rJSX_FilterButton() } */}</Box>
      hasButtonsOrInputs = true
    }
    // Inputs
    let inputsJSX = <></>
    if (us_searchView === true) {
      inputsJSX = <Box className="tw-inline-block tw-align-top">{rJSX_SearchInputs()}</Box>
      hasButtonsOrInputs = true
    }
    if (us_filterView === true) {
      inputsJSX = <Box className="tw-inline-block">{/* { rJSX_FilterInputs() } */}</Box>
      hasButtonsOrInputs = true
    }
    // Combined
    let searchAndFilterJSX = <></>
    if (hasButtonsOrInputs === true) {
      searchAndFilterJSX = (
        <Box className="tw-p-2">
          {buttonsJSX}
          {inputsJSX}
        </Box>
      )
    }
    return searchAndFilterJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <></>
    if (pr_rowCount === 0 && us_selectedPage === 0 && objectToArray(pr_tableData).length === 0 && pr_tableSettings.no_data_message != null) {
      // No Data for Default Query
      componentJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography variant="h5">{pr_tableSettings.no_data_message}</Typography>
        </Box>
      )
    } else if (
      us_searchProperty != null &&
      us_searchProperty !== '' &&
      us_searchValue != null &&
      us_searchValue !== '' &&
      us_tableSearchData.length === 0 &&
      pr_tableSettings.search_settings_basic != null &&
      pr_tableSettings.search_settings_basic.search_no_data_message != null
    ) {
      // Using Search and No Data for Search
      componentJSX = (
        <Box>
          {rJSX_SearchAndFilterButtons()}
          <Box className="tw-p-4 tw-text-center">
            <Typography variant="h5">{pr_tableSettings.search_settings_basic.search_no_data_message}</Typography>
          </Box>
        </Box>
      )
    } else {
      // Not Searching - has pagination
      componentJSX = (
        <Box>
          {rJSX_SearchAndFilterButtons()}
          <TableContainer
            className="bp_show_horizontal_scroll"
            sx={returnSX_TableContainer()}
          >
            <Table
              stickyHeader={getProp(pr_tableSettings, 'sticky_header', false)}
              aria-label="table"
              size={returnTableSize(pr_tableSettings.size)}
              sx={{ width: 'max-content', minWidth: '100%' }}
            >
              {rJSX_TableHead()}
              {rJSX_TableBody()}
            </Table>
          </TableContainer>
          {rJSX_TablePagination()}
        </Box>
      )
    }
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
