///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Stack, Tooltip, Typography } from '@mui/material/'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesDoorhangerRoutePage']['key']

// TEMP
const routeOptimizationRequest = {
  parent: 'projects/etw-energy-staging',
  model: {
    globalStartTime: {
      _seconds: 1722470400,
      _nanoseconds: 0,
    },
    globalEndTime: {
      _seconds: 1722556800,
      _nanoseconds: 0,
    },
    shipments: [
      {
        label: 'Shipment 0',
        pickups: [],
        deliveries: [
          {
            id: 'task-0',
            label: 'Task 0',
            arrivalLocation: {
              latitude: 41.7152115,
              longitude: -88.223374,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '2623',
                street: 'DREXEL LN',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: '187a94abbbfa250d',
              },
              geometry: { type: 'Point', coordinates: [-88.223374, 41.7152115] },
            },
          },
        ],
      },
      {
        label: 'Shipment 1',
        pickups: [],
        deliveries: [
          {
            id: 'task-1',
            label: 'Task 1',
            arrivalLocation: {
              latitude: 41.7477315,
              longitude: -88.1178694,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '864',
                street: 'HEATHERFIELD CR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60565',
                state: 'IL',
                hash: '52609f1b040d1db2',
              },
              geometry: { type: 'Point', coordinates: [-88.1178694, 41.7477315] },
            },
          },
        ],
      },
      {
        label: 'Shipment 2',
        pickups: [],
        deliveries: [
          {
            id: 'task-2',
            label: 'Task 2',
            arrivalLocation: {
              latitude: 41.6922958,
              longitude: -88.2063644,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '4197',
                street: 'ROYAL MEWS CR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: 'ce37a6e20d378780',
              },
              geometry: { type: 'Point', coordinates: [-88.2063644, 41.6922958] },
            },
          },
        ],
      },
      {
        label: 'Shipment 3',
        pickups: [],
        deliveries: [
          {
            id: 'task-3',
            label: 'Task 3',
            arrivalLocation: {
              latitude: 41.7199507,
              longitude: -88.1737444,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '1294',
                street: 'MEYER CT',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: 'bc04431853b7a9e9',
              },
              geometry: { type: 'Point', coordinates: [-88.1737444, 41.7199507] },
            },
          },
        ],
      },
      {
        label: 'Shipment 4',
        pickups: [],
        deliveries: [
          {
            id: 'task-4',
            label: 'Task 4',
            arrivalLocation: {
              latitude: 41.7299173,
              longitude: -88.0986184,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '1635',
                street: 'PATHWAY DR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60565',
                state: 'IL',
                hash: '235e0cd44ea1dbae',
              },
              geometry: { type: 'Point', coordinates: [-88.0986184, 41.7299173] },
            },
          },
        ],
      },
      {
        label: 'Shipment 5',
        pickups: [],
        deliveries: [
          {
            id: 'task-5',
            label: 'Task 5',
            arrivalLocation: {
              latitude: 41.7229145,
              longitude: -88.1257681,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '1635',
                street: 'PATHWAY DR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60565',
                state: 'IL',
                hash: '235e0cd44ea1dbae',
              },
              geometry: { type: 'Point', coordinates: [-88.0986184, 41.7299173] },
            },
          },
        ],
      },
      {
        label: 'Shipment 6',
        pickups: [],
        deliveries: [
          {
            id: 'task-6',
            label: 'Task 6',
            arrivalLocation: {
              latitude: 41.790547,
              longitude: -88.2191332,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '1501',
                street: 'FAIRWAY DR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60563',
                state: 'IL',
                hash: 'd7af81abb53962b5',
              },
              geometry: { type: 'Point', coordinates: [-88.2191332, 41.790547] },
            },
          },
        ],
      },
      {
        label: 'Shipment 7',
        pickups: [],
        deliveries: [
          {
            id: 'task-7',
            label: 'Task 7',
            arrivalLocation: {
              latitude: 41.7002987,
              longitude: -88.2023847,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '2828',
                street: 'LANCELOT LN',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: 'bb1f0f7d61fab6a2',
              },
              geometry: { type: 'Point', coordinates: [-88.2023847, 41.7002987] },
            },
          },
        ],
      },
      {
        label: 'Shipment 8',
        pickups: [],
        deliveries: [
          {
            id: 'task-8',
            label: 'Task 8',
            arrivalLocation: {
              latitude: 41.6960761,
              longitude: -88.2012733,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '3723',
                street: 'QUICK FIRE DR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: '0cced47552f4af07',
              },
              geometry: { type: 'Point', coordinates: [-88.2012733, 41.6960761] },
            },
          },
        ],
      },
      {
        label: 'Shipment 9',
        pickups: [],
        deliveries: [
          {
            id: 'task-9',
            label: 'Task 9',
            arrivalLocation: {
              latitude: 41.6999567,
              longitude: -88.1985562,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '3644',
                street: 'LIME LIGHT DR',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: '675ee2fc31eb8d70',
              },
              geometry: { type: 'Point', coordinates: [-88.1985562, 41.6999567] },
            },
          },
        ],
      },
      {
        label: 'Shipment 10',
        pickups: [],
        deliveries: [
          {
            id: 'task-10',
            label: 'Task 10',
            arrivalLocation: {
              latitude: 41.7101769,
              longitude: -88.2082311,
            },
            visitDuration: {
              seconds: 60,
            },
            address: {
              type: 'Feature',
              properties: {
                id: '',
                unit: '',
                number: '3056',
                street: 'ANNA MARIE LN',
                city: 'NAPERVILLE',
                district: '',
                region: '',
                postcode: '60564',
                state: 'IL',
                hash: '65dfe4f34c680603',
              },
              geometry: { type: 'Point', coordinates: [-88.2082311, 41.7101769] },
            },
          },
        ],
      },
    ],
    vehicles: [
      {
        id: 'vehicle-0',
        label: 'Vehicle 0',
        startLocation: {
          latitude: 41.7013518,
          longitude: -88.1990534,
        },
        endLocation: {
          latitude: 41.7013518,
          longitude: -88.1990534,
        },
        costPerKilometer: 1,
        capacity: 1000,
      },
    ],
    globalDurationCostPerHour: 1,
  },
}
const routeOptimizationResults = {
  routes: [
    {
      visits: [
        {
          loadDemands: {},
          shipmentIndex: 9,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722470428',
            nanos: 0,
          },
          detour: {
            seconds: '0',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 9',
          visitLabel: 'Task 9',
        },
        {
          loadDemands: {},
          shipmentIndex: 7,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722470493',
            nanos: 0,
          },
          detour: {
            seconds: '0',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 7',
          visitLabel: 'Task 7',
        },
        {
          loadDemands: {},
          shipmentIndex: 3,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722470962',
            nanos: 0,
          },
          detour: {
            seconds: '48',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 3',
          visitLabel: 'Task 3',
        },
        {
          loadDemands: {},
          shipmentIndex: 5,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722471516',
            nanos: 0,
          },
          detour: {
            seconds: '259',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 5',
          visitLabel: 'Task 5',
        },
        {
          loadDemands: {},
          shipmentIndex: 4,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722471800',
            nanos: 0,
          },
          detour: {
            seconds: '382',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 4',
          visitLabel: 'Task 4',
        },
        {
          loadDemands: {},
          shipmentIndex: 1,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722472143',
            nanos: 0,
          },
          detour: {
            seconds: '666',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 1',
          visitLabel: 'Task 1',
        },
        {
          loadDemands: {},
          shipmentIndex: 6,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722473390',
            nanos: 0,
          },
          detour: {
            seconds: '1922',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 6',
          visitLabel: 'Task 6',
        },
        {
          loadDemands: {},
          shipmentIndex: 10,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722474317',
            nanos: 0,
          },
          detour: {
            seconds: '3627',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 10',
          visitLabel: 'Task 10',
        },
        {
          loadDemands: {},
          shipmentIndex: 0,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722474610',
            nanos: 0,
          },
          detour: {
            seconds: '3728',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 0',
          visitLabel: 'Task 0',
        },
        {
          loadDemands: {},
          shipmentIndex: 2,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722475020',
            nanos: 0,
          },
          detour: {
            seconds: '4419',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 2',
          visitLabel: 'Task 2',
        },
        {
          loadDemands: {},
          shipmentIndex: 8,
          isPickup: false,
          visitRequestIndex: 0,
          startTime: {
            seconds: '1722475165',
            nanos: 0,
          },
          detour: {
            seconds: '4629',
            nanos: 0,
          },
          shipmentLabel: 'Shipment 8',
          visitLabel: 'Task 8',
        },
      ],
      transitions: [
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '28',
            nanos: 0,
          },
          travelDistanceMeters: 145,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '28',
            nanos: 0,
          },
          startTime: {
            seconds: '1722470400',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '65',
            nanos: 0,
          },
          travelDistanceMeters: 402,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '65',
            nanos: 0,
          },
          startTime: {
            seconds: '1722470428',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '469',
            nanos: 0,
          },
          travelDistanceMeters: 5021,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '469',
            nanos: 0,
          },
          startTime: {
            seconds: '1722470493',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '554',
            nanos: 0,
          },
          travelDistanceMeters: 5650,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '554',
            nanos: 0,
          },
          startTime: {
            seconds: '1722470962',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '284',
            nanos: 0,
          },
          travelDistanceMeters: 3453,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '284',
            nanos: 0,
          },
          startTime: {
            seconds: '1722471516',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '343',
            nanos: 0,
          },
          travelDistanceMeters: 3575,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '343',
            nanos: 0,
          },
          startTime: {
            seconds: '1722471800',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '1247',
            nanos: 0,
          },
          travelDistanceMeters: 14190,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '1247',
            nanos: 0,
          },
          startTime: {
            seconds: '1722472143',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '927',
            nanos: 0,
          },
          travelDistanceMeters: 10228,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '927',
            nanos: 0,
          },
          startTime: {
            seconds: '1722473390',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '293',
            nanos: 0,
          },
          travelDistanceMeters: 2333,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '293',
            nanos: 0,
          },
          startTime: {
            seconds: '1722474317',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '410',
            nanos: 0,
          },
          travelDistanceMeters: 4445,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '410',
            nanos: 0,
          },
          startTime: {
            seconds: '1722474610',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '145',
            nanos: 0,
          },
          travelDistanceMeters: 832,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '145',
            nanos: 0,
          },
          startTime: {
            seconds: '1722475020',
            nanos: 0,
          },
          routePolyline: null,
        },
        {
          vehicleLoads: {},
          travelDuration: {
            seconds: '163',
            nanos: 0,
          },
          travelDistanceMeters: 814,
          trafficInfoUnavailable: false,
          delayDuration: null,
          breakDuration: null,
          waitDuration: {
            seconds: '0',
            nanos: 0,
          },
          totalDuration: {
            seconds: '163',
            nanos: 0,
          },
          startTime: {
            seconds: '1722475165',
            nanos: 0,
          },
          routePolyline: null,
        },
      ],
      breaks: [],
      routeCosts: {
        'model.vehicles.cost_per_kilometer': 51.088,
      },
      vehicleIndex: 0,
      vehicleLabel: 'Vehicle 0',
      vehicleStartTime: {
        seconds: '1722470400',
        nanos: 0,
      },
      vehicleEndTime: {
        seconds: '1722475328',
        nanos: 0,
      },
      hasTrafficInfeasibilities: false,
      routePolyline: null,
      metrics: {
        maxLoads: {},
        performedShipmentCount: 11,
        travelDuration: {
          seconds: '4928',
          nanos: 0,
        },
        waitDuration: {
          seconds: '0',
          nanos: 0,
        },
        delayDuration: {
          seconds: '0',
          nanos: 0,
        },
        breakDuration: {
          seconds: '0',
          nanos: 0,
        },
        visitDuration: {
          seconds: '0',
          nanos: 0,
        },
        totalDuration: {
          seconds: '4928',
          nanos: 0,
        },
        travelDistanceMeters: 51088,
      },
      routeTotalCost: 51.088,
    },
  ],
  skippedShipments: [],
  validationErrors: [],
  requestLabel: '',
  metrics: {
    costs: {
      'model.vehicles.cost_per_kilometer': 51.088,
      'model.global_duration_cost_per_hour': 1.3688888888888888,
    },
    aggregatedRouteMetrics: {
      maxLoads: {},
      performedShipmentCount: 11,
      travelDuration: {
        seconds: '4928',
        nanos: 0,
      },
      waitDuration: {
        seconds: '0',
        nanos: 0,
      },
      delayDuration: {
        seconds: '0',
        nanos: 0,
      },
      breakDuration: {
        seconds: '0',
        nanos: 0,
      },
      visitDuration: {
        seconds: '0',
        nanos: 0,
      },
      totalDuration: {
        seconds: '4928',
        nanos: 0,
      },
      travelDistanceMeters: 51088,
    },
    skippedMandatoryShipmentCount: 0,
    usedVehicleCount: 1,
    earliestVehicleStartTime: {
      seconds: '1722470400',
      nanos: 0,
    },
    latestVehicleEndTime: {
      seconds: '1722475328',
      nanos: 0,
    },
    totalCost: 52.45688888888889,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const parseRouteOptimizationResults = (routeOptimizationRoute: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let steps: TsInterface_UnspecifiedObject[] = []
  if (routeOptimizationRoute != null && routeOptimizationRoute.visits != null && routeOptimizationRoute.transitions != null) {
    for (let i = 0; i < routeOptimizationRoute.visits.length; i++) {
      let visit = routeOptimizationRoute.visits[i]
      let transition = routeOptimizationRoute.transitions[i]
      let matchingRequestStop: TsInterface_UnspecifiedObject = {}
      for (let loopIndex in routeOptimizationRequest.model.shipments) {
        let shipment = routeOptimizationRequest.model.shipments[loopIndex]
        for (let deliveryIndex in shipment.deliveries) {
          let delivery = shipment.deliveries[deliveryIndex]
          if (visit.shipmentLabel === shipment.label && visit.visitLabel === delivery.label) {
            matchingRequestStop = delivery
          }
        }
      }
      let cleanVisit = {
        stop_index: i,
        type: 'stop',
        latitude: matchingRequestStop.arrivalLocation.latitude,
        longitude: matchingRequestStop.arrivalLocation.longitude,
        address: matchingRequestStop.address.properties.number + ' ' + matchingRequestStop.address.properties.street,
        city: matchingRequestStop.address.properties.city,
        state: matchingRequestStop.address.properties.state,
        zip: matchingRequestStop.address.properties.postcode,
      }
      let cleanTransition = {
        stop_index: i,
        type: 'travel',
        // Miles from meters
        distance: transition.travelDistanceMeters / 1609.34,
        time: transition.totalDuration.seconds / 60,
      }
      if (i === 0) {
        // First step
        steps.push(cleanVisit)
      } else if (i === routeOptimizationRoute.visits.length - 1) {
        // Last Stop
        steps.push(cleanTransition)
        steps.push(cleanVisit)
      } else {
        // Middle Steps
        steps.push(cleanTransition)
        steps.push(cleanVisit)
      }
    }
  }

  return steps
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  // { sort-start } - hooks
  const [us_completedStops, us_setCompletedStops] = useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Paper Route', false) as string
  }, [])

  // Functions
  const openMap = (latitude: number, longitude: number, address: string, city: string, state: string, zip: string) => {
    const formattedAddress = `${encodeURIComponent(address)},${encodeURIComponent(city)},${encodeURIComponent(state)},${encodeURIComponent(zip)}`

    // Check if Google Maps is available
    const googleMapsUrl = `comgooglemaps://?daddr=${latitude},${longitude}(${formattedAddress})&directionsmode=driving`
    const appleMapsUrl = `http://maps.apple.com/?daddr=${latitude},${longitude}(${formattedAddress})&dirflg=d`

    // Create a temporary link element to test if Google Maps is available
    const link = document.createElement('a')
    link.href = googleMapsUrl

    // Try opening Google Maps first
    link.onerror = () => {
      // If there's an error (Google Maps is not available), open Apple Maps
      window.open(appleMapsUrl, '_blank')
    }

    // Try opening Google Maps
    window.open(googleMapsUrl, '_blank')
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Orders')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_RouteSteps = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let stepJSX = <></>
    if (step.type === 'stop') {
      stepJSX = (
        <Box
          sx={{
            border: '1px solid rgba(255,255,255,0.3)',
            backgroundColor: us_completedStops[step.stop_index] ? themeVariables.success_main : themeVariables.background_paper,
          }}
          className="tw-m-2 tw-p-4 tw-rounded"
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Box
                className="tw-mr-1"
                sx={{ fontSize: '36px', color: themeVariables.error_main, fontWeight: 'bold' }}
              >
                {step.stop_index + 1}
              </Box>
              {/* <Icon
                icon="map-pin"
                sx={{ fontSize: '24px', color: themeVariables.error_main }}
              /> */}
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: themeVariables.white }}
                >
                  {step.address}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-30"
                  sx={{ color: themeVariables.white }}
                >
                  {step.city}
                </Typography>
              </Box>
            </Stack>
            <Box>
              {us_completedStops[step.stop_index] !== true ? (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  className="bp_icon_only_button tw-mr-2"
                  sx={{ margin: '0px' }}
                  disableElevation
                  onClick={() => {
                    // Open Map
                    openMap(step.latitude, step.longitude, step.address, step.city, step.state, step.zip)
                  }}
                >
                  <Icon icon="map" />
                </Button>
              ) : (
                <></>
              )}

              <Button
                variant={us_completedStops[step.stop_index] ? 'contained' : 'outlined'}
                color="success"
                size="small"
                className="bp_icon_only_button"
                sx={{ margin: '0px' }}
                disableElevation
                onClick={() => {
                  if (us_completedStops[step.stop_index] === true) {
                    us_setCompletedStops({ ...us_completedStops, [step.stop_index]: false })
                  } else {
                    us_setCompletedStops({ ...us_completedStops, [step.stop_index]: true })
                  }
                }}
              >
                <Icon icon="check-circle" />
              </Button>
            </Box>
          </Stack>
        </Box>
      )
    } else if (step.type === 'travel') {
      stepJSX = (
        <Box
          sx={{
            border: '1px solid rgba(255,255,255,0.3)',
            backgroundColor: us_completedStops[step.stop_index] ? themeVariables.success_main : themeVariables.background_default,
          }}
          className="tw-m-2 tw-p-4 tw-rounded"
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Icon
              icon="directions"
              sx={{ fontSize: '24px', color: themeVariables.warning_main }}
            />
            <Box>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.white }}
              >
                {step.distance.toFixed(1)} {rLIB('Miles')}
                <Box
                  component={'span'}
                  sx={{ color: themeVariables.white, opacity: 0.4, marginLeft: '8px' }}
                >
                  ({step.time.toFixed(1)} {rLIB('min')})
                </Box>
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    }
    return stepJSX
  }

  const rJSX_RouteList = (): JSX.Element => {
    let steps = parseRouteOptimizationResults(routeOptimizationResults.routes[0])
    let routeListJSX = (
      <Box>
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          {steps.map((step: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_RouteSteps(step)}</Box>
          ))}
        </Box>
      </Box>
    )
    return routeListJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Paper Route')}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton(false)}
            {rJSX_RouteList()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
