///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_Address_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Functions
///////////////////////////////

export const us_tableSettings_DiscoverySessionAddresses: TsInterface_TableSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        comparator: '==',
        conditions: [],
        logic_type: 'comparison',
        prop: 'status',
        source: 'rowData',
        value: 'deleted',
      },
    },
  ],
  size: 'small',
  sortable: true,
  sort_property_default: 'address',
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
}

export const tableColumns_DiscoverySessionAddresses: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(rowData.key as string))
        }
      },
    },
    geocode: {
      icon: (
        <Icon
          type="solid"
          icon="map-location-dot"
        />
      ),
      conditional_disable: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'location_latitude',
            comparator: '!=',
            value: null,
            conditions: [],
          },
        ],
      },
      label: <>{rLIB('Geocode Address')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let address = rowData.location_address + ' ' + rowData.location_city + ' ' + rowData.location_state + ' ' + rowData.location_zip
          let geocoder = new google.maps.Geocoder()
          geocoder.geocode({ address: address }, (results: any, status: any) => {
            // If there is a result
            if (status === 'OK') {
              // Set Latitude and Longitude
              let updateObject = {
                location_latitude: results[0].geometry.location.lat(),
                location_longitude: results[0].geometry.location.lng(),
              }
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(
                    DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                      res_GCK.clientKey,
                      tableAdditionalData.discoverySessionKey,
                      rowData.key as string,
                    ),
                    updateObject,
                  )
                    .then((res_DDD) => {
                      // console.log(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              console.error('Failed to calculate coordinates')
            }
          })
        }
      },
    },
    generate_google_solar_data: {
      icon: (
        <Icon
          type="solid"
          icon="solar-panel"
        />
      ),
      conditional_disable: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'calculated_google_solar_data',
            comparator: '==',
            value: true,
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'location_latitude',
            comparator: '==',
            value: null,
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'location_longitude',
            comparator: '==',
            value: null,
            conditions: [],
          },
        ],
      },
      label: <>{rLIB('Calculate Google Solar Data')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              cloudFunctionManageRequest('manageData', {
                function: 'run_google_solar_request_for_coordinates',
                latitude: rowData.location_latitude,
                longitude: rowData.location_longitude,
              })
                .then((res_CFMR) => {
                  console.log(res_CFMR)
                  let googleSolarUpdateObject = getProp(res_CFMR, 'data', {})
                  let addressUpdateObject = {
                    calculated_google_solar_data: true,
                  }
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.discoverySessionKey,
                        rowData.key as string,
                      ),
                      data: addressUpdateObject,
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.discoverySessionKey,
                        rowData.key as string,
                      ),
                      data: googleSolarUpdateObject,
                    },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      // console.log(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      // console.error(rej_DBU)
                    })
                })
                .catch((rej_CFMR) => {
                  console.error(rej_CFMR)
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMR.error })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
    view_google_solar_data: {
      icon: (
        <Icon
          type="solid"
          icon="house-day"
        />
      ),
      conditional_disable: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'calculated_google_solar_data',
            comparator: '!=',
            value: true,
            conditions: [],
          },
        ],
      },
      label: <>{rLIB('View Google Solar Data')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              DatabaseGetDocument(
                DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document(
                  res_GCK.clientKey,
                  tableAdditionalData.discoverySessionKey,
                  rowData.key as string,
                ),
              ).then((res_DGD) => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Json
                        data={getProp(res_DGD, 'data', {})}
                        alphabetized={true}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
            })
            .catch((rej_DGD) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
    undelete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="wand-magic-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Address'),
              icon: (
                <Icon
                  icon="wand-magic-sparkles"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this address?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'active', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.discoverySessionKey,
                          rowData.key as string,
                        ),
                        updateObject,
                      )
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Address'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this address?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'deleted', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.discoverySessionKey,
                          rowData.key as string,
                        ),
                        updateObject,
                      )
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  TEMP_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'color',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let color = themeVariables.gray_700
        switch (rowData.color) {
          case 'red':
            color = themeVariables.error_main
            break
          case 'green':
            color = themeVariables.success_main
            break
          case 'yellow':
            color = themeVariables.warning_main
            break
          default:
            color = themeVariables.gray_700
        }
        let colorCircleIconJSX = (
          <Icon
            icon="circle"
            type="solid"
            sx={{ color: color, fontSize: '18px' }}
            className="tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: {
                      color: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'color',
                        label: rLIB('Color'),
                        required: true,
                        options: [
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.error_main }}
                              />
                            ),
                            key: 'red',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.warning_main }}
                              />
                            ),
                            key: 'yellow',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.success_main }}
                              />
                            ),
                            key: 'green',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.gray_700 }}
                              />
                            ),
                            key: 'gray',
                          },
                        ],
                        submit_on_change: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.discoverySessionKey,
                                rowData.key as string,
                              ),
                              formSubmittedData,
                            )
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Address Color')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let solarCalculatedIconJSX = <></>
        if (rowData.calculated_google_solar_data === true) {
          solarCalculatedIconJSX = (
            <Icon
              icon="solar-panel"
              type="solid"
              className="tw-ml-3 tw-cursor-pointer"
              tooltip={rLIB('Solar Data Calculated')}
              onClick={() => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseGetDocument(
                      DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.discoverySessionKey,
                        rowData.key as string,
                      ),
                    ).then((res_DGD) => {
                      tableHooks.uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: (
                            <Json
                              data={getProp(res_DGD, 'data', {})}
                              alphabetized={true}
                            />
                          ),
                          settings: {
                            max_width: 'lg',
                          },
                        },
                      })
                    })
                  })
                  .catch((rej_DGD) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
                  })
              }}
              sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
            />
          )
        } else {
          solarCalculatedIconJSX = (
            <Icon
              icon="solar-panel"
              type="solid"
              className="tw-ml-3"
              tooltip={rLIB('Solar Data Not Calculated')}
              sx={{ color: themeVariables.gray_700, fontSize: '18px' }}
            />
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {colorCircleIconJSX}
            {solarCalculatedIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  location_address: TableCellBasic('location_address', rLIB('Address'), 'location_address'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  location_zip: TableCellBasic('location_zip', rLIB('Zip'), 'location_zip'),
  location_latitude: TableCellBasic('location_latitude', rLIB('Latitude'), 'location_latitude'),
  location_longitude: TableCellBasic('location_longitude', rLIB('Longitude'), 'location_longitude'),
  yearly_production_kwh: TableCellBasic('yearly_production_kwh', rLIB('Annual Production kWh'), 'yearly_production_kwh'),
  yearly_production_panels: TableCellBasic('yearly_production_panels', rLIB('Panels'), 'yearly_production_panels'),
}
