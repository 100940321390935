import { Box, Typography } from '@mui/material'

const rJSX_Banner = (message: string, backgroundColor: string) => {
  return (
    <Box sx={{ width: '100%', backgroundColor: backgroundColor, color: 'white', padding: 2, textAlign: 'center', marginBottom: 2 }}>
      <Typography variant="h4">{message}</Typography>
    </Box>
  )
}

export default rJSX_Banner
