//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*

	__          __     _____  _   _ _____ _   _  _____
	\ \        / /\   |  __ \| \ | |_   _| \ | |/ ____|
	\ \  /\  / /  \  | |__) |  \| | | | |  \| | |  __
	\ \/  \/ / /\ \ |  _  /| . ` | | | | . ` | | |_ |
	\  /\  / ____ \| | \ \| |\  |_| |_| |\  | |__| |
	\/  \/_/    \_\_|  \_\_| \_|_____|_| \_|\_____|


	INCLUDED IN DEPLOY PROCESS
	EDITS TO THIS FILE MUST BE MADE IN BOTH DEV AND PROD FILES

*/

///////////////////////////////
// Imports
///////////////////////////////

import { initializeApp } from 'firebase/app'

///////////////////////////////
// Variables
///////////////////////////////

const firebaseConfig = {
  apiKey: 'AIzaSyApi8g2-HVQUtgyhFaFufU0R2iDHaEQsTA',
  authDomain: 'etw-energy-staging.firebaseapp.com',
  projectId: 'etw-energy-staging',
  storageBucket: 'etw-energy-staging.appspot.com',
  messagingSenderId: '473715876213',
  appId: '1:473715876213:web:02b3072512ab8b7c81b33c',
  measurementId: 'G-MSFM6EM1SV',
}

///////////////////////////////
// Functions
///////////////////////////////

initializeApp(firebaseConfig)

///////////////////////////////
// Exports
///////////////////////////////

export default function FirebaseInit() {
  // Initialization occurs just from loading file BEFORE app.js file is loaded
}
