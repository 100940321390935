/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Context is used to manage access to get or set data that is stored in a shared (in this case global) state
			Combined Contexts - https://gist.github.com/etienne-dldc/68d9539a42d0a9a2737c44ace26242f8

		TODO:
			[ ] Feature - Support for custom themes
			[ ] Typescript - 6 instances of @ts-expect-error - functions copied for MUI docs
			[ ] Typescript - 2 instances of any
			[ ] Typescript - 1 instance of unknown

	*/

///////////////////////////////
// Imports
///////////////////////////////

import React, { createContext, useContext } from 'react'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormOnChange,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { TsInterface_ErrorDialogObject, TsInterface_UnspecifiedObject, TsType_MuiComponentColors } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

// RootData - Authenticated User
export interface TsInterface_AppData_UrlHistory {
  loggedIn: boolean | null
  uid: string | null
}

interface TsInterface_Context_AppData_UrlHistory {
  uc_AppData_UrlHistory: string[]
  uc_setAppData_UrlHistory(props: string[]): void
}

// RootData - Authenticated User
export interface TsInterface_RootData_AuthenticatedUser {
  loggedIn: boolean | null
  uid: string | null
}

interface TsInterface_Context_RootData_AuthenticatedUser {
  uc_RootData_AuthenticatedUser: TsInterface_RootData_AuthenticatedUser
  uc_setRootData_AuthenticatedUser(props: TsInterface_RootData_AuthenticatedUser): void
}

// RootData - Client Key
export type TsType_RootData_ClientKey = string | null

interface TsInterface_Context_RootData_ClientKey {
  uc_RootData_ClientKey: null | string
  uc_setRootData_ClientKey(props: null | string): void
}

// RootData - Client Permissions
export interface TsInterface_RootData_ClientPermissions {
  client_type: string | null
  [permissionKey: string]: boolean | string | null | undefined
}

interface TsInterface_Context_RootData_ClientPermissions {
  uc_RootData_ClientPermissions: TsInterface_RootData_ClientPermissions
  uc_setRootData_ClientPermissions(props: TsInterface_RootData_ClientPermissions): void
}

// RootData - Client User
export interface TsInterface_RootData_ClientUser {
  task_roles?: {
    [taskRoleKey: string]: boolean
  }
  associated_sales_partner_key?: string | null
  email: string
  name: string
  key: string
  permission_overrides?: {
    [permissioKey: string]: boolean
  }
  status: 'active' | 'deleted'
  user_role: string
  profile_photo_url?: string | null
  api_key?: string | null
}

interface TsInterface_Context_RootData_ClientUserInterface_ {
  uc_RootData_ClientUser: TsInterface_RootData_ClientUser
  uc_setRootData_ClientUser(props: TsInterface_RootData_ClientUser): void
}

// RootData - Global User
export interface TsInterface_RootData_GlobalUser {
  authorized_clients: {
    [clientKey: string]: boolean
  }
  client_key: string | null
  key: string | null
  super: boolean
  user_role: string | null
}

interface TsInterface_Context_RootData_GlobalUser {
  uc_RootData_GlobalUser: TsInterface_RootData_GlobalUser
  uc_setRootData_GlobalUser(props: TsInterface_RootData_GlobalUser): void
}

// RootData - User Permissions
export interface TsInterface_RootData_UserPermissions {
  [permissionKey: string]: boolean
}

interface TsInterface_Context_RootData_UserPermissions {
  uc_RootData_UserPermissions: TsInterface_RootData_UserPermissions
  uc_setRootData_UserPermissions(props: TsInterface_RootData_UserPermissions): void
}

// UserInterface - Dialog Dismissal
export type TsType_UserInterface_AllDialogsDismissCallback = () => void

// UserInterface - Alert Dialog
export interface TsInterface_AlertDialogObject {
  color?: TsType_MuiComponentColors
  header?: JSX.Element | null
  icon?: JSX.Element | null
  text?: JSX.Element | null
}

export interface TsInterface_UserInterface_AlertDialogDisplay {
  alert: TsInterface_AlertDialogObject
  display: boolean
}

interface TsInterface_Context_UserInterface_AlertDialogDisplay {
  uc_UserInterface_AlertDialogDisplay: TsInterface_UserInterface_AlertDialogDisplay
  uc_setUserInterface_AlertDialogDisplay(props: TsInterface_UserInterface_AlertDialogDisplay): void
}

export interface TsInterface_UserInterface_ConfirmDialogInternalState {
  confirming: boolean
}

// UserInterface - Confirm Dialog
export interface TsInterface_ConfirmDialogObject {
  color: TsType_MuiComponentColors
  header: string | JSX.Element | null
  icon: JSX.Element | null
  submit_callback: any // TODO
  submit_text: string | JSX.Element | null
  text: string | JSX.Element | null
}

export interface TsInterface_UserInterface_ConfirmDialogDisplay {
  confirm: TsInterface_ConfirmDialogObject
  display: boolean
}

interface TsInterface_Context_UserInterface_ConfirmDialogDisplay {
  uc_UserInterface_ConfirmDialogDisplay: TsInterface_UserInterface_ConfirmDialogDisplay
  uc_setUserInterface_ConfirmDialogDisplay(props: TsInterface_UserInterface_ConfirmDialogDisplay): void
}

// UserInterface - Custom Dialog
export interface TsInterface_CustomDialogObject {
  dialog_jsx: JSX.Element | null
  settings: {
    max_width: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  }
}

export interface TsInterface_UserInterface_CustomDialogDisplay {
  dialog: TsInterface_CustomDialogObject
  display: boolean
}

interface TsInterface_Context_UserInterface_CustomDialogDisplay {
  uc_UserInterface_CustomDialogDisplay: TsInterface_UserInterface_CustomDialogDisplay
  uc_setUserInterface_CustomDialogDisplay(props: TsInterface_UserInterface_CustomDialogDisplay): void
}

// UserInterface - Error Dialog
export interface TsInterface_UserInterface_ErrorDialogDisplay {
  display: boolean
  error: TsInterface_ErrorDialogObject
}

interface TsInterface_Context_UserInterface_ErrorDialogDisplay {
  uc_UserInterface_ErrorDialogDisplay: TsInterface_UserInterface_ErrorDialogDisplay
  uc_setUserInterface_ErrorDialogDisplay(props: TsInterface_UserInterface_ErrorDialogDisplay): void
}

// UserInterface - Form Dialog
export interface TsInterface_FormDialogObject {
  form: {
    formAdditionalData: TsInterface_FormAdditionalData
    formData: TsInterface_FormData
    formInputs: TsInterface_FormInputs
    formOnChange: TsType_FormOnChange
    formSettings: TsInterface_FormSettings
    formSubmission: TsType_FormSubmission
  }
  dialog: {
    formDialogHeaderColor: TsType_MuiComponentColors
    formDialogHeaderText: string | JSX.Element | null
    formDialogIcon: JSX.Element | null
  }
}

export interface TsInterface_UserInterface_FormDialogDisplay {
  display: boolean
  form: TsInterface_FormDialogObject
}

interface TsInterface_Context_UserInterface_FormDialogDisplay {
  uc_UserInterface_FormDialogDisplay: TsInterface_UserInterface_FormDialogDisplay
  uc_setUserInterface_FormDialogDisplay(props: TsInterface_UserInterface_FormDialogDisplay): void
}

export interface TsInterface_UserInterface_FormDialogInternalState {
  submitting: boolean
}

// UserInterface - Loading Bar
export type TsType_UserInterface_LoadingBarDisplay = boolean

interface TsInterface_Context_UserInterface_LoadingBar {
  uc_UserInterface_LoadingBarDisplay: boolean
  uc_setUserInterface_LoadingBarDisplay(props: boolean): void
}

// UserInterface - Nav Badges
export type TsInterface_UserInterface_NavBadges = TsInterface_UnspecifiedObject

interface TsInterface_Context_UserInterface_NavBadges {
  uc_UserInterface_NavBadges: TsInterface_UnspecifiedObject
  uc_setUserInterface_NavBadges(props: TsInterface_UnspecifiedObject): void
}

// UserInterface - Nav Bar
export type TsType_UserInterface_NavBarDisplay = boolean

interface TsInterface_Context_UserInterface_NavBar {
  uc_UserInterface_NavBarDisplay: boolean
  uc_setUserInterface_NavBarDisplay(props: boolean): void
}

// UserInterface - Page Content View Type
interface TsInterface_Context_UserInterfacePageContentViewType {
  uc_UserInterface_PageContentViewType: string
  uc_setUserInterface_PageContentViewType(props: string): void
}

// UserInterface - Prompt Dialog
export interface TsInterface_PromptDialogObject {
  color?: TsType_MuiComponentColors
  confirm_text?: JSX.Element | null
  default_value?: string | number | null | undefined
  header?: JSX.Element | string
  icon?: JSX.Element
  input_label?: JSX.Element
  input_type?: 'text' | 'number'
  required?: boolean
  required_value?: string
  submit_callback: any
  // submit_callback?(initialInputValue: string | number | null | undefined): Promise< unknown >
  text?: JSX.Element | string
}

export interface TsInterface_UserInterface_PromptDialogDisplay {
  display: boolean
  prompt: TsInterface_PromptDialogObject
}

interface TsInterface_Context_UserInterface_PromptDialogDisplay {
  uc_UserInterface_PromptDialogDisplay: TsInterface_UserInterface_PromptDialogDisplay
  uc_setUserInterface_PromptDialogDisplay(props: TsInterface_UserInterface_PromptDialogDisplay): void
}

export interface TsInterface_UserInterface_PromptDialogInternalState {
  submitting: boolean
}

// UserInterface - Snackbar
export interface TsInterface_SnackbarObject {
  // TODO

  // anchorOrigin
  // open: boolean
  // autoHideDuration
  // onClose
  message: string | JSX.Element
  autoHideDuration?: number
  alertType?: 'error' | 'warning' | 'info' | 'success' | null
  verticalAlignment?: 'top' | 'bottom' | null
  horizontalAlignment?: 'left' | 'center' | null
  // animationType?: "fade" | "grow" | "slide" | null
  // animationDirection?: "right" | "up" | "left" | "down" | null
  // action
  // key
  // TransitionProps
}

export interface TsInterface_UserInterface_SnackbarDisplay {
  snackbar: TsInterface_SnackbarObject
  display: boolean
}

interface TsInterface_Context_UserInterface_SnackbarDisplay {
  uc_UserInterface_SnackbarDisplay: TsInterface_UserInterface_SnackbarDisplay
  uc_setUserInterface_SnackbarDisplay(props: TsInterface_UserInterface_SnackbarDisplay): void
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// Combine Contexts
// @ts-expect-error - TODO: reason for error
const onlyChild = (children) => {
  return Array.isArray(children) ? children[0] : children
}

// @ts-expect-error - TODO: reason for error
const combineContext = (contexts) => {
  class Provider extends React.Component {
    render() {
      // @ts-expect-error - TODO: reason for error
      const init = this.props.children
      return Object.keys(contexts).reduce((acc, contextName) => {
        const TheContext = contexts[contextName]
        // @ts-expect-error - TODO: reason for error
        return <TheContext.Provider value={this.props.value[contextName]}>{acc}</TheContext.Provider>
      }, init)
    }
  }

  class Consumer extends React.Component {
    render() {
      // @ts-expect-error - TODO: reason for error
      const init = (value) => onlyChild(this.props.children)(value)
      const renderer = Object.keys(contexts).reduce((acc, contextName) => {
        const TheContext = contexts[contextName]
        return (value) => (
          <TheContext.Consumer>
            {/* @ts-expect-error  - TODO: reason */}
            {(contextValue) => {
              return acc({
                ...value,
                [contextName]: contextValue,
              })
            }}
          </TheContext.Consumer>
        )
      }, init)
      return renderer({})
    }
  }
  return {
    Consumer,
    Provider,
  }
}

///////////////////////////////
// Exports
///////////////////////////////

// Default Context States
export const RootData_Default_AppData_UrlHistoryState: string[] = []
export const RootData_Default_AuthenticatedUserState: TsInterface_RootData_AuthenticatedUser = { loggedIn: null, uid: null }
export const RootData_Default_ClientKeyState: TsType_RootData_ClientKey = null
export const RootData_Default_ClientPermissionsState: TsInterface_RootData_ClientPermissions = { client_type: null }
export const RootData_Default_ClientUserState: TsInterface_RootData_ClientUser = { email: '', name: '', key: '', status: 'active', user_role: '' }
export const RootData_Default_GlobalUserState: TsInterface_RootData_GlobalUser = {
  authorized_clients: {},
  client_key: null,
  key: null,
  super: false,
  user_role: null,
}
export const RootData_Default_UserPermissionsState: TsInterface_RootData_UserPermissions = {}
export const UserInterface_Default_AlertDialogDisplayState: TsInterface_UserInterface_AlertDialogDisplay = {
  display: false,
  alert: { icon: null, color: undefined, header: null, text: null },
}
export const UserInterface_Default_ConfirmDialogDisplayState: TsInterface_UserInterface_ConfirmDialogDisplay = {
  display: false,
  confirm: { icon: null, color: undefined, header: null, text: null, submit_text: null, submit_callback: null },
}
export const UserInterface_Default_ConfirmDialogInternalState: TsInterface_UserInterface_ConfirmDialogInternalState = { confirming: false }
export const UserInterface_Default_CustomDialogDisplayState: TsInterface_UserInterface_CustomDialogDisplay = {
  display: false,
  dialog: { dialog_jsx: <></>, settings: { max_width: 'md' } },
}
export const UserInterface_Default_ErrorDialogDisplayState: TsInterface_UserInterface_ErrorDialogDisplay = {
  display: false,
  error: { message: <></>, details: <></>, code: '' },
}
export const UserInterface_Default_FormDialogDisplayState: TsInterface_UserInterface_FormDialogDisplay = {
  display: false,
  form: {
    form: {
      formAdditionalData: {},
      formData: {},
      formInputs: {},
      formOnChange: (
        formAdditionalData: TsInterface_FormAdditionalData,
        formData: TsInterface_FormData,
        formInputs: TsInterface_FormInputs,
        formSettings: TsInterface_FormSettings,
      ) => {},
      formSettings: {},
      formSubmission: (
        formSubmittedData: TsInterface_FormSubmittedData,
        formAdditionalData: TsInterface_FormAdditionalData,
        formHooks: TsInterface_FormHooksObject,
      ) => {
        return new Promise((resolve, reject) => {
          resolve({ success: true })
        })
      },
    },
    dialog: {
      formDialogHeaderColor: undefined,
      formDialogHeaderText: '',
      formDialogIcon: <></>,
    },
  },
}
export const UserInterface_Default_FormDialogInternalState: TsInterface_UserInterface_FormDialogInternalState = { submitting: false }
export const UserInterface_Default_LoadingBarDisplayState: TsType_UserInterface_LoadingBarDisplay = false
export const UserInterface_Default_NavBadgesCountState: TsInterface_UserInterface_NavBadges = {}
export const UserInterface_Default_NavBarDisplayState: TsType_UserInterface_NavBarDisplay = false
export const UserInterface_Default_PageContentViewTypeState: string = 'default'
export const UserInterface_Default_PromptDialogDisplayState: TsInterface_UserInterface_PromptDialogDisplay = {
  display: false,
  prompt: {
    icon: <></>,
    color: undefined,
    header: <></>,
    text: <></>,
    input_type: 'text',
    input_label: <></>,
    confirm_text: null,
    default_value: null,
    submit_callback: null,
  },
}
export const UserInterface_Default_PromptDialogInternalState: TsInterface_UserInterface_PromptDialogInternalState = { submitting: false }
export const UserInterface_Default_SnackbarDisplayState: TsInterface_UserInterface_SnackbarDisplay = { display: false, snackbar: { message: <></> } }

// Individual Context
export const Context_AppData_UrlHistory = createContext<TsInterface_Context_AppData_UrlHistory>({
  uc_AppData_UrlHistory: RootData_Default_AppData_UrlHistoryState,
  uc_setAppData_UrlHistory: (props: string[]) => {},
})
export const Context_RootData_AuthenticatedUser = createContext<TsInterface_Context_RootData_AuthenticatedUser>({
  uc_RootData_AuthenticatedUser: RootData_Default_AuthenticatedUserState,
  uc_setRootData_AuthenticatedUser: (props: TsInterface_RootData_AuthenticatedUser) => {},
})
export const Context_RootData_ClientKey = createContext<TsInterface_Context_RootData_ClientKey>({
  uc_RootData_ClientKey: RootData_Default_ClientKeyState,
  uc_setRootData_ClientKey: (props: string | null) => {},
})
export const Context_RootData_ClientPermissions = createContext<TsInterface_Context_RootData_ClientPermissions>({
  uc_RootData_ClientPermissions: RootData_Default_ClientPermissionsState,
  uc_setRootData_ClientPermissions: (props: TsInterface_RootData_ClientPermissions) => {},
})
export const Context_RootData_ClientUser = createContext<TsInterface_Context_RootData_ClientUserInterface_>({
  uc_RootData_ClientUser: RootData_Default_ClientUserState,
  uc_setRootData_ClientUser: (props: TsInterface_RootData_ClientUser) => {},
})
export const Context_RootData_GlobalUser = createContext<TsInterface_Context_RootData_GlobalUser>({
  uc_RootData_GlobalUser: RootData_Default_GlobalUserState,
  uc_setRootData_GlobalUser: (props: TsInterface_RootData_GlobalUser) => {},
})
export const Context_RootData_UserPermissions = createContext<TsInterface_Context_RootData_UserPermissions>({
  uc_RootData_UserPermissions: RootData_Default_UserPermissionsState,
  uc_setRootData_UserPermissions: (props: TsInterface_RootData_UserPermissions) => {},
})
export const Context_UserInterface_AlertDialog = createContext<TsInterface_Context_UserInterface_AlertDialogDisplay>({
  uc_UserInterface_AlertDialogDisplay: UserInterface_Default_AlertDialogDisplayState,
  uc_setUserInterface_AlertDialogDisplay: (props: TsInterface_UserInterface_AlertDialogDisplay) => {},
})
export const Context_UserInterface_ConfirmDialog = createContext<TsInterface_Context_UserInterface_ConfirmDialogDisplay>({
  uc_UserInterface_ConfirmDialogDisplay: UserInterface_Default_ConfirmDialogDisplayState,
  uc_setUserInterface_ConfirmDialogDisplay: (props: TsInterface_UserInterface_ConfirmDialogDisplay) => {},
})
export const Context_UserInterface_CustomDialog = createContext<TsInterface_Context_UserInterface_CustomDialogDisplay>({
  uc_UserInterface_CustomDialogDisplay: UserInterface_Default_CustomDialogDisplayState,
  uc_setUserInterface_CustomDialogDisplay: (props: TsInterface_UserInterface_CustomDialogDisplay) => {},
})
export const Context_UserInterface_ErrorDialog = createContext<TsInterface_Context_UserInterface_ErrorDialogDisplay>({
  uc_UserInterface_ErrorDialogDisplay: UserInterface_Default_ErrorDialogDisplayState,
  uc_setUserInterface_ErrorDialogDisplay: (props: TsInterface_UserInterface_ErrorDialogDisplay) => {},
})
export const Context_UserInterface_FormDialog = createContext<TsInterface_Context_UserInterface_FormDialogDisplay>({
  uc_UserInterface_FormDialogDisplay: UserInterface_Default_FormDialogDisplayState,
  uc_setUserInterface_FormDialogDisplay: (props: TsInterface_UserInterface_FormDialogDisplay) => {},
})
export const Context_UserInterface_LoadingBar = createContext<TsInterface_Context_UserInterface_LoadingBar>({
  uc_UserInterface_LoadingBarDisplay: UserInterface_Default_LoadingBarDisplayState,
  uc_setUserInterface_LoadingBarDisplay: (props: boolean) => {},
})
export const Context_UserInterface_NavBadges = createContext<TsInterface_Context_UserInterface_NavBadges>({
  uc_UserInterface_NavBadges: UserInterface_Default_NavBadgesCountState,
  uc_setUserInterface_NavBadges: (props: TsInterface_UnspecifiedObject) => {},
})
export const Context_UserInterface_NavBar = createContext<TsInterface_Context_UserInterface_NavBar>({
  uc_UserInterface_NavBarDisplay: UserInterface_Default_NavBarDisplayState,
  uc_setUserInterface_NavBarDisplay: (props: boolean) => {},
})
export const Context_UserInterface_PageContentViewType = createContext<TsInterface_Context_UserInterfacePageContentViewType>({
  uc_UserInterface_PageContentViewType: UserInterface_Default_PageContentViewTypeState,
  uc_setUserInterface_PageContentViewType: (props: string) => {},
})
export const Context_UserInterface_PromptDialog = createContext<TsInterface_Context_UserInterface_PromptDialogDisplay>({
  uc_UserInterface_PromptDialogDisplay: UserInterface_Default_PromptDialogDisplayState,
  uc_setUserInterface_PromptDialogDisplay: (props: TsInterface_UserInterface_PromptDialogDisplay) => {},
})
export const Context_UserInterface_Snackbar = createContext<TsInterface_Context_UserInterface_SnackbarDisplay>({
  uc_UserInterface_SnackbarDisplay: UserInterface_Default_SnackbarDisplayState,
  uc_setUserInterface_SnackbarDisplay: (props: TsInterface_UserInterface_SnackbarDisplay) => {},
})

// Custom Hooks that use context
export const Hook_AppData_AddToUrlHistory = () => {
  const { uc_AppData_UrlHistory, uc_setAppData_UrlHistory } = useContext(Context_AppData_UrlHistory)
  const hook_addUrlToHistory = (url: string) => {
    if (uc_AppData_UrlHistory.length === 0 || uc_AppData_UrlHistory[uc_AppData_UrlHistory.length - 1] !== url) {
      const updatedHistory = [...uc_AppData_UrlHistory, url]
      uc_setAppData_UrlHistory(updatedHistory)
    }
  }
  return { uc_AppData_UrlHistory, hook_addUrlToHistory }
}

// Combine Contexts
export const CombinedAppContext = combineContext({
  Context_AppData_UrlHistory_Passthrough: Context_AppData_UrlHistory,
  Context_RootData_AuthenticatedUser_Passthrough: Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey_Passthrough: Context_RootData_ClientKey,
  Context_RootData_ClientPermissions_Passthrough: Context_RootData_ClientPermissions,
  Context_RootData_ClientUser_Passthrough: Context_RootData_ClientUser,
  Context_RootData_GlobalUser_Passthrough: Context_RootData_GlobalUser,
  Context_RootData_UserPermissions_Passthrough: Context_RootData_UserPermissions,
  Context_UserInterface_AlertDialog_Passthrough: Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog_Passthrough: Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog_Passthrough: Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog_Passthrough: Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog_Passthrough: Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar_Passthrough: Context_UserInterface_LoadingBar,
  Context_UserInterface_NavBadges_Passthrough: Context_UserInterface_NavBadges,
  Context_UserInterface_PageContentViewType_Passthrough: Context_UserInterface_PageContentViewType,
  Context_UserInterface_NavBar_Passthrough: Context_UserInterface_NavBar,
  Context_UserInterface_PromptDialog_Passthrough: Context_UserInterface_PromptDialog,
  Context_UserInterface_Snackbar_Passthrough: Context_UserInterface_Snackbar,
})
