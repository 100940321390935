///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { formatDateRange } from './payroll_tables'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_TasksPendingSalesPartnerApproval: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'timestamp_completed',
  use_live_data: false,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 350px)',
}

export const tableColumns_TasksPendingSalesPartnerApproval: TsInterface_TableColumns = {
  manage: TableCellManage({
    approve: {
      icon: (
        <Icon
          type="solid"
          icon="badge-check"
        />
      ),
      label: <>{rLIB('Sales Partner Approval Complete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {
                  timestamp_completed: returnFormattedDate(new Date(), 'YYYY-MM-DDTHH:mm'),
                },
                formInputs: {
                  sales_partner_approval_notes: {
                    key: 'sales_partner_approval_notes',
                    label: rLIB('Sales Partner Approval Notes'),
                    input_type: 'text_multiline',
                    required: true,
                    data_type: 'string',
                  },
                  header: {
                    data_type: 'string',
                    key: 'header',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    // @ts-ignore
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-center tw-mb-4">
                          <Typography
                            variant="h6"
                            className="tw-mt-2"
                          >
                            {rLIB('The following date will be used when determining payroll.')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="tw-opacity-50"
                          >
                            {rLIB("Don't put a date for a past locked payroll.")}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                  },
                  timestamp_completed: {
                    key: 'timestamp_completed',
                    label: rLIB('New Completion Date'),
                    input_type: 'timestamp_datetime',
                    required: true,
                    data_type: 'string',
                    // min: minDate,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      sales_partner_approval_notes: formSubmittedData.sales_partner_approval_notes,
                      timestamp_completed: new Date(formSubmittedData.timestamp_completed),
                      sales_partner_approval_granted: true,
                    }
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Sales Partner Approval Completion'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
      },
    },
  }),
  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('FSD Invoice Number'), 'invoice_id_number'),
  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
  task_completion_scheduled_dates: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Dates')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
          let goToCalendarIconJSX = (
            <Icon
              icon="calendar-arrow-up"
              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
              tooltip={rLIB('View on Calendar')}
              tooltipPlacement="right"
              onClick={() => {
                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                if (datesArray.length > 0) {
                  let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                  window.open(url, '_blank')
                }
              }}
            />
          )
          cellJSX = (
            <Box>
              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
              {goToCalendarIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_completed: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Completed On')
      },
      header_sort_by: 'timestamp_completed',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.timestamp_completed != null) {
          cellJSX = <Box>{returnFormattedDate(rowData['timestamp_completed'], 'D MMM YY')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const tableColumns_ReadOnlyTasksPendingSalesPartnerApproval: TsInterface_TableAdditionalData = {
  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('FSD Invoice Number'), 'invoice_id_number'),
  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
  task_completion_scheduled_dates: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Dates')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
          let goToCalendarIconJSX = (
            <Icon
              icon="calendar-arrow-up"
              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
              tooltip={rLIB('View on Calendar')}
              tooltipPlacement="right"
              onClick={() => {
                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                if (datesArray.length > 0) {
                  let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                  window.open(url, '_blank')
                }
              }}
            />
          )
          cellJSX = (
            <Box>
              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
              {goToCalendarIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_completed: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Completed On')
      },
      header_sort_by: 'timestamp_completed',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.timestamp_completed != null) {
          cellJSX = <Box>{returnFormattedDate(rowData['timestamp_completed'], 'D MMM YY')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

/*

On Complete of task

*/
