///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

        	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { eachWeekOfInterval, endOfMonth, format, isWithinInterval, startOfMonth, startOfWeek } from 'date-fns'
import { getDocs } from 'firebase/firestore'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query } from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import {
  DatabaseRef_CycleTimeByActiveProject_Query,
  DatabaseRef_CycleTimeByDate_Query,
  DatabaseRef_NewCrewHoursByDate_Query,
  DatabaseRef_SafetyReviewByDate_Query,
} from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import { DatabaseRef_ProjectsByIdNumber_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ActiveTeamsByType_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DatabaseRef_ScheduledTasksForTeamInDateRange_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { returnFormattedDateKey } from 'rfbp_core/components/chat/chat_helper_functions'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { downloadCSV, objectToArray } from '../../../rfbp_core/services/helper_functions/index'
import { GMDashboardHeatmap } from './components/gm_dashboard_heatmap'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['GMDashboardIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const currentDate = new Date()
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfCurrentMonth)
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(lastDayOfCurrentMonth)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_activeTeams, us_setActiveTeams] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawTaskData, us_setRawTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cleanTaskData, us_setCleanTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_teamKeys, us_setTeamKeys] = useState<string[]>([])
  const [us_projectsForTasks, us_setProjectsForTasks] = useState<string[]>([])
  const [us_weeklyPanelsInstalled, us_setWeeklyPanelsInstalled] = useState<any>({})
  const [us_refreshingColumn, us_setRefreshingColumn] = useState<string | null>(null)
  const [us_mondaysInDateRange, us_setMondaysInDateRange] = useState<TsInterface_UnspecifiedObject>({})
  const [us_panelsInstalledFormattedData, us_set_PanelsInstalledFormattedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCrewHours, us_setFormattedCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_crewHours, us_setCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawSafetyReview, us_setRawSafetyReview] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedSafetyReviews, us_setFormattedSafetyReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_CycleTimeRawData, us_setCycleTimeRawData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCycleTimeRawData, us_setFormattedCycleTimeRawData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartners, us_setSalesPartners] = useState<TsInterface_UnspecifiedObject>({})

  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  //TODO: change name to something more descriptive

  const [us_downloadingCycleTimes, us_setDownloadingCycleTimes] = useState<boolean>(false)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Where you are initializing the request to the cloud function
  // useEffect(() => {
  //   cloudFunctionManageRequest('manageData', {
  //     function: 'cacheGMDashboardCycleTimesData',
  //     client_key: 'etw_energy',
  //     monday_date_key: new Date('2024-06-24 00:00:00').toISOString(),
  //   })
  //     .then((res_CFMR) => {
  //       console.log(res_CFMR)
  //       us_setRefreshingColumn(null)
  //     })
  //     .catch((rej_CFMR) => {
  //       console.error(rej_CFMR)
  //       us_setRefreshingColumn(null)
  //     })
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('GM Dashboard', false) as string
  }, [])

  //Get Mondays in Date Range
  useEffect(() => {
    const interval = { start: us_selectedStartDate, end: us_selectedEndDate }

    // Generate all the Mondays within the interval
    const mondays = eachWeekOfInterval(interval, { weekStartsOn: 1 })
      .map((weekStart) => startOfWeek(weekStart, { weekStartsOn: 1 }))
      .filter((monday) => isWithinInterval(monday, interval)) // Check if the Monday is within the interval
      .map((monday) => monday.toISOString().split('T')[0]) // Convert to string format

    us_setMondaysInDateRange(mondays)
  }, [us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  // Get Regions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      //us_setRegionsLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Get Teams
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeams(newData)

      const activeTeamKeys = Object.keys(newData).filter((key) => newData[key].status === 'active')

      us_setTeamKeys(activeTeamKeys)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTeamsByType_Query(res_GCK.clientKey, 'install_crew'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Get Tasks
  useEffect(() => {
    if (Object.keys(us_activeTeams).length === 0 || us_teamKeys.length === 0) {
      return
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), 1)
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)
    const generateDateKeyArray = (startDate: Date, endDate: Date): string[] => {
      const dateKeyArray: string[] = []
      for (let loopTime = startDate.getTime(); loopTime <= endDate.getTime(); loopTime += 86400000) {
        const loopDate = new Date(loopTime)
        const loopDateKey = formatDateToYYYYMMDD(loopDate)
        dateKeyArray.push(loopDateKey)
      }
      return dateKeyArray
    }
    const chunkArray = (array: string[], size: number): string[][] => {
      const chunkedArr: string[][] = []
      for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size))
      }
      return chunkedArr
    }
    const fetchTasksForDateChunks = async (clientKey: string, dateChunks: string[][], teamKeys: string[]): Promise<any> => {
      const allResults: { [key: string]: any } = {}
      for (const dateChunk of dateChunks) {
        const promises = teamKeys.map((teamKey: string) => {
          const scheduledTasksQuery = DatabaseRef_ScheduledTasksForTeamInDateRange_Query(clientKey, dateChunk, teamKey)
          return getDocs(scheduledTasksQuery).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const taskData = doc.data()
              const lastDate = taskData.task_completion_scheduled_dates?.slice(-1)[0]
              if (dateChunk.includes(lastDate)) {
                allResults[doc.id] = taskData
              }
            })
          })
        })
        await Promise.all(promises)
      }

      return allResults
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then(async (res_GCK: any) => {
        const dateKeyArray = generateDateKeyArray(startDate, endDate)
        const dateChunks = chunkArray(dateKeyArray, 10)

        const allResults = await fetchTasksForDateChunks(res_GCK.clientKey, dateChunks, us_teamKeys)

        const filteredResults = Object.values(allResults).filter((task: any) => {
          const lastDate = task.task_completion_scheduled_dates?.slice(-1)[0]
          return dateKeyArray.includes(lastDate)
        })

        us_setRawTaskData(filteredResults)
        ur_forceRerender()
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error)
      })
  }, [
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    us_activeTeams,
    us_teamKeys,
    us_selectedStartDate,
    us_selectedEndDate,
    ur_forceRerender,
    us_setRawTaskData,
  ])

  // Clean task data and add latest task completion date
  useEffect(() => {
    if (us_rawTaskData.length === 0) {
      return
    }
    const cleanTaskData = objectToArray(us_rawTaskData).map((task: any) => {
      const latestDate = task.task_completion_scheduled_dates?.slice(-1)[0]
      return {
        ...task,
        latest_task_completion_date: latestDate,
      }
    })
    us_setCleanTaskData(objectToArray(cleanTaskData))
  }, [us_rawTaskData, us_setCleanTaskData])

  //Get Projects for Tasks
  useEffect(() => {
    if (!us_cleanTaskData || Object.keys(us_cleanTaskData).length === 0) {
      return
    }
    const updateProjectData = (newData: any) => {
      us_setProjectsForTasks(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const projectPromises = Object.values(us_cleanTaskData).map((task) => {
          const projectId = task.associated_project_id_number
          const projectQuery = DatabaseRef_ProjectsByIdNumber_Query(res_GCK.clientKey, projectId)
          return getDocs(projectQuery).then((querySnapshot) => {
            let projects: any = {}
            querySnapshot.forEach((doc) => {
              projects[doc.id] = doc.data()
            })
            return projects
          })
        })
        return Promise.all(projectPromises)
      })
      .then((allProjectResults) => {
        let combinedProjects = {}
        allProjectResults.forEach((project) => {
          combinedProjects = { ...combinedProjects, ...project }
        })
        updateProjectData(combinedProjects)
      })
      .catch((error) => {
        console.error('Error fetching projects for tasks:', error)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_cleanTaskData, ur_forceRerender])

  //Organize tasks/projects by months/weeks
  useEffect(() => {
    if (!us_cleanTaskData || Object.keys(us_cleanTaskData).length === 0 || !us_projectsForTasks || Object.keys(us_projectsForTasks).length === 0) {
      return
    }

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), 1)
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)
    const firstMonday = getFirstMonday(startDate)
    const lastSunday = getLastSundayAfterLastMonday(endDate)
    const weeks = generateWeeklyDateRanges(firstMonday, lastSunday)
    const months = generateMonthlyDateRanges(startDate, endDate)

    const aggregatedWeeklyData: any = {}
    const aggregatedMonthlyData: any = {}

    // Initialize weeks and months in the aggregated data
    weeks.forEach((weekRange) => {
      aggregatedWeeklyData[weekRange] = {} // Initialize week with an empty object
    })
    months.forEach((monthRange) => {
      aggregatedMonthlyData[monthRange] = { total_weeks: 0 } // Initialize month with total_weeks set to zero
    })

    Object.values(us_cleanTaskData).forEach((task: any) => {
      const teamKey = task.associated_team_key
      const teamName = task.associated_team_name
      const project: any = us_projectsForTasks[task.associated_project_key]
      const jobCode = task.associated_project_id_number
      const projectId = task.associated_project_key
      const taskId = task.key
      const taskName = task.name

      if (!project) {
        console.warn(`No project found for task with project key: ${task.associated_project_key}`)
        return
      }

      const panels = project.system_panel_quantity || 0
      const batteries = project.system_storage_quantity || 0
      const dateString = task.latest_task_completion_date
      const date = new Date(dateString)

      // Aggregate Weekly Data
      weeks.forEach((weekRange) => {
        const [weekStart, weekEnd] = weekRange.split(' to ').map((dateStr: string) => new Date(dateStr))
        if (!aggregatedWeeklyData[weekRange][teamKey]) {
          aggregatedWeeklyData[weekRange][teamKey] = { panels: 0, batteries: 0, total_panels: 0, teamName: teamName, tasks: [] }
        }

        if (isDateInRange(date, weekStart, weekEnd)) {
          aggregatedWeeklyData[weekRange][teamKey].panels += panels
          aggregatedWeeklyData[weekRange][teamKey].batteries += batteries
          aggregatedWeeklyData[weekRange][teamKey].total_panels += panels + batteries * 10

          const taskExists = aggregatedWeeklyData[weekRange][teamKey].tasks.find((t: any) => t.taskId === taskId)
          if (!taskExists) {
            aggregatedWeeklyData[weekRange][teamKey].tasks.push({
              projectId,
              jobCode,
              taskId,
              taskName,
              panels,
              batteries,
              total_panels: panels + batteries * 10,
              completion_date: dateString,
            })
          }
        }
      })

      // Aggregate Monthly Data
      months.forEach((monthRange) => {
        const [monthStart, monthEnd] = monthRange.split(' to ').map((dateStr: string) => new Date(dateStr))
        if (!aggregatedMonthlyData[monthRange][teamKey]) {
          aggregatedMonthlyData[monthRange][teamKey] = { panels: 0, batteries: 0, total_panels: 0, teamName: teamName, tasks: [] }
        }

        if (isDateInRange(date, monthStart, monthEnd)) {
          aggregatedMonthlyData[monthRange][teamKey].panels += panels
          aggregatedMonthlyData[monthRange][teamKey].batteries += batteries
          aggregatedMonthlyData[monthRange][teamKey].total_panels += panels + batteries * 10

          aggregatedMonthlyData[monthRange].total_weeks = weeks.filter((weekRange: string) => {
            const [weekStart] = weekRange.split(' to ').map((dateStr: string) => new Date(dateStr))
            return weekStart >= monthStart && weekStart <= monthEnd
          }).length

          const taskExists = aggregatedMonthlyData[monthRange][teamKey].tasks.find((t: any) => t.taskId === taskId)
          if (!taskExists) {
            aggregatedMonthlyData[monthRange][teamKey].tasks.push({
              projectId,
              jobCode,
              taskId,
              taskName,
              panels,
              batteries,
              total_panels: panels + batteries * 10,
              completion_date: dateString,
            })
          }
        }
      })
    })

    us_setWeeklyPanelsInstalled(aggregatedWeeklyData)
    //us_setTeamMonthlyScheduledDensityData(aggregatedMonthlyData)
  }, [us_cleanTaskData, us_projectsForTasks, us_mondaysInDateRange, us_selectedStartDate, us_selectedEndDate])

  //Organize panels installed into summary rows and data rows for V2 component
  useEffect(() => {
    if (!us_weeklyPanelsInstalled) return

    const today: any = new Date()
    today.setHours(0, 0, 0, 0) // Normalize today's date

    const formattedData: any = {
      summary_rows: [],
      data_rows: [],
    }

    let weekDates: any = [{ cellValue: rLIB('Panels Installed'), conditionalFormatting: { fontWeight: 'bold', width: '150px' } }]
    let weekTotals: any = []
    let teamsCountPerWeek: any = {}

    Object.entries(us_weeklyPanelsInstalled).forEach(([weekRange, teamsData], weekIndex) => {
      const [weekStartStr]: any = weekRange.split(' to ')
      let weekStart: any = new Date(weekStartStr)

      // Ensure week starts on Monday
      if (weekStart.getDay() !== 1) {
        weekStart.setDate(weekStart.getDate() + ((1 + 7 - weekStart.getDay()) % 7))
      }

      const isFuture: any = weekStart > today
      const formattedWeekStart: any = weekStart.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

      if (weekIndex >= weekDates.length - 1) {
        weekDates.push({
          cellValue: formattedWeekStart,
          conditionalFormatting: { fontWeight: 'bold', opacity: isFuture ? 0.5 : 1, width: '150px' },
        })
        weekTotals.push({ totalPanels: '-', goalPanels: 0, conditionalFormatting: {} })
        teamsCountPerWeek[weekRange] = new Set()
      }

      Object.entries(teamsData as any).forEach(([teamKey, teamInfo]) => {
        const { teamName, total_panels, tasks }: any = teamInfo

        if (!formattedData.data_rows.some((row: any) => row[0].cellValue === teamName)) {
          formattedData.data_rows.push([{ cellValue: teamName, conditionalFormatting: { fontWeight: 'bold', width: '150px' } }])
        }

        const teamRowIndex = formattedData.data_rows.findIndex((row: any) => row[0].cellValue === teamName)
        teamsCountPerWeek[weekRange].add(teamKey)

        const projectDetails = tasks.map((task: any) => ({
          projectId: task.jobCode,
          panels: task.panels,
          batteries: task.batteries,
          total_panels: task.total_panels,
          completion_date: task.completion_date,
        }))

        const dialogFunction = () => {
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <Card>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="h6"
                        sx={{ marginRight: '16px' }}
                      >
                        {teamName}
                      </Typography>
                      <Typography variant="subtitle1">{formattedWeekStart}</Typography>
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="simple table"
                      style={{ tableLayout: 'fixed', width: '100%' }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '150px' }}>Job</TableCell>
                          <TableCell
                            style={{ width: '150px' }}
                            align="right"
                          >
                            {rLIB('Panels')}
                          </TableCell>
                          <TableCell
                            style={{ width: '150px' }}
                            align="right"
                          >
                            {rLIB('Battery Adders')}
                          </TableCell>
                          <TableCell
                            style={{ width: '150px' }}
                            align="right"
                          >
                            {rLIB('Total Panels')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.map(
                          (
                            row: {
                              projectId:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                              panels:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                              batteries:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                              total_panels:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            },
                            index: React.Key | null | undefined,
                          ) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{ width: '150px' }}
                                component="th"
                                scope="row"
                              >
                                {row.projectId}
                              </TableCell>
                              <TableCell
                                style={{ width: '150px' }}
                                align="right"
                              >
                                {row.panels}
                              </TableCell>
                              <TableCell
                                style={{ width: '150px' }}
                                align="right"
                              >
                                {row.batteries}
                              </TableCell>
                              <TableCell
                                style={{ width: '150px' }}
                                align="right"
                              >
                                {row.total_panels}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              ),
              settings: { max_width: 'md' },
            },
          })
        }

        // Ensure sufficient length for data rows
        while (formattedData.data_rows[teamRowIndex].length <= weekIndex + 1) {
          formattedData.data_rows[teamRowIndex].push({
            cellValue: '-',
            conditionalFormatting: { opacity: isFuture ? 0.5 : 1, width: '150px' },
          })
        }

        // Update the data row
        formattedData.data_rows[teamRowIndex][weekIndex + 1] = {
          cellValue: total_panels > 0 ? total_panels : '-',
          conditionalFormatting: {
            backgroundColor: total_panels > 100 ? themeVariables.success_main : total_panels === 0 ? 'inherit' : themeVariables.error_main,
            opacity: isFuture ? 0.5 : 1,
            cursor: total_panels > 0 ? 'pointer' : 'default',
            width: '150px', // Ensure consistent width
          },
          dialogFunction: total_panels > 0 ? dialogFunction : undefined,
        }

        // Update weekly totals
        if (weekTotals[weekIndex].totalPanels === '-') {
          weekTotals[weekIndex].totalPanels = 0
        }
        if (total_panels !== '-' && total_panels > 0) {
          weekTotals[weekIndex].totalPanels += total_panels
          weekTotals[weekIndex].goalPanels += 100
        }
        weekTotals[weekIndex].conditionalFormatting = {
          backgroundColor:
            weekTotals[weekIndex].totalPanels >= weekTotals[weekIndex].goalPanels
              ? themeVariables.success_main
              : weekTotals[weekIndex].totalPanels === 0
                ? 'inherit'
                : themeVariables.error_main,
          opacity: isFuture ? 0.5 : 1,
        }
      })

      if (weekTotals[weekIndex].totalPanels === 0) {
        weekTotals[weekIndex].totalPanels = '-'
      }
    })

    // Sort the team names alphabetically before constructing the data rows
    formattedData.data_rows.sort((a: any, b: any) => a[0].cellValue.localeCompare(b[0].cellValue))

    // Finalize the summary rows
    formattedData.summary_rows.push(weekDates)
    formattedData.summary_rows.push([
      { cellValue: rLIB('Total'), conditionalFormatting: { fontWeight: 'bold', width: '150px' } },
      ...weekTotals.map((total: any) => ({
        cellValue: total.totalPanels !== '-' ? `${total.totalPanels} / ${total.goalPanels}` : '-',
        conditionalFormatting: total.conditionalFormatting,
        additionalCellData: total,
        dialogFunction: total.dialogFunction,
      })),
    ])

    us_set_PanelsInstalledFormattedData(formattedData)
  }, [us_weeklyPanelsInstalled, uc_setUserInterface_CustomDialogDisplay])

  //add default data to empty weeks
  useEffect(() => {
    if (!us_weeklyPanelsInstalled) {
      return
    }

    const monthMap: any = {}
    const formattedWeeklyDataForDisplay: any[] = []

    Object.values(us_mondaysInDateRange).forEach((mondayDate: any) => {
      formattedWeeklyDataForDisplay.push({
        week: mondayDate,
        total: 0,
        number_of_teams: 0,
      })
    })

    // Process available data
    Object.entries(us_weeklyPanelsInstalled).forEach(([weekRange, teams]) => {
      const mondayDate = weekRange.split(' to ')[0]
      let weeklyTotalPanels = 0
      let weeklyActiveTeams = 0

      Object.values<any>(teams as any).forEach((team) => {
        const teamTotalPanels = team.panels + team.batteries * 10
        if (teamTotalPanels > 0) {
          weeklyActiveTeams++
        }
        weeklyTotalPanels += teamTotalPanels

        const month = mondayDate.slice(0, 7)
        if (!monthMap[month]) {
          monthMap[month] = { panels: 0, batteries: 0, total_panels: 0, active_teams: 0 }
        }
        if (teamTotalPanels > 0) {
          monthMap[month].active_teams++
        }
        monthMap[month].panels += team.panels
        monthMap[month].batteries += team.batteries
        monthMap[month].total_panels += teamTotalPanels
      })

      // Update the initialized week with actual data
      const weekIndex = formattedWeeklyDataForDisplay.findIndex((weekData: any) => weekData.week === mondayDate)
      if (weekIndex !== -1) {
        formattedWeeklyDataForDisplay[weekIndex].total = weeklyTotalPanels
        formattedWeeklyDataForDisplay[weekIndex].number_of_teams = weeklyActiveTeams
      }
    })

    // Set the state with the new totals and formatted data
  }, [us_weeklyPanelsInstalled, us_mondaysInDateRange])

  //get crew hours
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCrewHours(newData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_NewCrewHoursByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  //process crew hours
  useEffect(() => {
    const formattedData: any = {
      summary_rows: [],
      data_rows: [],
    }

    // Initialize date headers for the summary rows
    const dateHeaders: any[] = [{ cellValue: 'Metric/Date', conditionalFormatting: { fontWeight: 'bold' } }]
    const totals: any = {
      panels: {},
      units: {},
      batteryUnits: {},
    }

    // Process each Monday in the range
    Object.values(us_mondaysInDateRange).forEach((date: any) => {
      let currentDate = new Date(date)
      if (currentDate.getDay() === 0) {
        // Adjust for Sundays
        currentDate.setDate(currentDate.getDate() + 1)
      }
      const formattedDate = currentDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
      dateHeaders.push({
        cellValue: formattedDate,
        conditionalFormatting: { fontWeight: 'bold', opacity: currentDate > new Date() ? 0.5 : 1 },
      })
      totals.panels[formattedDate] = 0
      totals.units[formattedDate] = 0
      totals.batteryUnits[formattedDate] = 0
    })

    // Collect all unique names and initialize weekly data
    const allPeople: any = {}
    Object.entries(us_crewHours).forEach(([index, weekData]: [string, any]) => {
      const weekId = weekData.id // This assumes there is always a valid 'id' that matches one of the Mondays

      Object.entries(weekData).forEach(([personId, details]: [string, any]) => {
        if (personId === 'id' || personId === 'data' || typeof details !== 'object') return // Skip non-person entries

        const personName: string = details.name
        const fieldHours: number = details.field_hours || 0
        const totalPanels: number = details.total_panels || 0
        const totalUnits: number = details.total_units || 0
        const totalBatteryUnits: number = details.total_battery_units || 0

        // Aggregate totals for the date
        totals.panels[weekId] += totalPanels
        totals.units[weekId] += totalUnits
        totals.batteryUnits[weekId] += totalBatteryUnits

        if (!allPeople[personName]) {
          allPeople[personName] = {}
          Object.values(us_mondaysInDateRange).forEach((date: any) => {
            allPeople[personName][date] = '-' // Initialize all weeks to '-'
          })
        }

        const customMetric: number = fieldHours - (totalPanels / 2 + totalUnits + totalBatteryUnits)
        allPeople[personName][weekId] = customMetric
      })
    })

    // Convert the structured data into rows for the table
    Object.keys(allPeople).forEach((personName: string) => {
      const rowData: any[] = [{ cellValue: personName, conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1 } }]
      Object.values(us_mondaysInDateRange).forEach((date: any) => {
        const metricThisWeek: any = allPeople[personName][date]
        const isMetricDefined: boolean = metricThisWeek !== '-'
        rowData.push({
          cellValue: isMetricDefined ? metricThisWeek.toFixed(2) : '-',
          conditionalFormatting: {
            backgroundColor: isMetricDefined && metricThisWeek > 0 ? '#c82424' : '#28a56c',
            opacity: metricThisWeek === '-' ? 0.5 : new Date(date) > new Date() ? 0.5 : 1,
            fontWeight: '',
          },
        })
      })
      formattedData.data_rows.push(rowData)
    })

    // Append total metrics to summary rows
    formattedData.summary_rows.push(dateHeaders)
    ;['panels', 'units', 'batteryUnits'].forEach((metric) => {
      const metricRow: any[] = [{ cellValue: rLIB('Total'), conditionalFormatting: { fontWeight: 'bold' } }]
      Object.keys(totals[metric]).forEach((date) => {
        metricRow.push({
          cellValue: totals[metric][date],
          conditionalFormatting: { fontWeight: 'normal', opacity: 1 },
        })
      })
      formattedData.summary_rows.push(metricRow)
    })

    // Update state or context with the formatted data
  }, [us_mondaysInDateRange, us_crewHours])

  //format in summary rows and data rows for V2 component
  useEffect(() => {
    if (!us_crewHours) return

    const today: any = new Date()
    today.setHours(0, 0, 0, 0) // Normalize today's date

    const formattedData: any = {
      summary_rows: [],
      data_rows: [],
    }

    const dateHeaders: any[] = [{ cellValue: rLIB('Crew Hours'), conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
    const totals: any = {}
    const personData: any = {}

    // Initialize each date with a zero total custom metric and check if the date is in the future
    objectToArray(us_mondaysInDateRange).forEach((dateStr: string) => {
      const date: any = new Date(`${dateStr}T12:00:00`)
      const formattedDate: any = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
      const isFuture: any = date > today
      dateHeaders.push({
        cellValue: (
          <>
            {formattedDate}{' '}
            {us_refreshingColumn === 'crew_hours_' + returnFormattedDateKey(date) ? (
              <Icon
                icon="arrows-rotate"
                sx={{ color: themeVariables.success_main }}
                className="tw-ml-1 bp_spin"
                tooltip={rLIB('Refresh Data')}
              />
            ) : (
              <Icon
                icon="arrows-rotate"
                className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1"
                tooltip={rLIB('Refresh Data')}
                onClick={() => {
                  if (us_refreshingColumn == null) {
                    const formattedDateKey = returnFormattedDateKey(date)

                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        us_setRefreshingColumn('crew_hours_' + formattedDateKey)
                        ur_forceRerender()
                        cloudFunctionManageRequest('manageData', {
                          function: 'cacheGMDashboardCrewHoursData',
                          client_key: res_GCK.clientKey,
                          monday_date_key: formattedDateKey,
                        })
                          .then((res_CFMR) => {
                            us_setRefreshingColumn(null)
                          })
                          .catch((rej_CFMR) => {
                            console.error(rej_CFMR)
                            us_setRefreshingColumn(null)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
              />
            )}
          </>
        ),
        conditionalFormatting: { fontWeight: 'bold', opacity: isFuture ? 0.5 : 1, width: '120px' },
      })
      totals[formattedDate] = '-'
    })

    // Process each week's data
    Object.entries(us_crewHours).forEach(([dateStr, weekData]: [string, any]) => {
      const date: any = new Date(`${dateStr}T12:00:00`)
      const formattedDate: any = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

      Object.entries(weekData.data || {}).forEach(([personId, details]: [string, any]) => {
        if (
          typeof details === 'object' &&
          details !== null &&
          'field_hours' in details &&
          'total_panels' in details &&
          'total_units' in details &&
          'total_battery_units' in details &&
          'name' in details
        ) {
          const safeDetails: any = details
          const rightSideOfEquation = (safeDetails.total_panels + safeDetails.total_battery_units) / 2 + safeDetails.total_units
          const customMetric: any = safeDetails.field_hours !== 0 && rightSideOfEquation !== 0 ? safeDetails.field_hours - rightSideOfEquation : '-'

          if (!personData[safeDetails.name]) {
            personData[safeDetails.name] = { details: {}, dialogFunctions: {} }
            Object.keys(totals).forEach((date: any) => {
              personData[safeDetails.name].details[date] = '-'
              personData[safeDetails.name].dialogFunctions[date] = () => {}
            })
          }

          personData[safeDetails.name].details[formattedDate] = customMetric
          if (customMetric !== '-') {
            if (totals[formattedDate] === '-') {
              totals[formattedDate] = 0
            }
            totals[formattedDate] += customMetric
          }

          personData[safeDetails.name].dialogFunctions[formattedDate] = () => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                const formattedDateForQuery = formatDateToYYYYMMDD(date)
                const query = DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query(
                  res_GCK.clientKey,
                  formattedDateForQuery,
                  safeDetails.name,
                )

                return getDocs(query)
              })
              .then((snapshot) => {
                const fetchedData = snapshot.docs.map((doc) => doc.data())

                const projectDetails = fetchedData.flatMap((data: any) => {
                  return Object.entries(data.grouped_codes.FIELD || {}).map(([jobCode, hours]: [string, any]) => {
                    const [job, jobType] = jobCode.split(' - ')
                    const taskBreakdown: any = Object.values(data.unit_pay_task_breakdown || {}).find((task: any) => task.key === jobCode) as {
                      breakdown?: {
                        total_units: any
                        task_units: number
                        battery_units: number
                        pitch_units: number
                        adder_related_fields?: {
                          distance_from_warehouse: number
                        }
                      }
                    }

                    let panels = 0
                    let units = 0
                    let total: any = '-'
                    let shouldIncludeHoursInTotal = true
                    if (taskBreakdown && taskBreakdown.breakdown) {
                      if (taskBreakdown.task_unit_evaluation === 'units') {
                        units = taskBreakdown.breakdown.task_units
                        panels = 0
                        total = typeof hours === 'number' ? hours - (units + taskBreakdown.breakdown.battery_units + taskBreakdown.breakdown.pitch_units) : '-'
                      } else if (taskBreakdown.task_unit_evaluation === 'panels') {
                        units = 0
                        panels = taskBreakdown.breakdown.task_units
                        total =
                          typeof hours === 'number' ? hours - (panels + taskBreakdown.breakdown.battery_units + taskBreakdown.breakdown.pitch_units) / 2 : '-'
                      }
                      shouldIncludeHoursInTotal = !(
                        units === 0 &&
                        panels === 0 &&
                        taskBreakdown.breakdown.battery_units === 0 &&
                        taskBreakdown.breakdown.pitch_units === 0
                      )
                    } else {
                      shouldIncludeHoursInTotal = false
                    }

                    return {
                      jobCode: job,
                      jobType,
                      hours: typeof hours === 'number' ? hours : '-',
                      panels,
                      batteryAdders: taskBreakdown && taskBreakdown.breakdown ? taskBreakdown.breakdown.battery_units : '-',
                      pitchUnits: taskBreakdown && taskBreakdown.breakdown ? taskBreakdown.breakdown.pitch_units : '-',
                      distanceFromWarehouse:
                        taskBreakdown && taskBreakdown.breakdown && taskBreakdown.breakdown.adder_related_fields
                          ? taskBreakdown.breakdown.adder_related_fields.distance_from_warehouse
                          : '-',
                      units,
                      total: typeof total === 'number' ? total.toFixed(2) : total,
                      shouldIncludeHoursInTotal,
                    }
                  })
                })

                // Calculate totals for the dialog
                const totalsForDialog = {
                  hours: 0,
                  panels: 0,
                  batteryAdders: 0,
                  pitchUnits: 0,
                  units: 0,
                  total: 0,
                }

                projectDetails.forEach((project) => {
                  if (project.shouldIncludeHoursInTotal && typeof project.hours === 'number') totalsForDialog.hours += project.hours
                  if (typeof project.panels === 'number') totalsForDialog.panels += project.panels
                  if (typeof project.batteryAdders === 'number') totalsForDialog.batteryAdders += project.batteryAdders
                  if (typeof project.pitchUnits === 'number') totalsForDialog.pitchUnits += project.pitchUnits
                  if (typeof project.units === 'number') totalsForDialog.units += project.units

                  if (!isNaN(parseFloat(project.total))) totalsForDialog.total += parseFloat(project.total)
                })

                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Card>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="h6"
                              sx={{ marginRight: '16px' }}
                            >
                              {safeDetails.name}
                            </Typography>
                            <Typography variant="subtitle1">{formattedDate}</Typography>
                          </Box>
                        </Box>
                        <TableContainer component={Paper}>
                          <Table
                            aria-label="custom metrics table"
                            style={{ tableLayout: 'fixed', width: '100%' }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ width: '180px' }}>{rLIB('Job Code')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Job Type')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Hours')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Panels')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Battery Adders')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Units')}</TableCell>
                                <TableCell style={{ width: '120px' }}>{rLIB('Pitch Adders')}</TableCell> {/* New column header */}
                                <TableCell style={{ width: '120px' }}>{rLIB('Distance from Warehouse')}</TableCell> {/* New column header */}
                                <TableCell style={{ width: '120px' }}>{rLIB('Total')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {projectDetails.map((project: any, index: any) => (
                                <TableRow
                                  key={index}
                                  style={{ opacity: project.shouldIncludeHoursInTotal ? 1 : 0.5 }} // Apply opacity to the row
                                >
                                  <TableCell style={{ width: '180px' }}>{project.jobCode}</TableCell>
                                  <TableCell style={{ width: '120px' }}>{project.jobType}</TableCell>
                                  <TableCell style={{ width: '120px' }}>
                                    {typeof project.hours === 'number' ? project.hours.toFixed(2) : project.hours}
                                  </TableCell>
                                  <TableCell style={{ width: '120px' }}>{project.panels}</TableCell>
                                  <TableCell style={{ width: '120px' }}>{project.batteryAdders}</TableCell>
                                  <TableCell style={{ width: '120px' }}>{project.units}</TableCell>
                                  <TableCell style={{ width: '120px' }}>{project.pitchUnits}</TableCell> {/* New column data */}
                                  <TableCell style={{ width: '120px' }}>{project.distanceFromWarehouse}</TableCell> {/* New column data */}
                                  <TableCell
                                    style={{
                                      width: '120px',
                                      backgroundColor:
                                        typeof project.total === 'number'
                                          ? project.total > 0
                                            ? themeVariables.error_main
                                            : project.total < 0
                                              ? themeVariables.success_main
                                              : ''
                                          : '',
                                    }}
                                  >
                                    {typeof project.total === 'number' ? project.total.toFixed(2) : project.total}
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>{rLIB('Total')}</TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.hours.toFixed(2)}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.panels}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.batteryAdders}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.units}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.pitchUnits}</TableCell> {/* New column total */}
                                <TableCell style={{ fontWeight: 'bold' }}>{'-'}</TableCell> {/* Placeholder for Distance from Warehouse total */}
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor:
                                      totalsForDialog.total > 0 ? themeVariables.error_main : totalsForDialog.total < 0 ? themeVariables.success_main : '',
                                  }}
                                >
                                  {totalsForDialog.total.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    ),
                    settings: { max_width: 'lg' }, // Increase the max width of the dialog
                  },
                })
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
              })
          }
        }
      })
    })

    // Construct the data rows for each person
    Object.keys(personData)
      .sort()
      .forEach((personName: any) => {
        const rowData: any[] = [
          {
            cellValue: personName,
            conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
          },
        ]
        Object.keys(totals).forEach((date: any) => {
          const metricThisWeek: any = personData[personName].details[date]
          const isMetricDefined: any = metricThisWeek !== '-' && !isNaN(Number(metricThisWeek))
          const isFuture: boolean = new Date(date) > today
          rowData.push({
            cellValue: isMetricDefined ? Number(metricThisWeek).toFixed(2) : '-',
            conditionalFormatting: {
              backgroundColor: isMetricDefined
                ? Number(metricThisWeek) > 0
                  ? themeVariables.error_main
                  : Number(metricThisWeek) < 0
                    ? themeVariables.success_main
                    : ''
                : 'inherit',
              opacity: isFuture ? 0.5 : 1,
              cursor: isMetricDefined ? 'pointer' : 'default',
              width: '120px', // Ensure consistent width
            },
            dialogFunction: isMetricDefined ? personData[personName].dialogFunctions[date] : undefined,
          })
        })
        formattedData.data_rows.push(rowData)
      })

    // Append the total metrics summary row
    formattedData.summary_rows.push(dateHeaders)
    const totalMetricsRow: any[] = [
      {
        cellValue: 'Total',
        conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
      },
    ]
    Object.keys(totals).forEach((date: any) => {
      const totalValue: any = totals[date] === '-' ? '-' : totals[date].toFixed(2)
      const isFuture: boolean = new Date(date) > today
      totalMetricsRow.push({
        cellValue: totalValue,
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: totalValue > 0 ? themeVariables.error_main : totalValue < 0 ? themeVariables.success_main : '',
          opacity: isFuture ? 0.5 : 1,
          width: '120px',
        },
      })
    })
    formattedData.summary_rows.push(totalMetricsRow)

    us_setFormattedCrewHours(formattedData)
  }, [
    us_crewHours,
    us_mondaysInDateRange,
    uc_setUserInterface_CustomDialogDisplay,
    us_refreshingColumn,
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    ur_forceRerender,
  ])

  const formatDateToYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // getMonth() returns 0-based month
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  //  GET Safety Review Data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawSafetyReview(newData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SafetyReviewByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  //format safety reviews in summary rows and data rows for V2 component
  useEffect(() => {
    if (!us_rawSafetyReview) return

    const today: any = new Date()
    today.setHours(0, 0, 0, 0) // Normalize today's date

    const formattedData: any = {
      summary_rows: [],
      data_rows: [],
    }

    const dateHeaders: any[] = [{ cellValue: rLIB('Safety Reviews'), conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
    const totals: any = {}
    const teamData: any = {}
    const redColumns: any = {}

    // Initialize each date with a zero total custom metric and check if the date is in the future
    objectToArray(us_mondaysInDateRange).forEach((dateStr: string) => {
      const date: any = new Date(`${dateStr}T12:00:00`)
      const formattedDate: any = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
      const isFuture: any = date > today
      dateHeaders.push({
        cellValue: (
          <>
            {formattedDate}{' '}
            {us_refreshingColumn === 'safety_reviews_' + returnFormattedDateKey(date) ? (
              <Icon
                icon="arrows-rotate"
                sx={{ color: themeVariables.success_main }}
                className="tw-ml-1 bp_spin"
                tooltip={rLIB('Refresh Data')}
              />
            ) : (
              <Icon
                icon="arrows-rotate"
                className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1"
                tooltip={rLIB('Refresh Data')}
                onClick={() => {
                  if (us_refreshingColumn == null) {
                    const formattedDateKey = returnFormattedDateKey(date)
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        us_setRefreshingColumn('safety_reviews_' + formattedDateKey)
                        ur_forceRerender()
                        cloudFunctionManageRequest('manageData', {
                          function: 'cacheGMDashboardSafetyReviewData',
                          client_key: res_GCK.clientKey,
                          monday_date_key: formattedDateKey,
                        })
                          .then((res_CFMR) => {
                            us_setRefreshingColumn(null)
                          })
                          .catch((rej_CFMR) => {
                            console.error(rej_CFMR)
                            us_setRefreshingColumn(null)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
              />
            )}
          </>
        ),
        conditionalFormatting: { fontWeight: 'bold', opacity: isFuture ? 0.5 : 1, width: '120px' },
      })
      totals[formattedDate] = { passed_reviews: '-', failed_reviews: '-', task_count: '-' }
      redColumns[formattedDate] = false
    })

    // Process each week's data
    Object.entries(us_rawSafetyReview).forEach(([dateStr, weekData]: [string, any]) => {
      const date: any = new Date(`${dateStr}T12:00:00`)
      const formattedDate: any = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

      Object.entries(weekData.data || {}).forEach(([teamId, details]: [string, any]) => {
        if (details && 'passed_reviews' in details && 'failed_reviews' in details && 'name' in details && 'task_count' in details) {
          const safeDetails: any = details

          if (!teamData[safeDetails.name]) {
            teamData[safeDetails.name] = {}
            Object.keys(totals).forEach((date: any) => {
              teamData[safeDetails.name][date] = { passed_reviews: '-', failed_reviews: '-', task_count: '-' }
            })
          }

          teamData[safeDetails.name][formattedDate] = {
            passed_reviews: safeDetails.passed_reviews,
            failed_reviews: safeDetails.failed_reviews,
            task_count: safeDetails.task_count,
          }

          if (totals[formattedDate].passed_reviews === '-') {
            totals[formattedDate].passed_reviews = 0
          }
          totals[formattedDate].passed_reviews += safeDetails.passed_reviews

          if (totals[formattedDate].failed_reviews === '-') {
            totals[formattedDate].failed_reviews = 0
          }
          totals[formattedDate].failed_reviews += safeDetails.failed_reviews

          if (totals[formattedDate].task_count === '-') {
            totals[formattedDate].task_count = 0
          }
          totals[formattedDate].task_count += safeDetails.task_count
        }
      })
    })

    // Construct the data rows for each team
    Object.keys(teamData)
      .sort()
      .forEach((teamName: any) => {
        const rowData: any[] = [
          {
            cellValue: teamName,
            conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
          },
        ]
        Object.keys(totals).forEach((date: any) => {
          const reviews: any = teamData[teamName][date]
          const isMetricDefined: any = reviews.passed_reviews !== '-' || reviews.failed_reviews !== '-'
          const isFuture: boolean = new Date(date) > today
          const backgroundColor =
            reviews.failed_reviews > 0 || (reviews.task_count > 0 && reviews.passed_reviews + reviews.failed_reviews === 0)
              ? themeVariables.error_main
              : themeVariables.success_main

          if (backgroundColor === themeVariables.error_main) {
            redColumns[date] = true
          }

          rowData.push({
            cellValue:
              reviews.task_count === '-'
                ? '-'
                : reviews.task_count === 0
                  ? 'No tasks for the week'
                  : reviews.passed_reviews === 0 && reviews.failed_reviews === 0
                    ? 'Safety review not completed'
                    : isMetricDefined
                      ? `P: ${reviews.passed_reviews}${reviews.failed_reviews > 0 ? ` F: ${reviews.failed_reviews}` : ''}`
                      : '-',
            conditionalFormatting: {
              backgroundColor:
                reviews.task_count === '-' || reviews.task_count === 0 || isFuture || reviews.passed_reviews === '-' ? 'inherit' : backgroundColor,
              opacity: isFuture ? 0.5 : 1,
              cursor: 'default',
              width: '120px', // Ensure consistent width
            },
          })
        })
        formattedData.data_rows.push(rowData)
      })

    // Append the total metrics summary row
    formattedData.summary_rows.push(dateHeaders)
    const totalMetricsRow: any[] = [
      {
        cellValue: 'Total',
        conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
      },
    ]
    Object.keys(totals).forEach((date: any) => {
      const totalValue: any = totals[date]
      const isFuture: boolean = new Date(date) > today
      const backgroundColor = redColumns[date] ? themeVariables.error_main : themeVariables.success_main
      totalMetricsRow.push({
        cellValue:
          totalValue.task_count === '-'
            ? '-'
            : totalValue.task_count === 0
              ? 'No tasks for the week'
              : `P: ${totalValue.passed_reviews}${totalValue.failed_reviews > 0 ? ` F: ${totalValue.failed_reviews}` : ''}`,
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor:
            totalValue.task_count === '-' || totalValue.task_count === 0 || isFuture || totalValue.passed_reviews === '-' ? 'inherit' : backgroundColor,
          opacity: isFuture ? 0.5 : 1,
          width: '120px',
        },
      })
    })
    formattedData.summary_rows.push(totalMetricsRow)

    us_setFormattedSafetyReviews(formattedData)
  }, [us_rawSafetyReview, us_mondaysInDateRange, rLIB, us_refreshingColumn, uc_setRootData_ClientKey, ur_forceRerender, us_setFormattedSafetyReviews])

  //get cycle time data
  useEffect(() => {
    let unsubscribeLiveData: () => void
    const updateLiveData = (newData: any) => {
      // Map through each entry to add sales partner names
      const enrichedData = Object.keys(newData).reduce((acc: any, dateKey: any) => {
        const dailyData = newData[dateKey].data
        const enrichedDailyData = Object.keys(dailyData).reduce((accInner: any, projectKey: any) => {
          const projectData = dailyData[projectKey]
          const salesPartnerName = us_salesPartners[projectData.associated_sales_partner_key]?.name || 'Unknown Partner'
          accInner[projectKey] = { ...projectData, salesPartnerName }
          return accInner
        }, {})
        acc[dateKey] = { ...newData[dateKey], data: enrichedDailyData }
        return acc
      }, {})

      us_setCycleTimeRawData(enrichedData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CycleTimeByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender, us_salesPartners])

  //Get Sales Partners
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartners(newData)
      ur_forceRerender()
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  //process cycle time data
  useEffect(() => {
    if (!us_CycleTimeRawData || !us_mondaysInDateRange) return

    const today: any = new Date()
    today.setHours(0, 0, 0, 0) // Normalize today's date

    const formattedData: any = {
      summary_rows: [],
      data_rows: [],
    }

    const dateHeaders: any[] = [{ cellValue: 'Cycle Time (RTG to Install)', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]

    const totalAverages: any = {}
    objectToArray(us_mondaysInDateRange).forEach((monday: any) => {
      const date: Date = new Date(monday)
      date.setDate(date.getDate() + (date.getDay() === 0 ? 1 : 0)) // Adjust for Sunday start, shift to Monday
      const formattedDateKey: any = returnFormattedDateKey(date)
      const formattedDate: string = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
      const isFuture: boolean = date > today
      dateHeaders.push({
        cellValue: (
          <>
            {formattedDate}
            {us_refreshingColumn === 'cycle_times_' + formattedDateKey ? (
              <Icon
                icon="arrows-rotate"
                sx={{ color: themeVariables.success_main }}
                className="tw-ml-1 bp_spin"
                tooltip="Refresh Data"
              />
            ) : (
              <Icon
                icon="arrows-rotate"
                className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1"
                tooltip="Refresh Data"
                onClick={() => {
                  if (us_refreshingColumn == null) {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK: any) => {
                        us_setRefreshingColumn('cycle_times_' + formattedDateKey)
                        ur_forceRerender()
                        cloudFunctionManageRequest('manageData', {
                          function: 'cacheGMDashboardCycleTimesData',
                          client_key: res_GCK.clientKey,
                          monday_date_key: formattedDateKey,
                        })
                          .then((res_CFMR: any) => {
                            us_setRefreshingColumn(null)
                          })
                          .catch((rej_CFMR: any) => {
                            console.error(rej_CFMR)
                            us_setRefreshingColumn(null)
                          })
                      })
                      .catch((rej_GCK: any) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
              />
            )}
          </>
        ),
        conditionalFormatting: { fontWeight: 'bold', width: '120px', opacity: isFuture ? 0.5 : 1 },
      })
      totalAverages[monday] = []
    })

    formattedData.summary_rows.push(dateHeaders)

    const partnersRegionsDelays: any = {}
    Object.entries<any>(us_CycleTimeRawData).forEach(([dateStr, weekData]: [string, any]) => {
      Object.values<any>(weekData.data).forEach((entry: any) => {
        // Check if both install_date and install_picc_rtg are present
        if (entry.install_date && entry.install_picc_rtg) {
          if (entry.time_delay_from_rtg_to_install !== 0) {
            totalAverages[dateStr].push(entry.time_delay_from_rtg_to_install)
          }
          const partnerRegionKey: string = `${entry.salesPartnerName} - ${entry.associated_region_name}`
          if (!partnersRegionsDelays[partnerRegionKey]) {
            partnersRegionsDelays[partnerRegionKey] = {}
          }
          if (!partnersRegionsDelays[partnerRegionKey][dateStr]) {
            partnersRegionsDelays[partnerRegionKey][dateStr] = []
          }
          if (entry.time_delay_from_rtg_to_install !== 0) {
            partnersRegionsDelays[partnerRegionKey][dateStr].push(entry.time_delay_from_rtg_to_install)
          }
        }
      })
    })

    const totalRow: any[] = [{ cellValue: 'Average', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
    Object.keys(totalAverages).forEach((monday: any) => {
      const delays: any = totalAverages[monday]
      let averageDelay: any = '-'
      if (delays.length > 0) {
        averageDelay = Math.round(delays.reduce((acc: number, cur: number) => acc + cur, 0) / delays.length) + ' days'
        totalRow.push({
          cellValue: averageDelay,
          conditionalFormatting: {
            fontWeight: 'normal',
            backgroundColor: parseInt(averageDelay) > 14 ? themeVariables.error_main : themeVariables.success_main,
            opacity: 1,
            width: '120px',
          },
        })
      } else {
        totalRow.push({
          cellValue: '-',
          conditionalFormatting: {
            fontWeight: 'normal',
            backgroundColor: '',
            opacity: 1,
            width: '120px',
          },
        })
      }
    })

    formattedData.summary_rows.push(totalRow)

    Object.keys(partnersRegionsDelays).forEach((partnerRegionKey: any) => {
      const rowData: any[] = [
        {
          cellValue: partnerRegionKey,
          conditionalFormatting: { fontWeight: 'bold', width: '200px' },
        },
      ]

      objectToArray(us_mondaysInDateRange).forEach((monday: any) => {
        const delays: any = partnersRegionsDelays[partnerRegionKey][monday] || []
        let averageDelay: string = '-'
        if (delays.length > 0) {
          averageDelay = Math.round(delays.reduce((acc: number, cur: number) => acc + cur, 0) / delays.length) + ' days' // No decimals
        }
        rowData.push({
          cellValue: averageDelay,
          conditionalFormatting: {
            backgroundColor: averageDelay !== '-' ? (parseInt(averageDelay) > 14 ? themeVariables.error_main : themeVariables.success_main) : '',
            opacity: 1,
            cursor: 'pointer',
            width: '120px',
          },
          dialogFunction: () => {
            const detailData = us_CycleTimeRawData[monday]?.data || {}
            const dialogEntries = Object.values(detailData).filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <Card>
                    <Box sx={{ padding: '16px' }}>
                      <Typography variant="h6">{partnerRegionKey}</Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID Number</TableCell>
                              <TableCell>PICC/RTG</TableCell> {/* Added new column for PICC/RTG */}
                              <TableCell>Install Date</TableCell>
                              <TableCell>Delay (Days)</TableCell>
                              <TableCell>Region Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dialogEntries.map((project: any, index) => (
                              <TableRow key={index}>
                                <TableCell>{project.id_number}</TableCell>
                                <TableCell>{new Date(project.install_picc_rtg).toISOString().split('T')[0]}</TableCell> {/* Format PICC/RTG date */}
                                <TableCell>{project.install_date}</TableCell>
                                <TableCell>{project.time_delay_from_rtg_to_install}</TableCell>
                                <TableCell>{project.associated_region_name}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Card>
                ),
                settings: { max_width: 'lg' },
              },
            })
          },
        })
      })

      if (rowData.length > 1) {
        // Ensure there's data beyond the header before adding to the display
        formattedData.data_rows.push(rowData)
      }
    })

    us_setFormattedCycleTimeRawData(formattedData)
  }, [us_CycleTimeRawData, us_mondaysInDateRange, us_refreshingColumn, uc_setRootData_ClientKey, ur_forceRerender, us_salesPartners])

  // JSX Generation
  const changeCalendarStartMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedStartDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const changeCalendarEndMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedEndDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const rJSX_StartMonthPicker = (disabled: boolean, us_selectedStartDate: Date | null, changeCalendarStartMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('Start Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedStartDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarStartMonth(startOfMonth(newValue))
            } else {
              changeCalendarStartMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  const rJSX_EndMonthPicker = (disabled: boolean, us_selectedEndDate: Date | null, changeCalendarEndMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('End Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedEndDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarEndMonth(endOfMonth(newValue))
            } else {
              changeCalendarEndMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true, // Prevent manual editing
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  // const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
  //   let dropdownJSX = (
  //     <Box className="tw-inline-block tw-align-top">
  //       <FormControl className="bp_thin_select_input">
  //         <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
  //         <Select
  //           id={'region_filter'}
  //           labelId={'region_filter'}
  //           color="primary"
  //           value={us_selectedRegion}
  //           label={rLIB('Region')}
  //           onChange={(event: any) => {
  //             if (event != null && event.target != null && event.target.value != null) {
  //               us_setSelectedRegion(event.target.value)
  //             }
  //           }}
  //           variant="outlined"
  //         >
  //           <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
  //           {objectToArray(us_regionLists)
  //             .sort(dynamicSort('name', null))
  //             .map((option: TsInterface_UnspecifiedObject, index: number) => (
  //               <MenuItem
  //                 key={index}
  //                 value={option['key']}
  //               >
  //                 {option['name']}
  //               </MenuItem>
  //             ))}
  //         </Select>
  //       </FormControl>
  //     </Box>
  //   )
  //   return dropdownJSX
  // }

  const getFirstMonday = (date: Date): Date => {
    const resultDate = new Date(date)
    if (resultDate.getDate() === 1 && resultDate.getDay() === 1) {
      return resultDate
    }
    resultDate.setDate(resultDate.getDate() + ((1 - resultDate.getDay() + 7) % 7))
    return resultDate
  }

  const getLastSundayAfterLastMonday = (date: Date): Date => {
    const resultDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    resultDate.setDate(resultDate.getDate() + ((7 - resultDate.getDay()) % 7))
    return resultDate
  }

  const generateWeeklyDateRanges = (start: Date, end: Date): string[] => {
    let current = new Date(start)
    const endDate = new Date(end)
    const weeks = []

    while (current <= endDate) {
      const weekStart = new Date(current)
      const weekEnd = new Date(current)
      weekEnd.setDate(weekEnd.getDate() + 6)

      weeks.push(`${formatDateToYYYYMMDD(weekStart)} to ${formatDateToYYYYMMDD(weekEnd)}`)

      current.setDate(current.getDate() + 7)
    }

    return weeks
  }

  const generateMonthlyDateRanges = (startDate: Date, endDate: Date): string[] => {
    const months: string[] = []
    let currentMonthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
    while (currentMonthStart <= endDate) {
      const monthEnd = new Date(currentMonthStart.getFullYear(), currentMonthStart.getMonth() + 1, 0)
      months.push(`${currentMonthStart.toISOString().split('T')[0]} to ${monthEnd.toISOString().split('T')[0]}`)
      currentMonthStart.setMonth(currentMonthStart.getMonth() + 1)
    }
    return months
  }

  const isDateInRange = (date: Date, start: Date, end: Date) => {
    return date >= start && date <= end
  }

  const rJSX_CycleTimeExportButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="file-arrow-down" />}
        disabled={us_downloadingCycleTimes}
        onClick={() => {
          us_setDownloadingCycleTimes(true)
          downloadCycleTimeCSV()
            .then((res_DNC: any) => {
              us_setDownloadingCycleTimes(false)
            })
            .catch((res_DNC: any) => {
              us_setDownloadingCycleTimes(false)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: res_DNC.error,
              })
            })
        }}
      >
        {rLIB('Download Cycle Times')}
      </Button>
    )
    return buttonJSX
  }

  const downloadCycleTimeCSV = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // First get the task blueprint data
          DatabaseGetCollection(DatabaseRef_TaskBlueprints_Collection(res_GCK.clientKey))
            .then((res_TaskBlueprint) => {
              const taskNameMap: any = {}
              // Create a map of task keys to human-readable names
              Object.keys(res_TaskBlueprint.data).forEach((key) => {
                if (res_TaskBlueprint.data[key].name) {
                  taskNameMap[key] = res_TaskBlueprint.data[key].name
                }
              })

              // Now get the project data
              DatabaseGetCollection(DatabaseRef_CycleTimeByActiveProject_Query(res_GCK.clientKey))
                .then((res_DGC) => {
                  let csvData = [['Job Code', 'Task Name', 'Completion Date']] // Include headers as the first row

                  // Iterate over each project/job
                  for (let jobKey in res_DGC.data) {
                    let job = res_DGC.data[jobKey]
                    let jobCode = job.id_number // Job code for this job

                    // Gather all tasks and prepare them for sorting
                    const tasks = Object.entries(job.task_completion_timestamps)
                      .map(([taskKey, timestamp]) => {
                        // Clean up task key by removing the first number and underscore
                        let cleanedTaskKey = taskKey.split('_').slice(1).join('_')
                        let taskName = taskNameMap[cleanedTaskKey] || cleanedTaskKey // Use map or cleaned key
                        return { taskName, timestamp: timestamp ? Number(timestamp) : null }
                      })
                      .filter((task) => task.timestamp !== null) // Filter out tasks without a timestamp

                    // Sort tasks by timestamp, and alphabetically if timestamps are the same
                    tasks.sort((a: any, b: any) => {
                      if (a.timestamp === b.timestamp) {
                        return a.taskName.localeCompare(b.taskName) // Alphabetical sort if timestamps are the same
                      }
                      return a.timestamp - b.timestamp // Timestamp sort
                    })

                    // Add sorted tasks to the csvData with job code on each line
                    tasks.forEach(({ taskName, timestamp }) => {
                      if (timestamp !== null) {
                        let formattedDate = new Date(timestamp).toISOString().split('T')[0]
                        csvData.push([jobCode, taskName, formattedDate])
                      }
                    })
                  }

                  downloadCSV('CycleTimeExport', csvData)
                    .then((res_DC) => {
                      resolve(res_DC)
                    })
                    .catch((rej_DC) => {
                      reject(rej_DC)
                    })
                })
                .catch((rej_DGC) => {
                  reject(rej_DGC)
                })
            })
            .catch((rej_TaskBlueprint) => {
              reject(rej_TaskBlueprint)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const rJSX_Page = (): JSX.Element => {
    return (
      <AuthenticatedContainer
        pageHeader={rLIB('GM Dashboard')}
        pageKey={pageKey}
        content={
          <Box>
            {/* Global Controls Above the Accordion */}
            <Box className="mb-2 flex items-center gap-2">
              {rJSX_StartMonthPicker(false, us_selectedStartDate, changeCalendarStartMonth)}
              {rJSX_EndMonthPicker(false, us_selectedEndDate, changeCalendarEndMonth)}
              {rJSX_CycleTimeExportButton()}
              {/* {rJSX_WeeklyMonthlyToggle()} */}
            </Box>

            <Card>
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.success_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('100 or more')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.error_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('Less than 100')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_panelsInstalledFormattedData.summary_rows,
                  data_rows: us_panelsInstalledFormattedData.data_rows,
                }}
              />
            </Card>
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box className="">
                    <Typography
                      variant="body1"
                      sx={{ marginBottom: 2 }}
                    >
                      {rLIB('Hours against max allocation. Includes pitch and roof type adders for install. Does not include distance adders.')}
                    </Typography>

                    {/* Legend for "less hours than max allocation" */}
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.success_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('Employee took less hours than max allocation')}</Typography>
                    </Stack>

                    {/* Legend for "more hours than max allocation" */}
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.error_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('Employee took more hours than max allocation')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_formattedCrewHours.summary_rows,
                  data_rows: us_formattedCrewHours.data_rows,
                }}
              />
            </Card>

            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.success_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('Safety review completed')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.error_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('Safety review failed or not completed')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_formattedSafetyReviews.summary_rows,
                  data_rows: us_formattedSafetyReviews.data_rows,
                }}
              />
            </Card>
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.success_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box sx={{ width: '20px', height: '20px', backgroundColor: themeVariables.error_main, borderRadius: '5px' }}></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_formattedCycleTimeRawData.summary_rows,
                  data_rows: us_formattedCycleTimeRawData.data_rows,
                }}
              />
            </Card>
          </Box>
        }
      />
    )
  }

  // Render
  return <>{rJSX_Page()}</>
}
