//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			// Deploy to GCP
			// https://docs.meilisearch.com/learn/cookbooks/gcp.html

		TODO:
			Prod mode keys
				https://docs.meilisearch.com/learn/security/master_api_keys.html#protecting-a-meilisearch-instance

			Implement on Tables
				probably new search type
				pass in params
					type: "meilisearch"
					resultRenderer: (): JSX => {}
					client_key: string
					search_index: string

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Autocomplete, Box, TextField } from '@mui/material/'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MeiliSearch from 'meilisearch'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { searchEnginePrefix } from 'rfbp_aux/config/config'
import { searchConfig } from 'rfbp_aux/config/search_config'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { TsType_TableSearchResultRenderer } from 'rfbp_core/components/table/table_types'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  // propKey: any
  clientKey: string
  searchIndexKey: string
  searchResultRenderer: TsType_TableSearchResultRenderer
  searchFilters: (string | string[])[]
  additionalSearchData: TsInterface_UnspecifiedObject
  defaultSearchValue?: string
  searchResultLimit?: number
  sx?: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_FAILED_TO_CREATE_SEARCH_INDEX: JSX.Element = <Trans>Failed to create search index</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing Required Parameters</Trans>
const se_SEARCH: string = 'Search'
// { sort-end } - displayed text

// Create Meilisearch Search Client
let searchClient: any = null
if (searchConfig != null && searchConfig.host !== '' && searchConfig.apiKey !== '') {
  searchClient = new MeiliSearch(searchConfig)
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

// Meilisearch Helper Functions - Index Management
export const createSearchIndex = (
  clientKey: string,
  searchIndexKey: string,
  itemKey: string, // ussually "id"
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .createIndex(combinedIndexKey, { primaryKey: itemKey })
        .then((res_CI: any) => {
          resolve(res_CI)
        })
        .catch((rej_CI: any) => {
          console.error(rej_CI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_CI.message,
              code: 'ER-D-SI-CI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-CI-02',
        },
      })
    }
  })
}

export const deleteSearchIndex = (clientKey: string, searchIndexKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .deleteIndex(combinedIndexKey)
        .then((res_DI: any) => {
          resolve(res_DI)
        })
        .catch((rej_DI: any) => {
          console.error(rej_DI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_DI.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const listSearchIndices = (
  offset: number, // default 0
  limit: number, // default 20
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      searchClient
        .getIndexes({ offset: offset, limit: limit })
        .then((res_GI: any) => {
          resolve(res_GI)
        })
        .catch((rej_GI: any) => {
          console.error(rej_GI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GI.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const getSpecificSearchIndexInfo = (clientKey: string, searchIndexKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .getSettings()
        .then((res_GI: any) => {
          resolve(res_GI)
        })
        .catch((rej_GI: any) => {
          console.error(rej_GI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GI.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-GSSII-02',
        },
      })
    }
  })
}

export const getSearchIndexStats = (clientKey: string, searchIndexKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .getStats()
        .then((res_GI: any) => {
          resolve(res_GI)
        })
        .catch((rej_GI: any) => {
          console.error(rej_GI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GI.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-GSSII-02',
        },
      })
    }
  })
}

export const updateSearchIndexSettings = (clientKey: string, searchIndexKey: string, newSettings: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .updateSettings(newSettings)
        .then((res_GI: any) => {
          resolve(res_GI)
        })
        .catch((rej_GI: any) => {
          console.error(rej_GI)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GI.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-GSSII-02',
        },
      })
    }
  })
}

// Meilisearch Helper Functions - Get Documents
export const getDocumentsFromSearchIndex = (
  clientKey: string,
  searchIndexKey: string,
  offset: number, // default 0
  limit: number, // default 20
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .getDocuments({ offset: offset, limit: limit })
        .then((res_GD: any) => {
          resolve(res_GD)
        })
        .catch((rej_GD: any) => {
          console.error(rej_GD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const getDocumentFromSearchIndex = (clientKey: string, searchIndexKey: string, documentKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .getDocument(documentKey, { fields: '*' })
        .then((res_GD: any) => {
          resolve(res_GD)
        })
        .catch((rej_GD: any) => {
          console.error(rej_GD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// Meilisearch Helper Functions - Add / Update Documents
export const addDocumentsToSearchIndex = (clientKey: string, searchIndexKey: string, documentArray: TsInterface_UnspecifiedObject[]): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .addDocuments(documentArray)
        .then((res_AD: any) => {
          resolve(res_AD)
        })
        .catch((rej_AD: any) => {
          console.error(rej_AD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_AD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const updateDocumentsInSearchIndex = (clientKey: string, searchIndexKey: string, documentArray: TsInterface_UnspecifiedObject[]) => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .updateDocuments(documentArray)
        .then((res_UD: any) => {
          resolve(res_UD)
        })
        .catch((rej_UD: any) => {
          console.error(rej_UD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_UD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// Meilisearch Helper Functions - Delete Documents
export const deleteAllDocumentsFromSearchIndex = (clientKey: string, searchIndexKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .deleteAllDocuments()
        .then((res_DAD: any) => {
          resolve(res_DAD)
        })
        .catch((rej_DAD: any) => {
          console.error(rej_DAD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_DAD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const deleteSpecifiedDocumentsFromSearchIndex = (clientKey: string, searchIndexKey: string, documentKeysArray: string[]): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .deleteDocuments(documentKeysArray)
        .then((res_DD: any) => {
          resolve(res_DD)
        })
        .catch((rej_DD: any) => {
          console.error(rej_DD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_DD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

export const deleteDocumentFromSearchIndex = (clientKey: string, searchIndexKey: string, documentKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .deleteDocument(documentKey)
        .then((res_DD: any) => {
          resolve(res_DD)
        })
        .catch((rej_DD: any) => {
          console.error(rej_DD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_DD.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// Keys
export const getSearchClientKeys = (): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      searchClient
        .getKeys()
        .then((res_GK: any) => {
          resolve(res_GK)
        })
        .catch((rej_GK: any) => {
          console.error(rej_GK)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GK.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// Filters
export const getSearchIndexFilters = (clientKey: string, searchIndexKey: string) => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .getFilterableAttributes()
        .then((res_GFA: any) => {
          resolve(res_GFA)
        })
        .catch((rej_GFA: any) => {
          console.error(rej_GFA)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_GFA.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// TODO - Has to be done for new clients and indices
export const updateSearchIndexFilters = (
  clientKey: string,
  searchIndexKey: string,
  filterableAttributes: string[],
  searchableAttributes: string[],
  displayedAttributes: string[],
) => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .updateFilterableAttributes(filterableAttributes)
        .then((res_UFA: any) => {
          searchClient
            .index(combinedIndexKey)
            .updateSearchableAttributes(searchableAttributes)
            .then((res_USA: any) => {
              searchClient
                .index(combinedIndexKey)
                .updateDisplayedAttributes(displayedAttributes)
                .then((res_UDA: any) => {
                  resolve(res_UDA)
                })
                .catch((rej_UDA: any) => {
                  console.error(rej_UDA)
                  reject({
                    success: false,
                    error: {
                      message: s_FAILED_TO_CREATE_SEARCH_INDEX,
                      details: rej_UDA.message,
                      code: 'ER-D-SI-DSI-01',
                    },
                  })
                })
            })
            .catch((rej_USA: any) => {
              console.error(rej_USA)
              reject({
                success: false,
                error: {
                  message: s_FAILED_TO_CREATE_SEARCH_INDEX,
                  details: rej_USA.message,
                  code: 'ER-D-SI-DSI-02',
                },
              })
            })
        })
        .catch((rej_UFA: any) => {
          console.error(rej_UFA)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_UFA.message,
              code: 'ER-D-SI-DSI-03',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-04',
        },
      })
    }
  })
}

// Meilisearch Helper Functions - Search
export const searchIndex = (
  clientKey: string,
  searchIndexKey: string,
  searchQuery: string,
  searchFilters: (string | string[])[],
  searchResultLimit: number | null | undefined,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (searchClient != null) {
      let offset = 0
      if (searchResultLimit == null) {
        searchResultLimit = 20
      }
      let combinedIndexKey = searchEnginePrefix + clientKey + '_' + searchIndexKey
      searchClient
        .index(combinedIndexKey)
        .search(searchQuery, {
          filter: searchFilters,
          offset: offset,
          limit: searchResultLimit,
          // filter: [['genres = Horror', 'genres = Mystery'], 'director = "Jordan Peele"']
        })
        .then((res_S: any) => {
          resolve(res_S)
        })
        .catch((rej_S: any) => {
          console.error(rej_S)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CREATE_SEARCH_INDEX,
              details: rej_S.message,
              code: 'ER-D-SI-DSI-01',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_SEARCH_INDEX,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-SI-DSI-02',
        },
      })
    }
  })
}

// Helper Functions
export const rJSX_HighlightedSearchString = (searchInputValue: string | null, stringToHighlight: string): JSX.Element => {
  let stringJSX = <></>
  if (stringToHighlight != null) {
    let displayStringSearchMatches: [number, number][] = []
    if (searchInputValue != null) {
      displayStringSearchMatches = match(stringToHighlight, searchInputValue)
    }
    const displayStringSearchParts = parse(stringToHighlight, displayStringSearchMatches)
    stringJSX = (
      <>
        {displayStringSearchParts.map((part: TsInterface_UnspecifiedObject, index: number) => (
          <Box
            key={index}
            component="span"
            sx={{
              backgroundColor: part.highlight ? themeVariables.warning_dark : 'rgba(0,0,0,0)',
              fontWeight: part.highlight ? 'bold' : 'regular',
              opacity: part.highlight ? '100%' : '30%',
            }}
          >
            {part.text}
          </Box>
        ))}
      </>
    )
  }
  return stringJSX
}

// Component
export const SearchInput = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_clientKey: TsInterface_ComponentProps['clientKey'] = getProp(props, 'clientKey', null)
  let pr_searchIndexKey: TsInterface_ComponentProps['searchIndexKey'] = getProp(props, 'searchIndexKey', null)
  let pr_searchFilters: TsInterface_ComponentProps['searchFilters'] = getProp(props, 'searchFilters', [])
  let pr_searchResultRenderer: TsInterface_ComponentProps['searchResultRenderer'] = getProp(
    props,
    'searchResultRenderer',
    (option: TsInterface_UnspecifiedObject, searchInputValue: string, inputHooks: TsInterface_InputHooksObject) => {
      return <>{option.id}</>
    },
  )
  let pr_additionalSearchData: TsInterface_ComponentProps['additionalSearchData'] = getProp(props, 'additionalSearchData', {})
  let pr_defaultSearchValue: TsInterface_ComponentProps['defaultSearchValue'] = getProp(props, 'defaultSearchValue', null)
  let pr_searchResultLimit: TsInterface_ComponentProps['searchResultLimit'] = getProp(props, 'searchResultLimit', 20)
  let pr_sx: TsInterface_ComponentProps['sx'] = getProp(props, 'sx', { width: '280px', height: '36px' })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_open, us_setOpen] = useState<boolean>(false)
  const [us_searchInputValue, us_setSearchInputValue] = useState<string | null>(pr_defaultSearchValue as string | null)
  const [us_searchOptions, us_setSearchOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const optionRefs = useRef(
    us_searchOptions.reduce((acc, option) => {
      acc[option.id] = React.createRef()
      return acc
    }, {}),
  )
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (pr_clientKey != null && pr_searchIndexKey != null && us_searchInputValue != null && us_searchInputValue !== '') {
      searchIndex(pr_clientKey, pr_searchIndexKey, us_searchInputValue, pr_searchFilters, pr_searchResultLimit).then((res_SI) => {
        let searchResults = getProp(res_SI, 'hits', [])
        us_setSearchOptions(searchResults)
      })
    } else {
      us_setSearchOptions([])
    }
    return () => {}
  }, [pr_clientKey, pr_searchIndexKey, us_searchInputValue, pr_searchFilters, pr_searchResultLimit])

  // Other Variables
  const inputHooks: TsInterface_InputHooksObject = {
    uc_RootData_ClientKey: uc_RootData_ClientKey,
    uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
    uc_RootData_ClientUser: uc_RootData_ClientUser,
    uc_RootData_GlobalUser: uc_RootData_GlobalUser,
    ur_forceRerender: ur_forceRerender,
    un_routerNavigation: un_routerNavigation,
    uc_setRootData_ClientKey: uc_setRootData_ClientKey,
    uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_LoadingBarDisplay: uc_setUserInterface_LoadingBarDisplay,
    uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
    us_setSearchInputValue: us_setSearchInputValue,
    us_setOpen: us_setOpen,
    us_searchOptions: us_searchOptions,
    optionRefs: optionRefs,
  }

  // Functions
  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault() // Prevent default arrow down behavior
      const activeElement = document.activeElement

      console.log(activeElement)

      let currentIndex = 0
      if (activeElement === document.getElementById('search_input')) {
        console.log('HIGHLIGHTING SEARCH')
        currentIndex = -1
      } else {
        console.log('HIGHLIGHTING OPTION')
        try {
          currentIndex = us_searchOptions.findIndex((option) => optionRefs.current[option.id].current === activeElement)
        } catch (err) {
          console.error(err)
        }
      }

      console.log(currentIndex)

      const nextIndex = currentIndex + 1 < us_searchOptions.length ? currentIndex + 1 : 0

      console.log(nextIndex)

      // refs.current[us_searchOptions[nextIndex].id].current.focus()
    }
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box
        className="bp_thin_autocomplete_input tw-inline-block"
        sx={pr_sx}
      >
        <Autocomplete
          freeSolo
          getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.id)}
          onOpen={() => {
            us_setOpen(true)
          }}
          onClose={(event: any, reason: any) => {
            us_setOpen(false)
          }}
          open={us_open}
          filterOptions={(x) => x}
          options={us_searchOptions}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={us_searchInputValue}
          noOptionsText={rLIB('No Search Results')}
          onChange={(event: any, newValue: any) => {
            us_setOpen(false)
          }}
          onInputChange={(event, newInputValue) => {
            us_setSearchInputValue(newInputValue)
          }}
          id="search_input"
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={handleSearchKeyDown}
              placeholder={se_SEARCH}
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            return <Box key={option.id}>{pr_searchResultRenderer(option, us_searchInputValue, inputHooks, pr_additionalSearchData)}</Box>
          }}
        />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
