///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			MAYBE Write cloud function to read only prod data at path and then have them select the data they want to copy to staging
				Feel like I built this utility before somewhere...


			// TODO - get collections on server and return and then pull list of documents in collection


			const admin = require('firebase-admin');

			// Initialize Firebase Admin SDK
			admin.initializeApp({
			credential: admin.credential.applicationDefault(),
			databaseURL: 'YOUR_DATABASE_URL'
			});

			async function getSubcollectionIds(path) {
			try {
				const collections = await admin.firestore().doc(path).listCollections();
				const collectionIds = collections.map(collection => collection.id);
				return collectionIds;
			} catch (error) {
				console.error('Error getting subcollection IDs:', error);
				return [];
			}
			}

			// Example usage:
			const path = 'YOUR_DOCUMENT_PATH'; // Replace this with your document path
			getSubcollectionIds(path)
			.then(subcollectionIds => {
				console.log('Subcollection IDs:', subcollectionIds);
			})
			.catch(error => {
				console.error('Error:', error);
			});




	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material/'
import React, { useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_RootClient_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { exampleHtmlEmailDataObject, exampleHtmlEmailTemplateObject, generateHtmlForEmailFromTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_COPY_TO: JSX.Element = <Trans>Copy To</Trans>
const s_DATABASE_MANAGEMENT_UTILITY: JSX.Element = <Trans>Database Management Utility</Trans>
const s_PRODUCTION: JSX.Element = <Trans>Production</Trans>
const s_STAGING: JSX.Element = <Trans>Staging</Trans>
const s_TEST_LAB: JSX.Element = <Trans>Test Lab</Trans>
const se_TEST_LAB = 'Test Lab'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// const moveProjectDataBetweenClients = (
// originClientKey: string,
// originProjectKey: string,
// destinationClientKey: string,
// destinationProjectKey: string,
// ) => {
// // Instantiate Variables
// let promiseArray1: TsType_UnknownPromise[] = []
// let promiseArray2: TsType_UnknownPromise[] = []
// let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
// let contactLogs: TsInterface_UnspecifiedObject | null = null
// let documentsAgreements: TsInterface_UnspecifiedObject | null = null
// let documentsDesign: TsInterface_UnspecifiedObject | null = null
// let documentsPermits: TsInterface_UnspecifiedObject | null = null
// let designPreferences: TsInterface_UnspecifiedObject | null = null
// let projectNotes: TsInterface_UnspecifiedObject | null = null
// let projectNotesSubdata: TsInterface_UnspecifiedObject = {}
// let taskFormData: TsInterface_UnspecifiedObject | null = null
// // Get Data
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectContactLogs_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ contactLogs = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "agreements" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsAgreements = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "design" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsDesign = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "permits" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsPermits = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectDesignPreferences_Document( originClientKey, originProjectKey )).then((res_DGD) => {
// 	if(res_DGD != null && res_DGD.data != null){ designPreferences = res_DGD.data }
// }).catch((rej_DGD) => {
// 	console.error(rej_DGD)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreads_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ projectNotes = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectTaskFormData_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ taskFormData = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// // Initial Data Loaded
// Promise.all( promiseArray1 ).finally(() => {
// 	for( let loopKey in projectNotes ){
// 		promiseArray2.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreadNotes_Collection( originClientKey, originProjectKey, loopKey )).then((res_DGC) => {
// 			if(res_DGC != null && res_DGC.data != null){ projectNotesSubdata[ loopKey ] = res_DGC.data }
// 		}).catch((rej_DGC) => {
// 			console.error(rej_DGC)
// 		}) )
// 	}
// 	Promise.all( promiseArray2 ).finally(() => {
// 		// Subsequent Data Loaded
// 		if( contactLogs != null ){
// 			for( let loopKey in contactLogs ){
// 				let loopData = contactLogs[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectContactLogs_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsAgreements != null ){
// 			for( let loopKey in documentsAgreements ){
// 				let loopData = documentsAgreements[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "agreements", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsDesign != null ){
// 			for( let loopKey in documentsDesign ){
// 				let loopData = documentsDesign[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "design", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsPermits != null ){
// 			for( let loopKey in documentsPermits ){
// 				let loopData = documentsPermits[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "permits", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( designPreferences != null ){
// 			updateArray.push({
// 				type: "setMerge",
// 				ref: DatabaseRef_ProjectDesignPreferences_Document( destinationClientKey, destinationProjectKey ),
// 				data: designPreferences
// 			})
// 		}
// 		if( taskFormData != null ){
// 			for( let loopKey in taskFormData ){
// 				let loopData = taskFormData[ loopKey ]
// 				// TODO - change key
// 				let newLoopKey = loopKey.replace(originProjectKey, destinationProjectKey)
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectTaskFormData_Document( destinationClientKey, destinationProjectKey, newLoopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( projectNotes != null ){
// 			for( let loopKey in projectNotes ){
// 				let loopData = projectNotes[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectNoteThreads_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 				if( projectNotesSubdata != null && projectNotesSubdata[loopKey] != null ){
// 					for( let subLoopKey in projectNotesSubdata[ loopKey ] ){
// 						let subLoopData = projectNotesSubdata[ loopKey ][ subLoopKey ]
// 						updateArray.push({
// 							type: "setMerge",
// 							ref: DatabaseRef_ProjectNoteThreadNotes_Document( destinationClientKey, destinationProjectKey, loopKey, subLoopKey ),
// 							data: subLoopData
// 						})
// 					}
// 				}
// 			}
// 		}
// 		console.log( "<><><><><><><><>" )
// 		console.log( updateArray )
// 		DatabaseBatchUpdate( updateArray ).then((res_DBU) => {
// 			console.log( res_DBU )
// 		}).catch((rej_DBU) => {
// 			console.error( rej_DBU )
// 		})
// 	})
// })
// }

// Database Management Utility
let databaseStructureOptions: TsInterface_UnspecifiedObject = {
  directory: {
    type: 'collection',
    key: 'directory',
    docs: {
      equipment: {
        type: 'doc',

        key: 'equipment',
        collections: {
          groups: {
            type: 'collection',
            key: 'groups',
            dynamic_doc_keys: true,
            docs: {
              $dynamicKey: {
                type: 'doc',
              },
            },
          },
          kits: {
            type: 'collection',
            key: 'kits',
          },
          main: {
            type: 'collection',
            key: 'main',
          },
          manufacturers: {
            type: 'collection',
            key: 'manufacturers',
          },
          types: {
            type: 'collection',
            key: 'types',
          },
        },
      },
      regions: {
        key: 'regions',
      },
      sales_partners: {
        key: 'sales_partners',
      },
      task_blueprints: {
        key: 'task_blueprints',
      },
    },
  },
  finances: {
    type: 'collection',
    key: 'finances',
  },
  integrations: {
    type: 'collection',
    key: 'integrations',
  },
  machine_learning: {
    type: 'collection',
    key: 'machine_learning',
  },
  materials: {
    type: 'collection',
    key: 'materials',
  },
  operations: {
    type: 'collection',
    key: 'operations',
  },
  settings: {
    type: 'collection',
    key: 'settings',
  },
  timesheets: {
    type: 'collection',
    key: 'timesheets',
  },
  webstore: {
    type: 'collection',
    key: 'webstore',
  },
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_destinationClientKey, us_setDestinationClientKey] = useState<string>('')
  const [us_doc10, us_setDoc10] = useState<string>('')
  const [us_doc2, us_setDoc2] = useState<string>('')
  const [us_doc4, us_setDoc4] = useState<string>('')
  const [us_doc6, us_setDoc6] = useState<string>('')
  const [us_doc8, us_setDoc8] = useState<string>('')
  const [us_originClientKey, us_setOriginClientKey] = useState<string>('')
  const [us_originDatabase, us_setOriginDatabase] = useState<string>('staging')
  const [us_sub1, us_setSub1] = useState<string>('')
  const [us_sub3, us_setSub3] = useState<string>('')
  const [us_sub5, us_setSub5] = useState<string>('')
  const [us_sub7, us_setSub7] = useState<string>('')
  const [us_sub9, us_setSub9] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_TEST_LAB
  }, [])

  useEffect(() => {
    // moveProjectFieldFromSubcollectionToMainDocument( "jmorrell1", "0", 500 )
    // moveProjectFieldFromSubcollectionToMainDocument( "etw_energy", "Z377841510", 500 )
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Variables

  // Functions

  // JSX Generation
  const rJSX_OriginDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginDatabase(event.target.value)
            }
          }}
          value={us_originDatabase}
        >
          <MenuItem value={'staging'}>{s_STAGING}</MenuItem>
          <MenuItem value={'prod'}>{s_PRODUCTION}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_OriginClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginClientKey(event.target.value)
            }
          }}
          value={us_originClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DestinationClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setDestinationClientKey(event.target.value)
            }
          }}
          value={us_destinationClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DropdownS1 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      us_setSub1(value)
      us_setDoc2('')
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (databaseStructureOptions != null) {
        dropdownOptions = databaseStructureOptions
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub1 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD2 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      us_setDoc2(value)
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '') {
      if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['dynamic_doc_keys'] === true) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc2 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['docs'] != null) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs']
        }
        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc2 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS3 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      us_setSub3(value)
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null
      ) {
        dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections']
        // } else if(
        // 	databaseStructureOptions != null &&
        // 	databaseStructureOptions[ us_sub1 ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null
        // ){
        // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub3 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD4 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      // us_setSub3( value )
      us_setDoc4(value)
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '' && us_sub3 !== '') {
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['dynamic_doc_keys'] === true
      ) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc4 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (
          databaseStructureOptions != null &&
          databaseStructureOptions[us_sub1] != null &&
          databaseStructureOptions[us_sub1]['docs'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs'] != null
        ) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs']
          // } else if(
          // 	databaseStructureOptions != null &&
          // 	databaseStructureOptions[ us_sub1 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ]["docs"] != null
          // ){
          // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
        }
        // D

        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc4 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS5 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD6 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS7 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD8 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS9 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD10 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  // const rJSX_ClientKeyDropdown = (): JSX.Element => {
  // let dropdownJSX =
  // <FormControl className="bp_thin_select_input bp_thin_select_multiple_input" sx={{ minWidth: "130px", marginRight: "12px", marginBottom: "8px" }}>
  // 	<Select
  // 		onChange={ ( event, value ) => {

  // 		} }
  // 		value={ conditionLine.variable_2 || "" }
  // 	>
  // 		{ objectToArray( calculationData.variables ).sort( dynamicSort( "name", null )).map(( option: TsInterface_UnspecifiedObject ) => (
  // 			<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["status"] === "inactive" }>
  // 				{ option["name"] }{ variableTrace2 }
  // 			</MenuItem>
  // 		)) }
  // 	</Select>
  // </FormControl>
  // return dropdownJSX
  // }

  const rJSX_DatabaseManagementTab = (): JSX.Element => {
    return (
      <Box>
        <Card className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {s_DATABASE_MANAGEMENT_UTILITY}
          </Typography>
          <Typography
            variant="body1"
            className="tw-opacity-40 tw-mt-4"
          ></Typography>
          <Divider className="tw-my-2" />
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy From')}:
            </Typography>
            {rJSX_OriginDropdown()}
            {rJSX_OriginClientDropdown()}

            {rJSX_DropdownS1()}
            {rJSX_DropdownD2()}
            {rJSX_DropdownS3()}
            {rJSX_DropdownD4()}
            {rJSX_DropdownS5()}
            {rJSX_DropdownD6()}
            {rJSX_DropdownS7()}
            {rJSX_DropdownD8()}
            {rJSX_DropdownS9()}
            {rJSX_DropdownD10()}
          </Box>
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {s_COPY_TO}:
            </Typography>
            {rJSX_DestinationClientDropdown()}
          </Box>
        </Card>
        <Json
          data={{
            us_originDatabase: us_originDatabase,
            us_originClientKey: us_originClientKey,
            us_destinationClientKey: us_destinationClientKey,
            us_sub1: us_sub1,
            us_doc2: us_doc2,
            us_sub3: us_sub3,
            us_doc4: us_doc4,
            us_sub5: us_sub5,
            us_doc6: us_doc6,
            us_sub7: us_sub7,
            us_doc8: us_doc8,
            us_sub9: us_sub9,
            us_doc10: us_doc10,
          }}
        />
      </Box>
    )
  }

  const rJSX_EmailTab = (): JSX.Element => {
    return (
      <Box>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: generateHtmlForEmailFromTemplateObject(exampleHtmlEmailTemplateObject, exampleHtmlEmailDataObject) }}
        />
        <Button
          onClick={() => {
            cloudFunctionManageRequest('manageEmails', {
              function: 'sendSendgridHtmlEmail',
              to: ['john.morrell.if@gmail.com'],
              subject: 'Test HTML Email',
              html: generateHtmlForEmailFromTemplateObject(exampleHtmlEmailTemplateObject, exampleHtmlEmailDataObject),
              cc: [],
              bcc: [],
            })
              .then((res) => {
                console.log(res)
              })
              .catch((rej) => {
                console.error(rej)
              })
          }}
        >
          SEND EMAIL
        </Button>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_TEST_LAB}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'email',
                  tabHeader: 'HTML Email',
                  tabButtons: [],
                  tabContent: rJSX_EmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'database_management',
                  tabHeader: 'Database Management',
                  tabButtons: [],
                  tabContent: rJSX_DatabaseManagementTab(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SuperTestLabPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Test Lab', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		DB Origin (prod or staging)
		DB Origin path length
		DB Destination (only staging) - select client
	*/
