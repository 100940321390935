///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Stack, Tooltip, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesOpportunityDiscoverySession_Addresses_Collection,
  DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Collection,
  DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_Address_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { MapBasic, TsInterface_MapMarkers } from 'rfbp_core/components/map'
import { TableBasic } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { tableColumns_DiscoverySessionAddresses, us_tableSettings_DiscoverySessionAddresses } from './tables/discovery_session_addresses'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['HomePage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
  let icon: TsInterface_UnspecifiedObject = {}
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
        scale: 1,
        anchor: new google.maps.Point(20, 48),
      }
      break
    case 'circle':
      icon = {
        path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
        scale: 0.6,
        anchor: new google.maps.Point(14, 14),
      }
      break
    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }
  return icon
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_discoverySession, us_setDiscoverySession] = useState<TsInterface_UnspecifiedObject>({})
  const [us_discoverySessionAddresses, us_setDiscoverySessionAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_geocodingInProgress, us_setGeocodingInProgress] = useState<boolean>(false)
  const [us_googleSolarInProgress, us_setGoogleSolarInProgress] = useState<boolean>(false)
  const [us_mapPins, us_setMapPins] = useState<TsInterface_MapMarkers>({})
  const un_routerNavigation = useNavigate()
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunity Discovery', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDiscoverySession(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDiscoverySessionAddresses(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunityDiscoverySession_Addresses_Collection(res_GCK.clientKey, itemKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let mapPins: TsInterface_MapMarkers = {}
    let strokeMultiplier = 2
    let scaleMultiplier = 2.5
    for (let loopAddressKey in us_discoverySessionAddresses) {
      let loopAddress = us_discoverySessionAddresses[loopAddressKey]

      let loopAddressColor = themeVariables.gray_700
      switch (loopAddress.color) {
        case 'red':
          loopAddressColor = themeVariables.error_main
          break
        case 'yellow':
          loopAddressColor = themeVariables.warning_main
          break
        case 'green':
          loopAddressColor = themeVariables.success_dark
          break
        default:
          loopAddressColor = themeVariables.gray_700
      }

      if (loopAddress.location_latitude != null && loopAddress.location_longitude != null) {
        mapPins[loopAddressKey] = {
          label: '',
          icon: {
            path: returnMapIcons('circle')['path'],
            fillColor: loopAddressColor,
            fillOpacity: 1,
            strokeWeight: 0.5 * strokeMultiplier,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
            anchor: returnMapIcons('circle')['anchor'],
          },
          position: {
            lat: parseFloat(loopAddress.location_latitude),
            lng: parseFloat(loopAddress.location_longitude),
          },
        }
      }
    }
    us_setMapPins(mapPins)
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)

    // {
    //   pin: {
    //     label: '',
    //     position: {
    //       lat: parseFloat(analyticsSession.location_metadata.location_latitude),
    //       lng: parseFloat(analyticsSession.location_metadata.location_longitude),
    //     },
    //   },
    // }
  }, [us_discoverySessionAddresses])

  // Functions
  const geocodeAddressesWithoutCoordinates = () => {
    us_setGeocodingInProgress(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: Promise<any>[] = []
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopAddressKey in us_discoverySessionAddresses) {
          let loopAddress = us_discoverySessionAddresses[loopAddressKey]
          if (
            loopAddress != null &&
            loopAddress.key != null &&
            loopAddress.location_address != null &&
            loopAddress.location_city != null &&
            loopAddress.location_state != null &&
            loopAddress.location_zip != null &&
            (loopAddress.location_latitude == null ||
              loopAddress.location_longitude == null ||
              loopAddress.location_latitude == '' ||
              loopAddress.location_longitude == '' ||
              loopAddress.location_latitude == 0 ||
              loopAddress.location_longitude == 0 ||
              loopAddress.location_latitude == '0' ||
              loopAddress.location_longitude == '0')
          ) {
            let address = loopAddress.location_address + ' ' + loopAddress.location_city + ' ' + loopAddress.location_state + ' ' + loopAddress.location_zip
            let geocoder = new google.maps.Geocoder()
            promiseArray.push(
              geocoder.geocode({ address: address }, (results: any, status: any) => {
                // If there is a result
                if (status === 'OK') {
                  // Set Latitude and Longitude
                  let updateObject = {
                    location_latitude: results[0].geometry.location.lat(),
                    location_longitude: results[0].geometry.location.lng(),
                  }
                  if (updateObject.location_latitude != null && updateObject.location_longitude != null) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(res_GCK.clientKey, itemKey, loopAddressKey),
                      data: updateObject,
                    })
                  }
                } else {
                  console.error('Failed to calculate coordinates')
                }
              }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          DatabaseStagedBatchUpdate(updateArray)
            .then((res_DSBU) => {
              us_setGeocodingInProgress(false)
            })
            .catch((rej_DSBU) => {
              us_setGeocodingInProgress(false)
            })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const runGoogleSolarOnAllAddressesMissingData = () => {
    us_setGoogleSolarInProgress(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: Promise<any>[] = []
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopAddressKey in us_discoverySessionAddresses) {
          let loopAddress = us_discoverySessionAddresses[loopAddressKey]
          if (
            loopAddress != null &&
            loopAddress.key != null &&
            loopAddress.location_latitude != null &&
            loopAddress.location_longitude != null &&
            loopAddress.location_latitude != '' &&
            loopAddress.location_longitude != '' &&
            loopAddress.location_latitude != 0 &&
            loopAddress.location_longitude != 0 &&
            loopAddress.location_latitude != '0' &&
            loopAddress.location_longitude != '0' &&
            loopAddress.calculated_google_solar_data != true
          ) {
            promiseArray.push(
              cloudFunctionManageRequest('manageData', {
                function: 'run_google_solar_request_for_coordinates',
                latitude: loopAddress.location_latitude,
                longitude: loopAddress.location_longitude,
              })
                .then((res_CFMR) => {
                  console.log(res_CFMR)
                  let googleSolarUpdateObject = getProp(res_CFMR, 'data', {})
                  let addressUpdateObject = {
                    calculated_google_solar_data: true,
                  }
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(res_GCK.clientKey, itemKey, loopAddressKey as string),
                    data: addressUpdateObject,
                  })
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document(res_GCK.clientKey, itemKey, loopAddressKey as string),
                    data: googleSolarUpdateObject,
                  })
                })
                .catch((rej_CFMR) => {
                  console.error(rej_CFMR)
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          console.log(updateArray)
          DatabaseStagedBatchUpdate(updateArray)
            .then((res_DBU) => {
              recalculateMapColors()
              us_setGoogleSolarInProgress(false)
              ur_forceRerender()
            })
            .catch((rej_DBU) => {
              recalculateMapColors()
              us_setGoogleSolarInProgress(false)
              ur_forceRerender()
            })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const openMapConfigForm = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: getProp(us_discoverySession, 'color_config', {}),
          formInputs: {
            min_panel_count: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'min_panel_count',
              label: rLIB('Minimum panel count'),
              required: true,
            },
            green_cutoff: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'green_cutoff',
              label: rLIB('Green cutoff at specified panel count'),
              required: true,
            },
            yellow_cutoff: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'yellow_cutoff',
              label: rLIB('Yellow cutoff at specified panel count'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    color_config: {
                      min_panel_count: formSubmittedData['min_panel_count'],
                      green_cutoff: formSubmittedData['green_cutoff'],
                      yellow_cutoff: formSubmittedData['yellow_cutoff'],
                    },
                  }
                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DSMD) => {
                      recalculateMapColors()
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Edit Map Config'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const recalculateMapColors = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (
          us_discoverySession != null &&
          us_discoverySession['color_config'] != null &&
          us_discoverySession['color_config']['min_panel_count'] != null &&
          us_discoverySession['color_config']['green_cutoff'] != null &&
          us_discoverySession['color_config']['yellow_cutoff'] != null
        ) {
          DatabaseGetCollection(DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Collection(res_GCK.clientKey, itemKey))
            .then((res_DGC) => {
              // Loop through each address and determine color
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
              let addressesWithMatches: TsInterface_UnspecifiedObject = {}
              let matchProductionPanels: number | null = null
              let matchProductionKwh: number | null = null
              for (let loopAddressKey in res_DGC['data']) {
                let loopAddressData = res_DGC['data'][loopAddressKey]
                let color = 'gray'
                console.log(loopAddressData)
                if (loopAddressData != null && loopAddressData['solarPotential'] != null && loopAddressData['solarPotential']['solarPanelConfigs'] != null) {
                  // Loop through and find the config that matches the specified number of panels
                  for (let loopConfigIndex in loopAddressData['solarPotential']['solarPanelConfigs']) {
                    let loopConfig = loopAddressData['solarPotential']['solarPanelConfigs'][loopConfigIndex]
                    if (
                      loopConfig != null &&
                      loopConfig.panelsCount === us_discoverySession['color_config']['min_panel_count'] &&
                      loopConfig.yearlyEnergyDcKwh != null
                    ) {
                      matchProductionPanels = loopConfig.panelsCount
                      matchProductionKwh = loopConfig.yearlyEnergyDcKwh
                      if (loopConfig.yearlyEnergyDcKwh > us_discoverySession['color_config']['green_cutoff']) {
                        color = 'green'
                      } else if (loopConfig.yearlyEnergyDcKwh >= us_discoverySession['color_config']['yellow_cutoff']) {
                        color = 'yellow'
                      } else {
                        color = 'red'
                      }
                    }
                  }
                }
                addressesWithMatches[loopAddressKey] = color
                updateArray.push({
                  type: 'setMerge',
                  ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(res_GCK.clientKey, itemKey, loopAddressKey),
                  data: {
                    color: color,
                    yearly_production_kwh: matchProductionKwh,
                    yearly_production_panels: matchProductionPanels,
                  },
                })
              }
              // Loop through and update the colors to gray if not found in previous loop
              for (let loopAddressKey in us_discoverySessionAddresses) {
                if (addressesWithMatches[loopAddressKey] == null) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(res_GCK.clientKey, itemKey, loopAddressKey),
                    data: { color: 'gray' },
                  })
                }
              }
              DatabaseStagedBatchUpdate(updateArray)
                .then((res_DSBU) => {})
                .catch((rej_DSBU) => {
                  console.error('Batch Update Error:', rej_DSBU)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                })
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        }
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Orders')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_MapConfigButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="info"
          variant="contained"
          onClick={(event) => {
            openMapConfigForm()
          }}
          disableElevation
          startIcon={<Icon icon="gear" />}
          className="tw-mr-2"
        >
          {rLIB('Map Config')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Map Config')}
          placement="top"
        >
          <Button
            color="info"
            variant="contained"
            onClick={(event) => {
              openMapConfigForm()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="gear" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_RecalculateMapColorsButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          onClick={(event) => {
            recalculateMapColors()
          }}
          disableElevation
          startIcon={<Icon icon="palette" />}
          className="tw-mr-2"
        >
          {rLIB('Recalculate Colors')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Recalculate Colors')}
          placement="top"
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={(event) => {
              recalculateMapColors()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="palette" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_GeocodeAddressesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    let iconJSX = (
      <>
        {us_geocodingInProgress ? (
          <Icon
            icon="arrows-rotate"
            className="bp_spin"
          />
        ) : (
          <Icon icon="map-location" />
        )}
      </>
    )
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          disabled={us_geocodingInProgress}
          onClick={(event) => {
            geocodeAddressesWithoutCoordinates()
          }}
          disableElevation
          startIcon={iconJSX}
          className="tw-mr-2"
        >
          {rLIB('Geocode All Addresses')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Geocode All Addresses')}
          placement="top"
        >
          <Button
            color="secondary"
            variant="contained"
            disabled={us_geocodingInProgress}
            onClick={(event) => {
              geocodeAddressesWithoutCoordinates()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            {iconJSX}
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_RunSolarButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    let iconJSX = (
      <>
        {us_googleSolarInProgress ? (
          <Icon
            icon="arrows-rotate"
            className="bp_spin"
          />
        ) : (
          <Icon icon="solar-panel" />
        )}
      </>
    )
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          disabled={us_googleSolarInProgress}
          onClick={(event) => {
            runGoogleSolarOnAllAddressesMissingData()
          }}
          disableElevation
          startIcon={iconJSX}
          className="tw-mr-2"
        >
          {rLIB('Run Solar Analysis')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Run Solar Analysis')}
          placement="top"
        >
          <Button
            color="secondary"
            variant="contained"
            disabled={us_googleSolarInProgress}
            onClick={(event) => {
              runGoogleSolarOnAllAddressesMissingData()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            {iconJSX}
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_AddressesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (objectToArray(us_discoverySessionAddresses).length > 0) {
      tabJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={{ discoverySessionKey: itemKey }}
            tableColumns={tableColumns_DiscoverySessionAddresses}
            tableData={objectToArray(us_discoverySessionAddresses)}
            tableSettings={us_tableSettings_DiscoverySessionAddresses}
          />
        </Card>
      )
    } else {
      tabJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{rLIB('No Addresses Uploaded Yet')}</Typography>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <MapBasic
          mapSettings={{
            center_lat: 0,
            center_lng: 0,
            height: 'calc( 100vh - 200px )',
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: '100%',
            zoom: 12,
          }}
          mapMarkers={us_mapPins}
          ref={ur_mapRef}
        />
        <Box>
          <Stack
            direction={'row'}
            spacing={2}
            className="tw-mt-2"
          >
            <Typography>
              {rLIB('Min Panel Count')}: {getProp(getProp(us_discoverySession, 'color_config', {}), 'min_panel_count', 0)}
            </Typography>
            <Box>
              <Box
                className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
                style={{ backgroundColor: themeVariables.error_main, width: '18px', height: '18px', marginTop: '2px' }}
              />
              <Typography className="tw-inline-block tw-align-top">
                {'<'} {getProp(getProp(us_discoverySession, 'color_config', {}), 'yellow_cutoff', 0)} {rLIB('Annual kWh')}{' '}
                {rLIB('for', false) + ' ' + getProp(getProp(us_discoverySession, 'color_config', {}), 'min_panel_count', 0) + ' ' + rLIB('panels', false)}
              </Typography>
            </Box>
            <Box>
              <Box
                className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
                style={{ backgroundColor: themeVariables.warning_main, width: '18px', height: '18px', marginTop: '2px' }}
              />
              <Typography className="tw-inline-block tw-align-top">
                {getProp(getProp(us_discoverySession, 'color_config', {}), 'green_cutoff', 0)} -{' '}
                {getProp(getProp(us_discoverySession, 'color_config', {}), 'yellow_cutoff', 0)} {rLIB('Annual kWh')}{' '}
                {rLIB('for', false) + ' ' + getProp(getProp(us_discoverySession, 'color_config', {}), 'min_panel_count', 0) + ' ' + rLIB('panels', false)}
              </Typography>
            </Box>
            <Box>
              <Box
                className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
                style={{ backgroundColor: themeVariables.success_dark, width: '18px', height: '18px', marginTop: '2px' }}
              />
              <Typography className="tw-inline-block tw-align-top">
                {'>'} {getProp(getProp(us_discoverySession, 'color_config', {}), 'green_cutoff', 0)} {rLIB('Annual kWh')}{' '}
                {rLIB('for', false) + ' ' + getProp(getProp(us_discoverySession, 'color_config', {}), 'min_panel_count', 0) + ' ' + rLIB('panels', false)}
              </Typography>
            </Box>
            <Box>
              <Box
                className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
                style={{ backgroundColor: themeVariables.gray_700, width: '18px', height: '18px', marginTop: '2px' }}
              />
              <Typography className="tw-inline-block tw-align-top">{rLIB('Data not calculated')}</Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_ImportAddressesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Addresses')}
        importDialogHeader={rLIB('Confirm Addresses Import')}
        importMappingOptions={{
          location_address: {
            key: 'location_address',
            required: true,
            label: rLIB('Street Address'),
            automatch_properties: ['Street Address', 'Address', 'ADDRESS'],
          },
          location_city: {
            key: 'location_city',
            required: true,
            label: rLIB('City'),
            automatch_properties: ['City', 'CITY'],
          },
          location_state: {
            key: 'location_state',
            required: true,
            label: rLIB('State'),
            automatch_properties: ['State', 'STATE'],
          },
          location_zip: {
            key: 'location_zip',
            required: true,
            label: rLIB('Zip'),
            automatch_properties: ['Zip', 'ZIP'],
          },
          location_latitude: {
            key: 'location_latitude',
            required: false,
            label: rLIB('Latitude'),
            automatch_properties: ['Latitude', 'Lat', 'LATITUDE', 'LAT'],
          },
          location_longitude: {
            key: 'location_longitude',
            required: false,
            label: rLIB('Longitude'),
            automatch_properties: ['Longitude', 'Lng', 'LONGITUDE', 'LNG'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                console.log(spreadsheetData)
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                for (let loopAddressIndex in spreadsheetData) {
                  let loopAddressKey = uuidv4()
                  let loopAddress = spreadsheetData[loopAddressIndex]
                  loopAddress['key'] = loopAddressKey
                  updateArray.push({
                    type: 'setOverwrite',
                    ref: DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(res_GCK.clientKey, itemKey, loopAddressKey),
                    data: loopAddress,
                  })
                }
                DatabaseStagedBatchUpdate(updateArray)
                  .then((res_DSBU) => {
                    resolve(res_DSBU)
                  })
                  .catch((rej_DSBU) => {
                    reject(rej_DSBU)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunity Discovery')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Opportunity Discovery', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Addresses'),
                  tabUrlKey: 'Addresses',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportAddressesButton(false), minJSX: rJSX_ImportAddressesButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_GeocodeAddressesButton(false), minJSX: rJSX_GeocodeAddressesButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RunSolarButton(false), minJSX: rJSX_RunSolarButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_AddressesTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Map'),
                  tabUrlKey: 'Map',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_MapConfigButton(false), minJSX: rJSX_MapConfigButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RecalculateMapColorsButton(false), minJSX: rJSX_RecalculateMapColorsButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_MapTab()}</Box>,
                  tabOnChange: () => {
                    setTimeout(() => {
                      // @ts-expect-error - TODO: reason for error
                      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
                        // @ts-expect-error - TODO: reason for error
                        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
                          // @ts-expect-error - TODO: reason for error
                          ur_mapRef.current.recalculateMapBounds(true)
                        }
                      }
                    }, 0)
                  },
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
