import { Box } from '@mui/material'

interface ImageProps {
  src: string
  alt: string
  width?: string | number
  height?: string | number
}

const Image: React.FC<ImageProps> = ({ src, alt, width = '100%', height = 'auto' }: ImageProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', width: '100%' }}>
      <img
        src={src}
        alt={alt}
        style={{ width: width, height: height, objectFit: 'cover' }}
      />
    </Box>
  )
}

export default Image
