/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
      - Phone number to get in
      - Saving local storage "auth"
      - Create Analytics Session
      - Save Data to Analytics Session

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Slider,
  TextField,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import rJSX_Banner from '../components/banner'
import { formatNumberToDollar } from '../components/helper'
import Image from '../components/image'
import {
  TsInterface_LeadData,
  TsInterface_LightreachRequestParams,
  TsInterface_LSEDetails,
  TsInterface_ProspectData_Response,
  TsInterface_UnauthenticatedShownSections,
} from '../interfaces/i_sales_tools'
import * as utilityCompanyJson from '../us_utility_company_info.json'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////
const EPC = 2.5
const PANEL_WATT_CAPACITY = 405 // note that this matches the value used to query eagleview on the server side
const FEDERAL_INCENTIVE_RATE = 0.3
const sectionMap: TsInterface_UnspecifiedObject = {
  ['System Size']: 'system_size',
  ['Battery']: 'battery',
  ['Roof Replacement']: 'roof_replacement',
  ['Electrical Upgrades']: 'electrical_upgrades',
  ['Remove Trees']: 'remove_trees',
  ['Ground Mount']: 'ground_mount',
}

const illinoisUtilityCompanies = {
  'Ameren Illinois': 'A',
  'Commonwealth Edison Co': 'B',
  'Tri-County Electric Coop, Inc': 'A',
  'Village of Chatham': 'A',
  'City Water Light & Power': 'A',
  'Rural Electric Conven Coop': 'A',
  'City of Highland': 'A',
  'Menard Electric Coop': 'A',
  'Wayne-White Counties Elec Coop': 'A',
  'Corn Belt Energy Corporation': 'A',
  'Southwestern Electric Coop Inc': 'A',
  'Southern Illinois Elec Coop': 'A',
  'Rock Energy Cooperative': 'B',
  'Shelby Electric Coop, Inc': 'A',
  'City of Batavia': 'B',
}

const utilityCompanyMap = {
  IL: illinoisUtilityCompanies,
}

///////////////////////////////
// Functions
///////////////////////////////

// const saveSessionData = (
//   clientKey: string,
//   opportunityKey: string,
//   analyticsSessionKey: string,
//   sessionData: TsInterface_UnspecifiedObject,
//   saveAnalyticsSessionData: boolean,
// ) => {
//   return new Promise((resolve, reject) => {
//     if (clientKey != null && opportunityKey != null && analyticsSessionKey != null && saveAnalyticsSessionData === true) {
//       cloudFunctionUnauthenticatedRequests({
//         function: 'saveSalesOpportunitySessionData',
//         client_key: clientKey,
//         opportunity_key: opportunityKey,
//         session_key: analyticsSessionKey,
//         session_data: sessionData,
//       })
//         .then((res_CFUR) => {
//           resolve(res_CFUR)
//         })
//         .catch((rej_CFUR) => {
//           reject(rej_CFUR)
//         })
//     } else {
//       resolve({ success: false })
//     }
//   })
// }

const returnStandardizedUSPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-numeric characters from the input
  const numericOnly = phoneNumber.replace(/\D/g, '')
  // Check if the number is 10 digits long (without country code)
  // or 11 digits long (with country code)
  if (numericOnly.length === 10) {
    // If it's 10 digits, assume it's a US number without the country code
    return `${numericOnly}`
  } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
    // If it's 11 digits and starts with '1', remove the '1' and assume it's a US number with the country code
    return `${numericOnly.substring(1)}`
  } else {
    // If the number does not conform to expected formats, return an error message
    return 'Invalid phone number'
  }
}

///////////////////////////////
// Container
///////////////////////////////
export const Container: React.FC = (): JSX.Element => {
  // Hooks
  const params = useParams()
  const clientKey: string = 'etw_energy'
  const opportunityKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // const [us_analyticsSessionKey] = useState<string>(uuidv4())
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_confirmedPhoneNumber, us_setConfirmedPhoneNumber] = useState<boolean>(false)
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')
  // const [us_initialPageLoadTime] = useState<Date>(new Date())
  // const [us_initialSessionSaved, us_setInitialSessionSaved] = useState<boolean>(false)
  const [us_userPhoneNumber, us_setUserPhoneNumber] = useState<string>('')
  const [us_leadData, us_setLeadData] = useState<TsInterface_LeadData | undefined>(undefined)
  const [us_utilityCompanies, us_setUtilityCompanies] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_customerInfoOpen, us_setCustomerInfoOpen] = useState(false)
  const [us_adjustablesOpen, us_setAdjustablesOpen] = useState(false)
  // const [us_addersOpen, us_setAddersOpen] = useState(false)
  const [us_systemSizeSliderData, us_setSystemSizeSliderData] = useState<TsInterface_ProspectData_Response[] | []>([])
  const [us_systemRegressiveSliderData, us_setSystemRegressiveSliderData] = useState<TsInterface_UnspecifiedObject[] | []>([])
  const [us_selectedSystemSize, us_setSelectedSystemSize] = useState<number>(0)
  const [us_selectedSystemAnnualProduction, us_setSelectedSystemAnnualProduction] = useState<number>(0)
  const [us_possibleSystemSizes, us_setPossibleSystemSizes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_customerName, us_setCustomerName] = useState<string>('')
  const [us_customerEmail, us_setCustomerEmail] = useState<string>('')
  const [us_customerPhone, us_setCustomerPhone] = useState<string>('')
  const [us_electricityBill, us_setElectricityBill] = useState<string>('')
  const [us_numPanels, us_setNumPanels] = useState(0)
  // const [us_numGroundMounts, us_setNumGroundMounts] = useState<number>(0)
  // const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_roofCost, us_setRoofCost] = useState<number>(0)
  const [us_batteryCost, us_setBatteryCost] = useState<number>(0)
  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('')
  const [us_roofSizeInFeet, us_setRoofSizeInFeet] = useState(0)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // const [us_adders, us_setAdders] = useState({
  //   batteries: false,
  //   ground_mount: false,
  //   roof_replacement: false,
  //   electrical_upgrades: false,
  //   remove_trees: false,
  // })
  const [us_shownSections, us_setShownSections] = useState<TsInterface_UnauthenticatedShownSections>({
    system_size: true,
    battery: true,
    roof_replacement: true,
    electrical_upgrades: true,
    remove_trees: true,
    ground_mount: true,
  })
  // const ul_queryParams = new URLSearchParams(useLocation().search)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Go Solar with ETW Energy', false) as string
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage('go_solar_' + opportunityKey)
    if (getProp(pageLocalStorageData, 'confirmed_phone_number', null) == true) {
      us_setConfirmedPhoneNumber(true)
    }
  }, [opportunityKey])

  useEffect(() => {
    // Check if the user has confirmed their phone number
    if (
      us_leadData != null &&
      us_leadData.opportunityData != null &&
      us_leadData.opportunityData.phone != null &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number' &&
      us_userPhoneNumber !== '' &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) === returnStandardizedUSPhoneNumber(us_userPhoneNumber) &&
      us_confirmedPhoneNumber !== true
    ) {
      us_setConfirmedPhoneNumber(true)
      setPageLocalStorage('go_solar_' + opportunityKey, 'confirmed_phone_number', true)
    }
  }, [opportunityKey, us_confirmedPhoneNumber, us_leadData, us_userPhoneNumber])

  // useEffect(() => {
  //   // Save Initial Analytics Session
  //   if (us_initialSessionSaved === false) {
  //     let sessionMetadata: TsInterface_UnspecifiedObject = {
  //       metadata_version: '1.0.0',
  //       cookies_enabled: getProp(navigator, 'cookieEnabled', null),
  //       device_memory: getProp(navigator, 'deviceMemory', null),
  //       language: getProp(navigator, 'language', null),
  //       platform: getProp(navigator, 'platform', null),
  //       referrer: document.referrer,
  //       screen_available_height: getProp(screen, 'availHeight', null),
  //       screen_available_width: getProp(screen, 'availWidth', null),
  //       screen_height: getProp(screen, 'height', null),
  //       screen_width: getProp(screen, 'width', null),
  //       timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       user_agent: getProp(navigator, 'userAgent', null),
  //     }
  //     // Get IP Address
  //     let promiseArray: Promise<unknown>[] = []
  //     promiseArray.push(
  //       fetch('https://api.ipify.org?format=json')
  //         .then((response) => response.json())
  //         .then((data) => {
  //           sessionMetadata['ip_address'] = getProp(data, 'ip', null)
  //         }),
  //     )
  //     // After IP Address Loaded
  //     Promise.all(promiseArray).finally(() => {
  //       saveSessionData(
  //         clientKey,
  //         opportunityKey,
  //         us_analyticsSessionKey,
  //         {
  //           key: us_analyticsSessionKey,
  //           associated_opportunity_key: opportunityKey,
  //           associated_client_key: clientKey,
  //           timestamp_session_started: us_initialPageLoadTime,
  //           initialPageLoadURL: window.location.href,
  //           metadata: sessionMetadata,
  //         },
  //         !(ul_queryParams.get('tracking') == 'false'),
  //       )
  //       us_setInitialSessionSaved(true)
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [opportunityKey, us_analyticsSessionKey, us_initialPageLoadTime, us_initialSessionSaved])

  // useEffect(() => {
  //   // Interval Update Session Length
  //   let currentLoop = 1
  //   setInterval(() => {
  //     let sessionLength = currentLoop * 5
  //     if (sessionLength <= 60) {
  //       // 1 minute
  //       // Save Data to Analytics Session
  //       saveSessionData(
  //         clientKey,
  //         opportunityKey,
  //         us_analyticsSessionKey,
  //         {
  //           timestamp_session_last_updated: new Date(),
  //           session_length_seconds: sessionLength,
  //         },
  //         !(ul_queryParams.get('tracking') == 'false'),
  //       )
  //     } else if (sessionLength <= 180) {
  //       // 3 minutes
  //       // Save Data to Analytics Session on 10 second intervals
  //       if (sessionLength % 10 === 0) {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             timestamp_session_last_updated: new Date(),
  //             session_length_seconds: sessionLength,
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }
  //     } else if (sessionLength <= 600) {
  //       // 10 minutes
  //       // Save Data to Analytics Session on 30 second intervals
  //       if (sessionLength % 30 === 0) {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             timestamp_session_last_updated: new Date(),
  //             session_length_seconds: sessionLength,
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }
  //     }
  //     currentLoop++
  //   }, 5000)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const reloadPageData = (clientKey: string, opportunityKey: string) => {
    cloudFunctionUnauthenticatedRequests({
      function: 'getSalesOpportunityData',
      client_key: clientKey,
      opportunity_key: opportunityKey,
    })
      .then((res_CFUR: any) => {
        // set the visible sections as determined by the admin view
        us_setShownSections(res_CFUR.data.opportunityData.shown_sections ? res_CFUR.data.opportunityData.shown_sections : { system_size: true })
        us_setSystemSizeSliderData(res_CFUR.data.eagleviewProspectData.ev_slider_data)
        us_setSystemRegressiveSliderData(getRegressiveSliderData(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data)))
        us_setPossibleSystemSizes(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
        us_setUtilityCompanyName(res_CFUR.data.opportunityData.contact_utility_provider)
        us_setElectricityBill(res_CFUR.data.opportunityData.contact_monthly_electric_bill ? res_CFUR.data.opportunityData.contact_monthly_electric_bill : 150)
        us_setCustomerName(res_CFUR.data.opportunityData.name)
        us_setCustomerEmail(res_CFUR.data.opportunityData.email ? res_CFUR.data.opportunityData.email : '')
        us_setCustomerPhone(res_CFUR.data.opportunityData.phone ? res_CFUR.data.opportunityData.phone : '')
        us_setCurrentCashCost(res_CFUR.data.opportunityData.current_cash_cost ? res_CFUR.data.opportunityData.current_cash_cost : 0)
        us_setBaseCashCost(res_CFUR.data.opportunityData.base_cash_cost ? res_CFUR.data.opportunityData.base_cash_cost : 0)
        us_setLeaseCost(res_CFUR.data.opportunityData.lease_cost ? res_CFUR.data.opportunityData.lease_cost : 0)

        // set the size slider to propagate changes from admin -> client and vice versa
        us_setNumPanels(res_CFUR.data.opportunityData.num_panels ? res_CFUR.data.opportunityData.num_panels : 0)
        us_setSelectedSystemSize(res_CFUR.data.opportunityData.selected_system_size ? res_CFUR.data.opportunityData.selected_system_size : 0)
        us_setSelectedSystemAnnualProduction(
          res_CFUR.data.opportunityData.selected_annual_production ? res_CFUR.data.opportunityData.selected_annual_production : 0,
        )

        const roofSizeInFeet = res_CFUR.data.googleSolarData.solarPotential.buildingStats.areaMeters2 * 10.7639
        us_setRoofSizeInFeet(roofSizeInFeet)

        us_setLeadData(res_CFUR.data) // TsInterface_LeadData
        us_setDataLoadedStatus('success')
      })
      .catch((rej_CFUR) => {
        us_setDataLoadedStatus('error')
      })
  }

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      reloadPageData(clientKey, opportunityKey)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(opportunityKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [opportunityKey])

  useEffect(() => {
    us_setDataLoadedStatus('loading')

    if (opportunityKey != null) {
      cloudFunctionUnauthenticatedRequests({
        function: 'getSalesOpportunityData',
        client_key: clientKey,
        opportunity_key: opportunityKey,
      })
        .then((res_CFUR: any) => {
          // set the visible sections as determined by the admin view
          us_setShownSections(res_CFUR.data.opportunityData.shown_sections ? res_CFUR.data.opportunityData.shown_sections : { system_size: true })
          us_setSystemSizeSliderData(res_CFUR.data.eagleviewProspectData.ev_slider_data)
          us_setSystemRegressiveSliderData(
            getRegressiveSliderData(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data)),
          )
          us_setPossibleSystemSizes(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
          us_setUtilityCompanyName(res_CFUR.data.opportunityData.contact_utility_provider)
          us_setElectricityBill(res_CFUR.data.opportunityData.contact_monthly_electric_bill ? res_CFUR.data.opportunityData.contact_monthly_electric_bill : 150)
          us_setCustomerName(res_CFUR.data.opportunityData.name)
          us_setCustomerEmail(res_CFUR.data.opportunityData.email ? res_CFUR.data.opportunityData.email : '')
          us_setCustomerPhone(res_CFUR.data.opportunityData.phone ? res_CFUR.data.opportunityData.phone : '')
          us_setCurrentCashCost(res_CFUR.data.opportunityData.current_cash_cost ? res_CFUR.data.opportunityData.current_cash_cost : 0)
          us_setBaseCashCost(res_CFUR.data.opportunityData.base_cash_cost ? res_CFUR.data.opportunityData.base_cash_cost : 0)
          us_setLeaseCost(res_CFUR.data.opportunityData.lease_cost ? res_CFUR.data.opportunityData.lease_cost : 0)

          // set the size slider to propagate changes from admin -> client and vice versa
          us_setNumPanels(res_CFUR.data.opportunityData.num_panels ? res_CFUR.data.opportunityData.num_panels : 0)
          us_setSelectedSystemSize(res_CFUR.data.opportunityData.selected_system_size ? res_CFUR.data.opportunityData.selected_system_size : 0)
          us_setSelectedSystemAnnualProduction(
            res_CFUR.data.opportunityData.selected_annual_production ? res_CFUR.data.opportunityData.selected_annual_production : 0,
          )

          const roofSizeInFeet = res_CFUR.data.googleSolarData.solarPotential.buildingStats.areaMeters2 * 10.7639
          us_setRoofSizeInFeet(roofSizeInFeet)

          // load the correct utility provider names in the autocomplete
          const jsonString = JSON.stringify(utilityCompanyJson)
          let jsonData: TsInterface_LSEDetails[] = JSON.parse(jsonString)
          const utilityCompanyList: TsInterface_UnspecifiedObject[] = Object.values(jsonData).map((company: TsInterface_LSEDetails) => {
            return { label: company.name, lseId: company.lseId, key: company.name }
          })

          us_setUtilityCompanies(utilityCompanyList)
          us_setLeadData(res_CFUR.data) // TsInterface_LeadData
          us_setDataLoadedStatus('success')
        })
        .catch((rej_CFUR) => {
          console.error(rej_CFUR)
          us_setDataLoadedStatus('error')
        })
    } else {
      us_setDataLoadedStatus('loading')
    }
  }, [clientKey, opportunityKey])

  const getRegressiveSliderData = (systemSizes: TsInterface_UnspecifiedObject) => {
    let systemSizesArray = Object.keys(systemSizes)
      .map((systemSize) => parseFloat(systemSize))
      .sort((a, b) => a - b)

    let annualProductionArray = Object.values(systemSizes)
      .map((systemSize) => systemSize.annual_production)
      .sort((a, b) => a - b)

    let currentSystemSize = 0
    let currentSystemProduction = 0

    let regressiveSliderData: TsInterface_UnspecifiedObject[] = []

    // use outer for-loop to calculate the needed production increment estimate per panel for the gap in production data
    for (let i = 1; i < annualProductionArray.length; i++) {
      const sizeDiff = systemSizesArray[i] - systemSizesArray[i - 1]
      const productionDiff = annualProductionArray[i] - annualProductionArray[i - 1]
      const intermediateNumPanels = Math.round((sizeDiff * 1000) / PANEL_WATT_CAPACITY)
      const productionIncrement = productionDiff / intermediateNumPanels

      // use inner for-loop to populate the data array that will be used for the slider
      for (let j = 0; j < intermediateNumPanels; j++) {
        regressiveSliderData.push({
          size: currentSystemSize,
          production: currentSystemProduction,
        })
        currentSystemProduction = currentSystemProduction + productionIncrement
        currentSystemSize = currentSystemSize + 405
      }
    }

    // add the max values to the end of the array
    regressiveSliderData.push({
      size: systemSizesArray[systemSizesArray.length - 1] * 1000,
      production: annualProductionArray[annualProductionArray.length - 1],
    })

    return regressiveSliderData
  }

  // Functions
  const handleToggleCustomerInfo = () => {
    us_setCustomerInfoOpen(!us_customerInfoOpen)
  }

  const handleToggleAdjustables = () => {
    us_setAdjustablesOpen(!us_adjustablesOpen)
  }

  // const handleToggleAdders = () => {
  //   us_setAddersOpen(!us_addersOpen)
  // }

  // const handleAdderChange = (event: any) => {
  //   us_setAdders({ ...us_adders, [event.target.name]: event.target.checked })
  //   const [cash, lease] = calculateCostsWithAdders({ ...us_adders, [event.target.name]: event.target.checked })
  //   us_setBaseCashCost(cash)
  //   us_setLeaseCost(lease)
  // }

  const calculateRoofCost = () => {
    const numSquares = Math.ceil(us_roofSizeInFeet / 100)
    const cost = numSquares * 650
    return cost
  }

  // const handleRoofReplacementChange = (event: any) => {
  //   us_setAdders({ ...us_adders, [event.target.name]: event.target.checked })
  //   const roofCost = event.target.checked ? calculateRoofCost() : 0
  //   us_setRoofCost(roofCost)
  //   updateOpportunity({ current_cash_cost: us_baseCashCost + roofCost + us_batteryCost })
  //   us_setCurrentCashCost(us_baseCashCost + roofCost + us_batteryCost)
  // }

  const handleSizeSliderChange = (event: any, newValue: number) => {
    let annualProd = us_systemRegressiveSliderData[newValue].production
    us_setSelectedSystemSize(us_systemRegressiveSliderData[newValue].size)
    us_setSelectedSystemAnnualProduction(annualProd)
    us_setNumPanels(newValue)

    updateOpportunity({
      num_panels: newValue,
      selected_system_size: us_systemRegressiveSliderData[newValue].size,
      selected_annual_production: annualProd,
    })

    getLightreachData(us_systemRegressiveSliderData[newValue].size, us_systemRegressiveSliderData[newValue].production)
      .then((res_GLD: any) => {
        us_setLeaseCost(res_GLD.data[2].monthlyPayments[0].monthlyPayment)
        us_setBaseCashCost(EPC * newValue * PANEL_WATT_CAPACITY)
        us_setCurrentCashCost(applyIncentives(EPC * newValue * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost))
        updateOpportunity({
          base_cash_cost: EPC * newValue * PANEL_WATT_CAPACITY,
          current_cash_cost: applyIncentives(EPC * newValue * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost),
          lease_cost: res_GLD.data[2].monthlyPayments[0].monthlyPayment,
        })
      })
      .catch((rej_GLD) => {
        rJSX_ErrorDisplay()
      })
  }

  // const handleBatterySliderChange = (event: any, newValue: number) => {
  //   let battery = false

  //   if (newValue == 0) {
  //     us_setAdders({ ...us_adders, batteries: false })
  //   } else {
  //     battery = true
  //     us_setAdders({ ...us_adders, batteries: true })
  //   }

  //   us_setBatteryCost(calculateBatteryAdder(newValue))
  //   us_setCurrentCashCost(us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue))
  //   updateOpportunity({ current_cash_cost: us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue) })
  // }

  const calculateBatteryAdder = (numBatteries: number) => {
    let adderSum = 0

    for (let i = 0; i < numBatteries; i++) {
      if (i == 0) {
        adderSum += 13200
      } else {
        adderSum += 9700
      }
    }

    return adderSum
  }

  const calculateGroundMountAdder = (numMounts: number) => {
    if (numMounts === 0) {
      return 0
    }

    if (numMounts <= 30) {
      return 9000
    }

    let adderSum = 9000

    for (let i = 30; i < numMounts; i += 10) {
      adderSum += 3000
    }

    return adderSum
  }

  const getSRECGroup = () => {
    if (us_leadData && us_leadData.opportunityData) {
      const state = us_leadData.opportunityData.state
      const relevantUtilityCompanies = getProp(utilityCompanyMap, state, null)
      if (relevantUtilityCompanies && relevantUtilityCompanies[us_utilityCompanyName]) {
        return relevantUtilityCompanies[us_utilityCompanyName as string]
      } else {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'Utility company not found.', code: '', details: '' } })
      }
    } else {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'No lead data available.', code: '', details: '' } })
    }
  }

  const getSRECSizeBracket = () => {
    if (us_selectedSystemSize <= 10000) {
      return '<=10'
    } else if (us_selectedSystemSize <= 25000) {
      return '10-25'
    } else {
      return '>25'
    }
  }

  const getSRECRate = (srecGroup: string, srecSizeBracket: string) => {
    if (srecGroup === 'A') {
      if (srecSizeBracket === '<=10') {
        return 75.21
      } else if (srecSizeBracket === '10-25') {
        return 64.71
      } else {
        return 59.28
      }
    } else {
      if (srecSizeBracket === '<=10') {
        return 83.75
      } else if (srecSizeBracket === '10-25') {
        return 76.27
      } else {
        return 69.47
      }
    }
  }

  const calculateSRECRate = () => {
    const srecGroup = getSRECGroup()
    const srecSizeBracket = getSRECSizeBracket()
    const srecRate = getSRECRate(srecGroup, srecSizeBracket)
    return srecRate
  }

  const applyIncentives = (cost: number, federalRate: number = FEDERAL_INCENTIVE_RATE): number => {
    const annualProduction = us_selectedSystemAnnualProduction
    const degradation = 0.05
    const fifteenYearProductionInMWh = Math.floor((annualProduction * ((1 - (1 - degradation) ** 15) / 0.005)) / 1000)
    const costAfterFederalIncentive = cost * (1 - federalRate)
    const costAfterSRECIncentive = costAfterFederalIncentive - calculateSRECRate() * fifteenYearProductionInMWh

    return costAfterSRECIncentive
  }

  const getPossibleSystemSizesAndProductionRatios = (systemSliderData: any) => {
    let possibleSystems: TsInterface_UnspecifiedObject = {}
    for (const sliderData of systemSliderData) {
      const systemSize = sliderData.data.dataOutput.roof['Max kW system']
      const pr = sliderData.production_ratio as number
      if (!possibleSystems[systemSize]) {
        possibleSystems[systemSize] = { production_ratio: sliderData.production_ratio, annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'] }
      } else {
        if (possibleSystems[systemSize] > pr) {
          possibleSystems[systemSize] = {
            production_ratio: sliderData.production_ratio,
            annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'],
          }
        }
      }
    }
    return possibleSystems
  }

  const calculateOffset = (initial: string, newValue: number) => {
    let monthlyBill
    if (typeof initial === 'string') {
      // Remove the dollar sign and parse the number
      monthlyBill = parseInt(initial.replace(/^\$/, ''))
    } else {
      monthlyBill = initial
    }
    return (newValue / ((monthlyBill * 12) / 0.15)) * 100
  }

  const getLightreachData = (systemSize: number, production: number) => {
    return new Promise((resolve, reject) => {
      if (clientKey && opportunityKey && us_leadData) {
        const lightreachParams: TsInterface_LightreachRequestParams = {
          utilityCompany: us_utilityCompanyName,
          state: us_leadData.opportunityData.state,
          systemSize: systemSize / 1000,
          production: production,
          batteryIncluded: false, // TODO: for now, going to calculate battery adders separately
          electricalUpgradeIncluded: false,
        }

        cloudFunctionUnauthenticatedRequests({
          function: 'getLightreachData',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          lightreachParams: lightreachParams,
        })
          .then((res_CFUR) => {
            resolve(res_CFUR)
          })
          .catch((rej_CFUR) => {
            reject(rej_CFUR)
          })
      } else {
        resolve({ success: false })
      }
    })
  }

  const handleAdminButtonClick = (mappedName: string) => {
    updateOpportunity({ shown_sections: { [mappedName]: !us_shownSections[mappedName] } })
    us_setShownSections({ ...us_shownSections, [mappedName]: !us_shownSections[mappedName] })
  }

  // updates the opportunity in the database
  const updateOpportunity = (updatedFields: TsInterface_UnspecifiedObject) => {
    if (us_leadData) {
      let updatedOpportunity = {}

      for (const entry of Object.entries(updatedFields)) {
        updatedOpportunity = { ...updatedOpportunity, [entry[0]]: entry[1] }
      }

      cloudFunctionUnauthenticatedRequests({
        function: 'updateOpportunity',
        client_key: clientKey,
        opportunity_key: opportunityKey,
        opportunity_data: { ...updatedOpportunity },
      })
    }
  }

  // JSX Generation
  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load property data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.white }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  const rJSX_AdminToggleButton = (parameterName: string) => {
    const mappedName = sectionMap[parameterName]
    const backgroundColor = us_shownSections[mappedName] ? themeVariables.error_main : themeVariables.success_light
    const toggleValue = us_shownSections[mappedName] ? 'Hide' : 'Show'
    return (
      <Button
        sx={{ backgroundColor: backgroundColor, color: '#ffffff' }}
        onClick={() => handleAdminButtonClick(mappedName)}
      >
        {toggleValue} {parameterName} for Customer View
      </Button>
    )
  }

  const rJSX_YardOpportunityView = (): JSX.Element => {
    const productionRatio =
      us_systemRegressiveSliderData[us_systemRegressiveSliderData.length - 1].production /
      us_systemRegressiveSliderData[us_systemRegressiveSliderData.length - 1].size
    let backgroundColor = ''
    let message = ''

    if (productionRatio > 1.25 && us_leadData) {
      message = `For ${(<Typography>us_leadData.opportunityData.address1</Typography>)}, solar is a GREAT choice!`
      backgroundColor = themeVariables.success_light
    } else if (productionRatio > 1 && us_leadData) {
      message = `For ${us_leadData.opportunityData.address1}, solar is a GOOD choice!`
      backgroundColor = themeVariables.success_light
    } else {
      message = 'Please contact ETW Energy for a custom quote...'
      backgroundColor = themeVariables.accent_light
    }

    return (
      <Grid2
        container
        sx={{ flexDirection: 'column' }}
      >
        <Grid2
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {rJSX_Banner(message, backgroundColor)}
        </Grid2>
        <Grid2
          xs={8}
          sm={8}
          md={6}
          lg={4}
          xl={4}
          sx={{ margin: 'auto' }}
        >
          {us_leadData && (
            <Image
              src={us_leadData?.eagleviewProspectData.ev_image_data ? `data:image/jpeg;base64,${us_leadData.eagleviewProspectData.ev_image_data}` : ''}
              height={'100%'}
              width={'100%'}
              alt=""
            />
          )}
        </Grid2>
        <Grid2
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ margin: 'auto' }}
        >
          <Card sx={{ margin: 2 }}>
            <CardContent>
              <Typography variant="h5">Cost Details</Typography>
              <Box
                display="flex"
                justifyContent="space-evenly"
              >
                <Typography variant="h4">Cash: {formatNumberToDollar(us_currentCashCost)}</Typography>
                <Typography variant="h4">Lease: {formatNumberToDollar(us_leaseCost)}/mo</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2>
          <Button
            sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' } }}
            onClick={handleToggleCustomerInfo}
          >
            <Typography>Toggle Homeowner Details</Typography>
          </Button>
        </Grid2>
        <Grid2
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Collapse in={us_customerInfoOpen}>
            <List>
              <ListItem>
                <ListItemText
                  secondary={
                    <Autocomplete
                      value={us_utilityCompanyName ? { label: us_utilityCompanyName } : {}}
                      onChange={(event, newValue) => {
                        // updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' })
                        us_setUtilityCompanyName(newValue ? newValue.label : '')
                      }}
                      options={us_utilityCompanies}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Utility Company"
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  // primary="Customer Name"
                  secondary={
                    <TextField
                      label="Customer Name"
                      variant="standard"
                      fullWidth
                      onChange={(event) => {
                        // updateOpportunity({ name: event.target.value })
                        us_setCustomerName(event.target.value)
                      }}
                      value={us_customerName}
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  // primary="Customer Email"
                  secondary={
                    <TextField
                      label="Email"
                      variant="standard"
                      fullWidth
                      value={us_customerEmail}
                      onChange={(event) => {
                        // updateOpportunity({ email: event.target.value })
                        us_setCustomerEmail(event.target.value)
                      }}
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  // primary="Phone"
                  secondary={
                    <TextField
                      label="Phone"
                      variant="standard"
                      fullWidth
                      value={returnStandardizedUSPhoneNumber(us_customerPhone)}
                      onChange={(event) => {
                        // updateOpportunity({ phone: event.target.value })
                        us_setCustomerPhone(event.target.value)
                      }}
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  // primary="Electricity Bill"
                  secondary={
                    <TextField
                      label="Electricity Bill"
                      variant="standard"
                      fullWidth
                      value={us_electricityBill}
                      onChange={(event) => {
                        // updateOpportunity({ contact_monthly_electric_bill: event.target.value })
                        us_setElectricityBill(event.target.value)
                      }}
                    />
                  }
                />
              </ListItem>
            </List>
            <Button
              sx={{ backgroundColor: themeVariables.success_main, color: themeVariables.white }}
              onClick={() => {
                updateOpportunity({
                  contact_utility_provider: us_utilityCompanyName,
                  name: us_customerName,
                  email: us_customerEmail,
                  phone: us_customerPhone,
                  contact_monthly_electric_bill: us_electricityBill,
                })
              }}
            >
              Submit Changes
            </Button>
          </Collapse>
          <Button
            onClick={handleToggleAdjustables}
            sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' } }}
          >
            <Typography>Toggle Adjustables</Typography>
          </Button>
          <Collapse in={us_adjustablesOpen}>
            <List>
              <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box>
                  {rJSX_AdminToggleButton('System Size')}
                  <Typography>Offset {calculateOffset(us_electricityBill, us_selectedSystemAnnualProduction)}%</Typography>
                  <Typography>Production {us_selectedSystemAnnualProduction} kWh/year</Typography>
                  <Typography>
                    Number of Panels: {us_numPanels} ({us_selectedSystemSize / 1000} kW system)
                  </Typography>
                </Box>
                <Slider
                  sx={{ height: 10, width: '100%', marginX: 'auto' }}
                  value={us_numPanels}
                  valueLabelDisplay="auto"
                  marks
                  step={1}
                  min={0}
                  max={us_systemRegressiveSliderData.length - 1}
                  onChangeCommitted={(event, value) => handleSizeSliderChange(event, value as number)}
                />
              </ListItem>
            </List>
          </Collapse>
          {/* <Button
            onClick={handleToggleAdders}
            sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' } }}
          >
            <Typography>Toggle Adders</Typography>
          </Button> */}
          {/* <Collapse in={us_addersOpen}>
            <List>
              <ListItem>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <ListItemText primary="Batteries" />
                  <Slider
                    sx={{ height: 10, width: '100%', marginX: 'auto' }}
                    defaultValue={us_numBatteries}
                    valueLabelDisplay="on"
                    marks
                    step={1}
                    min={0}
                    max={4}
                    onChangeCommitted={(event, value) => handleBatterySliderChange(event, value as number)}
                  />
                </Box>
              </ListItem>
              {/* <ListItem>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <ListItemText primary="Ground Mount" />
                  <Slider
                    sx={{ height: 10, width: '100%', marginX: 'auto' }}
                    defaultValue={us_numBatteries}
                    valueLabelDisplay="on"
                    marks
                    step={10}
                    min={0}
                    max={50}
                    onChangeCommitted={(event, value) => handleGroundMountSliderChange(event, value as number)}
                  />
                </Box>
              </ListItem> */}
          {/* <ListItem>
                <ListItemText primary="Roof Replacement" />
                <Checkbox
                  checked={us_adders.roof_replacement}
                  onChange={handleRoofReplacementChange}
                  name="roof_replacement"
                />
              </ListItem> */}
          {/* <ListItem>
                <ListItemText primary="Electrical Upgrades" />
                <Checkbox
                  checked={us_adders.electrical_upgrades}
                  onChange={handleAdderChange}
                  name="electrical_upgrades"
                />
              </ListItem> */}
          {/* <ListItem>
                <ListItemText primary="Remove Trees" />
                <Checkbox
                  checked={us_adders.remove_trees}
                  onChange={handleAdderChange}
                  name="remove_trees"
                />
              </ListItem> */}
          {/* Add more inputs as needed */}
          {/* </List>
          </Collapse> */}
        </Grid2>
      </Grid2>
    )
  }

  const rJSX_ConfirmPhoneNumber = (phone: string): JSX.Element => {
    let formattedPhoneNumber = returnStandardizedUSPhoneNumber(phone)
    let confirmPhoneNumberJSX = (
      <Box className="tw-p-4">
        <Typography
          variant="h5"
          className="tw-text-center"
        >
          {rLIB('Please confirm your phone number')}
        </Typography>
        <Typography
          variant="h4"
          className="tw-text-center tw-mt-2"
        >
          XXX-XXX-{formattedPhoneNumber.slice(-4)}
        </Typography>
        <Box className="tw-mt-8 tw-text-center">
          <FormControl
            className="bp_thin_text_input"
            sx={{ minWidth: '300px', marginRight: '12px', marginBottom: '8px' }}
          >
            <TextField
              sx={{ width: '300px' }}
              onChange={(event) => {
                us_setUserPhoneNumber(event.target.value)
              }}
              value={us_userPhoneNumber}
              variant="outlined"
              // label={rLIB('Phone Number')}
            />
          </FormControl>
        </Box>
      </Box>
    )
    return confirmPhoneNumberJSX
  }

  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center tw-mt-2">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      if (
        us_confirmedPhoneNumber !== true &&
        us_leadData != null &&
        us_leadData.opportunityData != null &&
        us_leadData.opportunityData.phone != null &&
        returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number'
      ) {
        contentJSX = rJSX_ConfirmPhoneNumber(us_leadData.opportunityData.phone)
      } else {
        contentJSX = rJSX_YardOpportunityView()
      }
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = <Box className="tw-p-4">{rJSX_ErrorDisplay()}</Box>
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <Box>{rJSX_PageContent()}</Box>
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}
