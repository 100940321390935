/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
      - Phone number to get in
      - Saving local storage "auth"
      - Create Analytics Session
      - Save Data to Analytics Session

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Fab,
  FormControl,
  LinearProgress,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic } from 'rfbp_core/components/map'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { formatNumberToDollar } from './components/helper'
import {
  TsInterface_LeadData,
  TsInterface_LightreachRequestParams,
  TsInterface_LSEDetails,
  TsInterface_ProspectData_Response,
  TsInterface_UnauthenticatedShownSections,
} from './interfaces/i_sales_tools'
import * as utilityCompanyJson from './us_utility_company_info.json'

// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
import { EagleviewProposePanelsVisualization, TsInterface_EagleviewVisualizationCallbackData } from './components/eagleview_propose_panels_visualization'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////
const EPC = 2.5
const PANEL_WATT_CAPACITY = 405 // note that this matches the value used to query eagleview on the server side
const FEDERAL_INCENTIVE_RATE = 0.3
const sectionMap: TsInterface_UnspecifiedObject = {
  ['System Size']: 'system_size',
  ['Battery']: 'battery',
  ['Roof Replacement']: 'roof_replacement',
  ['Electrical Upgrades']: 'electrical_upgrades',
  ['Remove Trees']: 'remove_trees',
  ['Ground Mount']: 'ground_mount',
}

const illinoisUtilityCompanies = {
  'Ameren Illinois': 'A',
  'Commonwealth Edison Co': 'B',
  'Tri-County Electric Coop, Inc': 'A',
  'Village of Chatham': 'A',
  'City Water Light & Power': 'A',
  'Rural Electric Conven Coop': 'A',
  'City of Highland': 'A',
  'Menard Electric Coop': 'A',
  'Wayne-White Counties Elec Coop': 'A',
  'Corn Belt Energy Corporation': 'A',
  'Southwestern Electric Coop Inc': 'A',
  'Southern Illinois Elec Coop': 'A',
  'Rock Energy Cooperative': 'B',
  'Shelby Electric Coop, Inc': 'A',
  'City of Batavia': 'B',
}

const utilityCompanyMap = {
  IL: illinoisUtilityCompanies,
}

const black = '#000000'
const white = '#ffffff'
const teal = '#1E83A1'
const lightTeal = '#33b3d9'
const darkTeal = '#166076'
const lightGray = '#F4F7F9'
const lightGray2 = '#dbe5eb'
const darkGray = '#9C9C9E'
const green = '#1ea13c'
const yellow = '#f7a830'

let pageCSS: string = `
		body {
			background: ${lightGray};
		}
	`

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: teal,
      light: lightTeal,
      dark: darkTeal,
      contrastText: white,
    },
  },
})

///////////////////////////////
// Functions
///////////////////////////////
const orderEagleViewProproseData = async (clientKey: string, opportunityKey: string, formSubmittedData: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    cloudFunctionUnauthenticatedRequests({
      function: 'generateNewOpportunityData',
      client_key: clientKey,
      opportunity_key: opportunityKey,
      formSubmittedData: formSubmittedData,
      generateFlags: {
        google: false,
        ev_prospect: false,
        ev_propose: true,
      },
    })
      .then((res_CFUR) => {
        resolve(res_CFUR)
      })
      .catch((rej_CFUR) => {
        reject(rej_CFUR)
      })
  })
}

const saveSessionData = (
  clientKey: string,
  opportunityKey: string,
  analyticsSessionKey: string,
  sessionData: TsInterface_UnspecifiedObject,
  saveAnalyticsSessionData: boolean,
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && opportunityKey != null && analyticsSessionKey != null && saveAnalyticsSessionData === true) {
      cloudFunctionUnauthenticatedRequests({
        function: 'saveSalesOpportunitySessionData',
        client_key: clientKey,
        opportunity_key: opportunityKey,
        session_key: analyticsSessionKey,
        session_data: sessionData,
      })
        .then((res_CFUR) => {
          resolve(res_CFUR)
        })
        .catch((rej_CFUR) => {
          reject(rej_CFUR)
        })
    } else {
      resolve({ success: false })
    }
  })
}

const returnStandardizedUSPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber != null) {
    // Remove all non-numeric characters from the input
    const numericOnly = phoneNumber.replace(/\D/g, '')
    // Check if the number is 10 digits long (without country code)
    // or 11 digits long (with country code)
    if (numericOnly.length === 10) {
      // If it's 10 digits, assume it's a US number without the country code
      return `${numericOnly}`
    } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
      // If it's 11 digits and starts with '1', remove the '1' and assume it's a US number with the country code
      return `${numericOnly.substring(1)}`
    } else {
      // If the number does not conform to expected formats, return an error message
      return 'Invalid phone number'
    }
  } else {
    return 'Invalid phone number'
  }
}

function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

const RotatingLoadingMessages = () => {
  const [us_currentMessageIndex, us_setCurrentMessageIndex] = useState<number>(0)
  const [us_messagesArray, us_setMessagesArray] = useState<string[]>([])

  const secondsBetweenMessages = 5

  useEffect(() => {
    // Shuffle Messages
    const messages = [
      'Harness the power of the sun!',
      'Save on your electricity bills',
      'Reduce your carbon footprint',
      'Go green with solar energy',
      'Customize your own solar solution',
      'Solar panels for a sustainable future',
      'Energy independence is just a step away',
      'Invest in renewable energy',
      'Solar power: Clean, green, and efficient',
      'Discover the benefits of solar energy',
      'The future is solar-powered',
      'Start saving with solar today',
      'A bright idea: Solar energy!',
      'Solar panels: The eco-friendly choice',
      'Design your dream solar system',
      'Solar energy: Reliable and renewable',
      'Enjoy the freedom of solar power',
      'Solar panels pay for themselves',
      'Experience the power of the sun',
      'Join the solar revolution!',
    ]
    us_setMessagesArray(shuffleArray(messages))
    return () => {}
  }, [])

  useEffect(() => {
    // Interval to rotate messages
    const intervalId = setInterval(() => {
      us_setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % us_messagesArray.length)
    }, secondsBetweenMessages * 1000)
    // Clear Interval
    return () => {
      clearInterval(intervalId)
    }
  }, [us_messagesArray])

  return <Box>{us_messagesArray[us_currentMessageIndex]}</Box>
}

///////////////////////////////
// Container
///////////////////////////////
export const Container: React.FC = (): JSX.Element => {
  // Hooks
  const params = useParams()
  const clientKey: string = 'etw_energy'
  const opportunityKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_analyticsSessionKey] = useState<string>(uuidv4())
  const [us_propertyCoordinates, us_setPropertyCoordinates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_savingInitialActivePanels, us_setSavingInitialActivePanels] = useState<boolean>(false)
  const [us_activeProposePanels, us_setActiveProposePanels] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_updatingPricingNumbers, us_setUpdatingPricingNumbers] = useState<boolean>(false)
  const [us_leasePricingError, us_setLeasePricingError] = useState<boolean>(false)
  const [us_customerNameChanged, us_setCustomerNameChanged] = useState<boolean>(false)
  const [us_customerEmailChanged, us_setCustomerEmailChanged] = useState<boolean>(false)
  const [us_customerPhoneChanged, us_setCustomerPhoneChanged] = useState<boolean>(false)
  const [us_electricityBillChanged, us_setElectricityBillChanged] = useState<boolean>(false)
  const [us_confirmedPhoneNumber, us_setConfirmedPhoneNumber] = useState<boolean>(false)
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')
  const [us_initialPageLoadTime] = useState<Date>(new Date())
  const [us_initialSessionSaved, us_setInitialSessionSaved] = useState<boolean>(false)
  const [us_userPhoneNumber, us_setUserPhoneNumber] = useState<string>('')
  const [us_leadData, us_setLeadData] = useState<TsInterface_LeadData | undefined>(undefined)
  const [us_utilityCompanies, us_setUtilityCompanies] = useState<TsInterface_UnspecifiedObject[]>([])
  // const [us_customerInfoOpen, us_setCustomerInfoOpen] = useState(false)
  // const [us_adjustablesOpen, us_setAdjustablesOpen] = useState(false)
  // const [us_addersOpen, us_setAddersOpen] = useState(false)
  const [us_systemSizeSliderData, us_setSystemSizeSliderData] = useState<TsInterface_ProspectData_Response[] | []>([])
  const [us_systemRegressionSliderData, us_setSystemRegressionSliderData] = useState<TsInterface_UnspecifiedObject[] | []>([])
  const [us_selectedSystemSize, us_setSelectedSystemSize] = useState<number>(0)
  const [us_selectedSystemAnnualProduction, us_setSelectedSystemAnnualProduction] = useState<number>(0)
  const [us_possibleSystemSizes, us_setPossibleSystemSizes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_customerName, us_setCustomerName] = useState<string>('')
  const [us_customerEmail, us_setCustomerEmail] = useState<string>('')
  const [us_customerPhone, us_setCustomerPhone] = useState<string>('')
  const [us_electricityBill, us_setElectricityBill] = useState<string>('')
  const [us_numPanels, us_setNumPanels] = useState(0)
  const [us_numGroundMounts, us_setNumGroundMounts] = useState<number>(0)
  const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_roofCost, us_setRoofCost] = useState<number>(0)
  const [us_batteryCost, us_setBatteryCost] = useState<number>(0)
  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('')
  const [us_roofSizeInFeet, us_setRoofSizeInFeet] = useState(0)
  const [us_leasePricingErrorCount, us_setLeasePricingErrorCount] = useState(0)
  const [us_adders, us_setAdders] = useState({
    batteries: false,
    ground_mount: false,
    roof_replacement: false,
    electrical_upgrades: false,
    remove_trees: false,
  })
  const [us_shownSections, us_setShownSections] = useState<TsInterface_UnauthenticatedShownSections>({
    system_size: true,
    battery: true,
    roof_replacement: true,
    electrical_upgrades: true,
    remove_trees: true,
    ground_mount: true,
  })
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // Hooks - useCallback
  const loadPageData = useCallback((clientKey: string, opportunityKey: string, initialRequest: boolean) => {
    cloudFunctionUnauthenticatedRequests({
      function: 'getSalesOpportunityData',
      client_key: clientKey,
      opportunity_key: opportunityKey,
    })
      .then((res_CFUR: any) => {
        if (res_CFUR != null && res_CFUR.data != null) {
          // Load Prospect Data if it's missing
          if (!res_CFUR.data.eagleviewProspectData || Object.keys(res_CFUR.data.eagleviewProspectData).length === 0) {
            cloudFunctionUnauthenticatedRequests({
              function: 'generateNewOpportunityData',
              client_key: clientKey,
              opportunity_key: opportunityKey,
              formSubmittedData: res_CFUR.data.opportunityData,
              generateFlags: {
                google: false,
                ev_prospect: true,
                ev_propose: false,
              },
            })
          }
          // Eagleview Data
          if (res_CFUR.data.eagleviewProspectData != null && res_CFUR.data.eagleviewProspectData.ev_slider_data != null) {
            us_setSystemSizeSliderData(res_CFUR.data.eagleviewProspectData.ev_slider_data)
            us_setSystemRegressionSliderData(
              getRegressionSliderData(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data)),
            )
            us_setPossibleSystemSizes(getPossibleSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
          }
          // Opportunity Data
          if (res_CFUR.data.opportunityData != null) {
            us_setShownSections(res_CFUR.data.opportunityData.shown_sections)
            us_setUtilityCompanyName(res_CFUR.data.opportunityData.contact_utility_provider)
            us_setElectricityBill(res_CFUR.data.opportunityData.contact_monthly_electric_bill)
            us_setCustomerName(res_CFUR.data.opportunityData.name)
            us_setCustomerEmail(res_CFUR.data.opportunityData.email)
            us_setCustomerPhone(res_CFUR.data.opportunityData.phone)
            us_setCurrentCashCost(res_CFUR.data.opportunityData.current_cash_cost ? res_CFUR.data.opportunityData.current_cash_cost : 0)
            us_setBaseCashCost(res_CFUR.data.opportunityData.base_cash_cost ? res_CFUR.data.opportunityData.base_cash_cost : 0)
            us_setLeaseCost(res_CFUR.data.opportunityData.lease_cost ? res_CFUR.data.opportunityData.lease_cost : 0)
            us_setNumPanels(res_CFUR.data.opportunityData.num_panels ? res_CFUR.data.opportunityData.num_panels : 0)
            us_setSelectedSystemSize(res_CFUR.data.opportunityData.selected_system_size ? res_CFUR.data.opportunityData.selected_system_size : 0)
            us_setSelectedSystemAnnualProduction(
              res_CFUR.data.opportunityData.selected_annual_production ? res_CFUR.data.opportunityData.selected_annual_production : 0,
            )
          }
          // Google Solar Data
          // TODO: This may not work with all addresses
          if (res_CFUR.data.googleSolarData != null && res_CFUR.data.googleSolarData.solarPotential != null) {
            const roofSizeInFeet = res_CFUR.data.googleSolarData.solarPotential.buildingStats.areaMeters2 * 10.7639
            us_setRoofSizeInFeet(roofSizeInFeet)
            if (
              res_CFUR.data.googleSolarData.center != null &&
              res_CFUR.data.googleSolarData.center.latitude != null &&
              res_CFUR.data.googleSolarData.center.longitude != null
            ) {
              us_setPropertyCoordinates(res_CFUR.data.googleSolarData.center)
            }
          }
          us_setLeadData(res_CFUR.data) // TsInterface_LeadData
        }
        us_setDataLoadedStatus('success')
      })
      .catch((rej_CFUR) => {
        console.error(rej_CFUR)
        us_setDataLoadedStatus('error')
      })
  }, [])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Go Solar with ETW Energy', false) as string
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage('go_solar_' + opportunityKey)
    if (getProp(pageLocalStorageData, 'confirmed_phone_number', null) == true) {
      us_setConfirmedPhoneNumber(true)
    }
  }, [opportunityKey])

  const requestProposeDataset = () => {
    if (us_leadData) {
      us_setDataLoadedStatus('loading_eagleview_propose_data')
      orderEagleViewProproseData(clientKey, opportunityKey, getProp(us_leadData, 'opportunityData', {}))
        .then((res_OEVPD) => {
          // Nothing
        })
        .catch((rej_OEVPD) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: 'There was an error getting the customization dataset.',
              code: '',
              details: '',
            },
          })
        })
    } else {
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: {
          message: 'No lead data found.',
          code: '',
          details: '',
        },
      })
    }
  }

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      loadPageData(clientKey, opportunityKey, true)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(opportunityKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [opportunityKey, loadPageData, ur_forceRerender])

  useEffect(() => {
    // Check if the user has confirmed their phone number
    if (
      us_leadData != null &&
      us_leadData.opportunityData != null &&
      us_leadData.opportunityData.phone != null &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number' &&
      us_userPhoneNumber !== '' &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) === returnStandardizedUSPhoneNumber(us_userPhoneNumber) &&
      us_confirmedPhoneNumber !== true
    ) {
      us_setConfirmedPhoneNumber(true)
      setPageLocalStorage('go_solar_' + opportunityKey, 'confirmed_phone_number', true)
      saveSessionData(
        clientKey,
        opportunityKey,
        us_analyticsSessionKey,
        {
          actions_taken: {
            clicked_test_button: true,
          },
        },
        !(ul_queryParams.get('tracking') == 'false'),
      )
    }
  }, [opportunityKey, ul_queryParams, us_analyticsSessionKey, us_confirmedPhoneNumber, us_leadData, us_userPhoneNumber])

  useEffect(() => {
    // Save Initial Analytics Session
    if (us_initialSessionSaved === false) {
      let sessionMetadata: TsInterface_UnspecifiedObject = {
        metadata_version: '1.0.0',
        cookies_enabled: getProp(navigator, 'cookieEnabled', null),
        device_memory: getProp(navigator, 'deviceMemory', null),
        language: getProp(navigator, 'language', null),
        platform: getProp(navigator, 'platform', null),
        referrer: document.referrer,
        screen_available_height: getProp(screen, 'availHeight', null),
        screen_available_width: getProp(screen, 'availWidth', null),
        screen_height: getProp(screen, 'height', null),
        screen_width: getProp(screen, 'width', null),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_agent: getProp(navigator, 'userAgent', null),
      }
      // Get IP Address
      let promiseArray: Promise<unknown>[] = []
      promiseArray.push(
        fetch('https://api.ipify.org?format=json')
          .then((response) => response.json())
          .then((data) => {
            sessionMetadata['ip_address'] = getProp(data, 'ip', null)
          }),
      )
      // After IP Address Loaded
      Promise.all(promiseArray).finally(() => {
        saveSessionData(
          clientKey,
          opportunityKey,
          us_analyticsSessionKey,
          {
            key: us_analyticsSessionKey,
            associated_opportunity_key: opportunityKey,
            associated_client_key: clientKey,
            timestamp_session_started: us_initialPageLoadTime,
            initialPageLoadURL: window.location.href,
            metadata: sessionMetadata,
          },
          !(ul_queryParams.get('tracking') == 'false'),
        )
        us_setInitialSessionSaved(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityKey, us_analyticsSessionKey, us_initialPageLoadTime, us_initialSessionSaved])

  useEffect(() => {
    // Interval Update Session Length
    let currentLoop = 1
    setInterval(() => {
      let sessionLength = currentLoop * 5
      if (sessionLength <= 60) {
        // 1 minute
        // Save Data to Analytics Session
        saveSessionData(
          clientKey,
          opportunityKey,
          us_analyticsSessionKey,
          {
            timestamp_session_last_updated: new Date(),
            session_length_seconds: sessionLength,
          },
          !(ul_queryParams.get('tracking') == 'false'),
        )
      } else if (sessionLength <= 180) {
        // 3 minutes
        // Save Data to Analytics Session on 10 second intervals
        if (sessionLength % 10 === 0) {
          saveSessionData(
            clientKey,
            opportunityKey,
            us_analyticsSessionKey,
            {
              timestamp_session_last_updated: new Date(),
              session_length_seconds: sessionLength,
            },
            !(ul_queryParams.get('tracking') == 'false'),
          )
        }
      } else if (sessionLength <= 600) {
        // 10 minutes
        // Save Data to Analytics Session on 30 second intervals
        if (sessionLength % 30 === 0) {
          saveSessionData(
            clientKey,
            opportunityKey,
            us_analyticsSessionKey,
            {
              timestamp_session_last_updated: new Date(),
              session_length_seconds: sessionLength,
            },
            !(ul_queryParams.get('tracking') == 'false'),
          )
        }
      }
      currentLoop++
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // load the correct utility provider names in the autocomplete
    const jsonString = JSON.stringify(utilityCompanyJson)
    let jsonData: TsInterface_LSEDetails[] = JSON.parse(jsonString)
    let utilityCompanyListObject: TsInterface_UnspecifiedObject = {}
    for (let loopCompanyKey in jsonData) {
      let loopCompany = jsonData[loopCompanyKey]
      if (loopCompany != null && loopCompany.name != null && loopCompany.lseId != null) {
        utilityCompanyListObject[loopCompany.name] = {
          label: loopCompany.name,
          lseId: loopCompany.lseId,
          key: loopCompany.name,
        }
      }
    }
    us_setUtilityCompanies(objectToArray(utilityCompanyListObject).sort(dynamicSort('label', 'asc')))
  }, [])

  useEffect(() => {
    if (
      us_selectedSystemAnnualProduction > 0 &&
      us_savingInitialActivePanels === false &&
      us_dataLoadedStatus === 'success' &&
      us_leadData != null &&
      us_leadData.eagleviewProposeData != null &&
      objectToArray(us_leadData.eagleviewProposeData).length > 0 &&
      us_leadData.opportunityData != null &&
      us_leadData.opportunityData['active_propose_panels'] == null
    ) {
      us_setSavingInitialActivePanels(true)
      // Set Initial Panels - One Time Only based on System Size
      let activePanelSelection: TsInterface_UnspecifiedObject = {}
      let systemSize = us_selectedSystemAnnualProduction
      let passedSystemSize = false
      for (let loopPanelIndex in getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable']) {
        let loopPanel = getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable'][loopPanelIndex]
        if (loopPanel != null) {
          activePanelSelection[loopPanelIndex] = true
          if (passedSystemSize === false) {
            activePanelSelection[loopPanelIndex] = true
          } else {
            activePanelSelection[loopPanelIndex] = false
          }
          if (loopPanel['kWh/yr(Cumulative)'] >= systemSize) {
            passedSystemSize = true
          }
        }
      }
      updateOpportunity({ active_propose_panels: activePanelSelection })
      us_setActiveProposePanels(activePanelSelection)
      ur_forceRerender()
    } else if (us_leadData != null && us_leadData.opportunityData != null && us_leadData.opportunityData['active_propose_panels'] != null) {
      us_setActiveProposePanels(us_leadData.opportunityData['active_propose_panels'])
      ur_forceRerender()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_dataLoadedStatus, us_leadData, us_savingInitialActivePanels, ur_forceRerender, us_selectedSystemAnnualProduction])

  const getRegressionSliderData = (systemSizes: TsInterface_UnspecifiedObject) => {
    let systemSizesArray = Object.keys(systemSizes)
      .map((systemSize) => parseFloat(systemSize))
      .sort((a, b) => a - b)

    let annualProductionArray = Object.values(systemSizes)
      .map((systemSize) => systemSize.annual_production)
      .sort((a, b) => a - b)

    let currentSystemSize = 0
    let currentSystemProduction = 0

    let regressionSliderData: TsInterface_UnspecifiedObject[] = []

    // use outer for-loop to calculate the needed production increment estimate per panel for the gap in production data
    for (let i = 1; i < annualProductionArray.length; i++) {
      const sizeDiff = systemSizesArray[i] - systemSizesArray[i - 1]
      const productionDiff = annualProductionArray[i] - annualProductionArray[i - 1]
      const intermediateNumPanels = Math.round((sizeDiff * 1000) / PANEL_WATT_CAPACITY)
      const productionIncrement = productionDiff / intermediateNumPanels

      // use inner for-loop to populate the data array that will be used for the slider
      for (let j = 0; j < intermediateNumPanels; j++) {
        regressionSliderData.push({
          size: currentSystemSize,
          production: currentSystemProduction,
        })
        currentSystemProduction = currentSystemProduction + productionIncrement
        currentSystemSize = currentSystemSize + 405
      }
    }

    // add the max values to the end of the array
    regressionSliderData.push({
      size: systemSizesArray[systemSizesArray.length - 1] * 1000,
      production: annualProductionArray[annualProductionArray.length - 1],
    })

    return regressionSliderData
  }

  // Functions
  const handleRoofReplacementChange = (event: any) => {
    us_setAdders({ ...us_adders, [event.target.name]: event.target.checked })
    const roofCost = event.target.checked ? calculateRoofCost() : 0
    us_setRoofCost(roofCost)
    updateOpportunity({ current_cash_cost: applyIncentives(us_baseCashCost + roofCost + us_batteryCost) })
    us_setCurrentCashCost(applyIncentives(us_baseCashCost + roofCost + us_batteryCost))
  }

  const handleSystemSizeSliderChange = (event: any, newValue: number) => {
    let panelCount = newValue
    let productionAmount = us_systemRegressionSliderData[newValue].production
    let systemSize = us_systemRegressionSliderData[newValue].size
    submitSystemSizeChange(panelCount, productionAmount, systemSize)
  }

  const submitSystemSizeChange = (panelCount: number, productionAmount: number, systemSize: number) => {
    let annualProd = productionAmount
    us_setSelectedSystemSize(systemSize)
    us_setSelectedSystemAnnualProduction(annualProd)
    us_setNumPanels(panelCount)

    us_setUpdatingPricingNumbers(true)

    // TODO: Missing data issue

    updateOpportunity({
      num_panels: panelCount,
      selected_system_size: systemSize,
      selected_annual_production: annualProd,
    })

    // TODO: Stop calculating lease cost if it fails twice - probably remove once lightreach data is cached
    if (us_leasePricingErrorCount <= 1) {
      getLightreachData(systemSize, productionAmount)
        .then((res_GLD: any) => {
          // Lease Cost
          us_setLeaseCost(res_GLD.data[2].monthlyPayments[0].monthlyPayment)
          us_setLeasePricingError(false)
          us_setLeasePricingErrorCount(0)
          // Base Cash Cost
          us_setBaseCashCost(EPC * panelCount * PANEL_WATT_CAPACITY)
          us_setCurrentCashCost(applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost))
          us_setUpdatingPricingNumbers(false)
          // Update Opportunity
          updateOpportunity({
            base_cash_cost: EPC * panelCount * PANEL_WATT_CAPACITY,
            current_cash_cost: applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost),
            lease_cost: res_GLD.data[2].monthlyPayments[0].monthlyPayment,
          })
            .then((res_UO) => {
              us_setUpdatingPricingNumbers(false)
              ur_forceRerender()
            })
            .catch((rej_UO) => {
              us_setUpdatingPricingNumbers(false)
              ur_forceRerender()
            })
        })
        .catch((rej_GLD) => {
          // Lease Cost
          us_setLeasePricingError(true)
          us_setLeasePricingErrorCount(us_leasePricingErrorCount + 1)
          // Base Cash Cost
          us_setBaseCashCost(EPC * panelCount * PANEL_WATT_CAPACITY)
          us_setCurrentCashCost(applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost))
          us_setUpdatingPricingNumbers(false)
          // Update Opportunity
          updateOpportunity({
            base_cash_cost: EPC * panelCount * PANEL_WATT_CAPACITY,
            current_cash_cost: applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost),
            // lease_cost: res_GLD.data[2].monthlyPayments[0].monthlyPayment,
          })
            .then((res_UO) => {
              us_setUpdatingPricingNumbers(false)
              ur_forceRerender()
            })
            .catch((rej_UO) => {
              us_setUpdatingPricingNumbers(false)
              ur_forceRerender()
            })
        })
    } else {
      // Lease Cost
      us_setLeasePricingError(true)
      us_setLeasePricingErrorCount(us_leasePricingErrorCount + 1)
      // Base Cash Cost
      us_setBaseCashCost(EPC * panelCount * PANEL_WATT_CAPACITY)
      us_setCurrentCashCost(applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost))
      us_setUpdatingPricingNumbers(false)
      // Update Opportunity
      updateOpportunity({
        base_cash_cost: EPC * panelCount * PANEL_WATT_CAPACITY,
        current_cash_cost: applyIncentives(EPC * panelCount * PANEL_WATT_CAPACITY + us_roofCost + us_batteryCost),
        // lease_cost: res_GLD.data[2].monthlyPayments[0].monthlyPayment,
      })
        .then((res_UO) => {
          us_setUpdatingPricingNumbers(false)
          ur_forceRerender()
        })
        .catch((rej_UO) => {
          us_setUpdatingPricingNumbers(false)
          ur_forceRerender()
        })
    }
  }

  const calculateRoofCost = () => {
    const numSquares = Math.ceil(us_roofSizeInFeet / 100)
    const cost = numSquares * 650
    return cost
  }

  const handleBatterySliderChange = (event: any, newValue: number) => {
    let battery = false
    if (newValue == 0) {
      us_setAdders({ ...us_adders, batteries: false })
    } else {
      battery = true
      us_setAdders({ ...us_adders, batteries: true })
    }
    us_setBatteryCost(calculateBatteryAdder(newValue))
    us_setCurrentCashCost(applyIncentives(us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue)))
    updateOpportunity({ current_cash_cost: applyIncentives(us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue)) })
  }

  const calculateCostsWithAdders = (adders: TsInterface_UnspecifiedObject) => {
    let cashCost = us_baseCashCost
    let leaseCost = us_leaseCost
    if (adders.battery) {
      cashCost += calculateBatteryAdder(us_numBatteries)
    }
    if (adders.replace_roof) {
      cashCost += 10000
    }
    return [cashCost, leaseCost]
  }

  const getSRECGroup = () => {
    if (us_leadData && us_leadData.opportunityData) {
      const state = us_leadData.opportunityData.state
      const relevantUtilityCompanies = getProp(utilityCompanyMap, state, null)
      if (relevantUtilityCompanies && relevantUtilityCompanies[us_utilityCompanyName]) {
        return relevantUtilityCompanies[us_utilityCompanyName as string]
      } else {
        // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'Utility company not found.', code: '', details: '' } })
      }
    } else {
      // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'No lead data available.', code: '', details: '' } })
    }
  }

  const getSRECSizeBracket = () => {
    if (us_selectedSystemSize <= 10000) {
      return '<=10'
    } else if (us_selectedSystemSize <= 25000) {
      return '10-25'
    } else {
      return '>25'
    }
  }

  const getSRECRate = (srecGroup: string, srecSizeBracket: string) => {
    if (srecGroup === 'A') {
      if (srecSizeBracket === '<=10') {
        return 75.21
      } else if (srecSizeBracket === '10-25') {
        return 64.71
      } else {
        return 59.28
      }
    } else {
      if (srecSizeBracket === '<=10') {
        return 83.75
      } else if (srecSizeBracket === '10-25') {
        return 76.27
      } else {
        return 69.47
      }
    }
  }

  const calculateSRECRate = () => {
    const srecGroup = getSRECGroup()
    const srecSizeBracket = getSRECSizeBracket()
    const srecRate = getSRECRate(srecGroup, srecSizeBracket)
    return srecRate
  }

  const applyIncentives = (cost: number, federalRate: number = FEDERAL_INCENTIVE_RATE): number => {
    const annualProduction = us_selectedSystemAnnualProduction / 1000
    const degradation = 0.05 // (B7*((1-(1-B27)^15)/0.005)/1000,0)
    const fifteenYearProductionInMWh = Math.floor((annualProduction * ((1 - (1 - degradation) ** 15) / 0.005)) / 1000)
    const costAfterFederalIncentive = cost * (1 - federalRate)
    const costAfterSRECIncentive = costAfterFederalIncentive - calculateSRECRate() * fifteenYearProductionInMWh
    return costAfterSRECIncentive
  }

  const recalculateCostsOnSizeSlider = (adders: TsInterface_UnspecifiedObject, production: number = 0, systemSize: number = 0, reset: boolean = false) => {
    if (reset) {
      return [0, 0]
    }
    const baseLeaseCost = ((production ? production : us_selectedSystemAnnualProduction) / 12) * 0.09
    const batteryCost = calculateBatteryAdder(us_numBatteries)
    const electricalUpgradesCost = adders.electrical_upgrades ? 3500 : 0
    const roofReplacementCost = adders.roof_replacement ? 650 * 100 : 0
    const groundMountCost = calculateGroundMountAdder(us_numGroundMounts)
    const removeTreesCost = adders.remove_trees ? 0 : 0
    const finalLeaseCost =
      baseLeaseCost +
      batteryCost / (12 * 25) +
      electricalUpgradesCost / (12 * 25) +
      roofReplacementCost / (12 * 25) +
      groundMountCost / (12 * 25) +
      removeTreesCost / (12 * 25)
    const finalCashCost =
      (systemSize ? systemSize : us_selectedSystemSize) * 1000 * EPC +
      batteryCost +
      electricalUpgradesCost +
      roofReplacementCost +
      groundMountCost +
      removeTreesCost

    return [finalCashCost, finalLeaseCost]
  }

  const calculateBatteryAdder = (numBatteries: number) => {
    let adderSum = 0
    for (let i = 0; i < numBatteries; i++) {
      if (i == 0) {
        adderSum += 13200
      } else {
        adderSum += 9700
      }
    }
    return adderSum
  }

  const calculateGroundMountAdder = (numMounts: number) => {
    if (numMounts === 0) {
      return 0
    }
    if (numMounts <= 30) {
      return 9000
    }
    let adderSum = 9000
    for (let i = 30; i < numMounts; i += 10) {
      adderSum += 3000
    }
    return adderSum
  }

  const getPossibleSystemSizesAndProductionRatios = (systemSliderData: any) => {
    let possibleSystems: TsInterface_UnspecifiedObject = {}
    for (const sliderData of systemSliderData) {
      const systemSize = sliderData.data.dataOutput.roof['Max kW system']
      const pr = sliderData.production_ratio as number
      if (!possibleSystems[systemSize]) {
        possibleSystems[systemSize] = { production_ratio: sliderData.production_ratio, annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'] }
      } else {
        if (possibleSystems[systemSize] > pr) {
          possibleSystems[systemSize] = {
            production_ratio: sliderData.production_ratio,
            annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'],
          }
        }
      }
    }
    return possibleSystems
  }

  const calculateOffset = (initial: string, newValue: number) => {
    let monthlyBill
    if (typeof initial === 'string') {
      // Remove the dollar sign and parse the number
      monthlyBill = parseInt(initial.replace(/^\$/, ''))
    } else {
      monthlyBill = initial
    }
    return (newValue / ((monthlyBill * 12) / 0.15)) * 100
  }

  const getLightreachData = (systemSize: number, production: number) => {
    return new Promise((resolve, reject) => {
      if (clientKey && opportunityKey && us_leadData) {
        const lightreachParams: TsInterface_LightreachRequestParams = {
          utilityCompany: us_utilityCompanyName,
          state: us_leadData.opportunityData.state,
          systemSize: systemSize / 1000,
          production: production,
          batteryIncluded: false, // TODO: for now, going to calculate battery adders separately
          electricalUpgradeIncluded: false,
        }
        cloudFunctionUnauthenticatedRequests({
          function: 'getLightreachData',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          lightreachParams: lightreachParams,
        })
          .then((res_CFUR) => {
            resolve(res_CFUR)
          })
          .catch((rej_CFUR) => {
            reject(rej_CFUR)
          })
      } else {
        resolve({ success: false })
      }
    })
  }

  const openCustomTooltipDialog = (header: string | JSX.Element, textToDisplay: string | JSX.Element) => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Dialog
            className="bp_dialog_md_width"
            keepMounted
            onClose={() => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            }}
            open={true}
          >
            <DialogContent sx={{ padding: '0px', background: white }}>
              <Box className="tw-p-4">
                <Typography
                  variant="h6"
                  sx={{ color: teal, fontWeight: 'bold' }}
                >
                  {header}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: darkGray }}
                >
                  {textToDisplay}
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  // updates the opportunity in the database
  const updateOpportunity = (updatedFields: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      if (us_leadData) {
        let updatedOpportunity = {}
        for (const entry of Object.entries(updatedFields)) {
          updatedOpportunity = { ...updatedOpportunity, [entry[0]]: entry[1] }
        }
        cloudFunctionUnauthenticatedRequests({
          function: 'updateOpportunity',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          opportunity_data: { ...updatedOpportunity },
        })
          .then((res_CFUR) => {
            resolve(res_CFUR)
          })
          .catch((rej_CFUR) => {
            reject(rej_CFUR)
          })
      } else {
        resolve({ success: false })
      }
    })
  }

  // JSX Generation
  // const rJSX_TempAnalyticsButton = (): JSX.Element => {
  //   let buttonJSX = <></>
  //   buttonJSX = (
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       startIcon={<Icon icon="analytics" />}
  //       className="tw-m-2"
  //       onClick={() => {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             actions_taken: {
  //               clicked_test_button: true,
  //             },
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }}
  //     >
  //       Test Save Analytics Session Action Data
  //     </Button>
  //   )
  //   return buttonJSX
  // }

  // Error View
  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load property data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.white }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  // Customer Info Tab Content
  const rJSX_ContactInfoTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    if (
      us_utilityCompanyName == null ||
      us_utilityCompanyName === '' ||
      us_customerName == null ||
      us_customerName === '' ||
      us_customerEmail == null ||
      us_customerEmail === '' ||
      us_customerPhone == null ||
      us_customerPhone === '' ||
      us_electricityBill == null ||
      us_electricityBill === ''
    ) {
      headerJSX = (
        <Box>
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Contact Info')}
        </Box>
      )
    } else {
      headerJSX = (
        <Box>
          <Icon
            icon="circle-check"
            className="tw-mr-1"
            sx={{ color: themeVariables.success_main }}
          />
          {rLIB('Contact Info')}
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_UtilityCompanyAutocomplete = (): JSX.Element => {
    const newLocal = 'Your Utility Company'
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Box className="">
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: teal }}
          >
            <Icon
              icon="utility-pole-double"
              className="tw-mr-2"
            />
            {rLIB('Utility Company')}
            <Icon
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                "Your utility company is the company that provides your electricity. If you're not sure, check your electricity bill. This information helps us calculate your utility rate and the best system size for your home.",
              )}
              onClick={() => {
                openCustomTooltipDialog(
                  rLIB('Utility Company'),
                  rLIB(
                    "Your utility company is the company that provides your electricity. If you're not sure, check your electricity bill. This information helps us calculate your utility rate and the best system size for your home.",
                  ),
                )
              }}
            />
          </Typography>
          <FormControl
            className="tw-my-2"
            fullWidth
          >
            <Autocomplete
              value={us_utilityCompanyName ? { label: us_utilityCompanyName } : {}}
              onChange={(event, newValue) => {
                if (newValue != null && newValue.label != null) {
                  console.log('UPDATE')
                  updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' })
                  us_setUtilityCompanyName(newValue ? newValue.label : '')
                }
              }}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              options={us_utilityCompanies}
              getOptionLabel={(option) => {
                if (option != null && option.label != null) {
                  return option.label
                } else {
                  return ''
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={rLIB(newLocal)}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Box>
      </Card>
    )
  }

  const rJSX_ContactInfoTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mx-2">
        <Card className="tw-p-2 tw-mb-2">
          <Box className="tw-mt-0">
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{ color: teal }}
            >
              <Icon
                icon="address-card"
                className="tw-mr-2"
              />
              {rLIB('Contact Info')}
            </Typography>

            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Name')}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  us_setCustomerName(event.target.value || '')
                  us_setCustomerNameChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerNameChanged === true) {
                    updateOpportunity({ name: event.target.value })
                    us_setCustomerNameChanged(false)
                  }
                }}
                value={us_customerName}
              />
            </FormControl>
            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Email')}
                variant="outlined"
                fullWidth
                value={us_customerEmail || ''}
                onChange={(event) => {
                  us_setCustomerEmail(event.target.value)
                  us_setCustomerEmailChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerEmailChanged === true) {
                    updateOpportunity({ email: event.target.value })
                    us_setCustomerEmailChanged(false)
                  }
                }}
              />
            </FormControl>
            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Phone')}
                variant="outlined"
                fullWidth
                value={us_customerPhone || ''}
                onChange={(event) => {
                  us_setCustomerPhone(event.target.value)
                  us_setCustomerPhoneChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerPhoneChanged === true) {
                    updateOpportunity({ phone: event.target.value })
                    us_setCustomerPhoneChanged(false)
                  }
                }}
              />
            </FormControl>
          </Box>
        </Card>
        {rJSX_UtilityCompanyAutocomplete()}
      </Box>
    )
    return tabJSX
  }

  // System Tab Content
  const rJSX_SystemTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="solar-panel"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('System')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_LoadingPriceIcon = (): JSX.Element => {
    let iconJSX = <></>
    if (us_updatingPricingNumbers === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-ml-2"
        />
      )
    }
    return iconJSX
  }

  const rJSX_SystemSizeVisualizationSegment = (percent: number, offset: number): JSX.Element => {
    let segmentJSX = <></>
    if (percent <= offset) {
      segmentJSX = (
        <Box sx={{ paddingLeft: '1px', paddingRight: '1px', width: '5%' }}>
          <Box
            className="tw-rounded"
            sx={{ backgroundColor: yellow, height: '20px', width: '100%' }}
          ></Box>
        </Box>
      )
    } else {
      segmentJSX = (
        <Box sx={{ paddingLeft: '1px', paddingRight: '1px', width: '5%' }}>
          <Box
            className="tw-rounded"
            sx={{ border: '2px solid ' + yellow, height: '20px', width: '100%' }}
          ></Box>
        </Box>
      )
    }
    return segmentJSX
  }

  const rJSX_SystemSizeVisualizationFull = (): JSX.Element => {
    let visualizationJSX = <></>
    let systemOffsetPercent = calculateOffset(us_electricityBill, us_selectedSystemAnnualProduction)
    visualizationJSX = (
      <Stack
        direction="row"
        spacing="0"
      >
        {rJSX_SystemSizeVisualizationSegment(5, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(10, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(15, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(20, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(25, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(30, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(35, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(40, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(45, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(50, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(55, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(60, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(65, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(70, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(75, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(80, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(85, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(90, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(95, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(100, systemOffsetPercent)}
      </Stack>
    )
    return visualizationJSX
  }

  const rJSX_UtilityBillSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
          sx={{ color: teal }}
        >
          <Icon
            icon="bolt"
            className="tw-mr-2"
          />
          {rLIB('Utility Bill')}
          <Icon
            icon="info-circle"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB(
              'We use your utility bill to estimate your electricity usage and calculate the size of the solar system you need to offset your electricity bill',
            )}
            onClick={() => {
              openCustomTooltipDialog(
                rLIB('Utility Bill'),
                rLIB(
                  'We use your utility bill to estimate your electricity usage and calculate the size of the solar system you need to offset your electricity bill',
                ),
              )
            }}
          />
        </Typography>
        <FormControl
          className="tw-my-2"
          fullWidth
        >
          <TextField
            label={rLIB('Your Monthly Electricity Bill')}
            variant="outlined"
            type="number"
            fullWidth
            value={us_electricityBill}
            onChange={(event) => {
              us_setElectricityBill(event.target.value)
              us_setElectricityBillChanged(true)
            }}
            onBlur={(event) => {
              if (us_electricityBillChanged === true) {
                console.log('UPDATE')
                updateOpportunity({ contact_monthly_electric_bill: event.target.value })
                us_setElectricityBillChanged(false)
              }
            }}
          />
        </FormControl>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_SystemSizeSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block"
            sx={{ color: teal }}
          >
            <Icon
              icon="solar-panel"
              className="tw-mr-2"
            />
            {rLIB('System Size')}
            <Box
              component={'span'}
              className="tw-inline-block tw-ml-1"
              sx={{ color: teal }}
            >
              ({(us_selectedSystemSize / 1000).toFixed(1)} {rLIB('kW')})
            </Box>
            <Icon
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                'Your system is composed of solar panels that convert sunlight into electricity. The more panels you have the more electricity you can produce',
              )}
              onClick={() => {
                openCustomTooltipDialog(
                  rLIB('System Size'),
                  rLIB(
                    'Your system is composed of solar panels that convert sunlight into electricity. The more panels you have the more electricity you can produce',
                  ),
                )
              }}
            />
          </Typography>
        </Box>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
          >
            <Slider
              sx={{ height: 10, width: '100%', marginX: 'auto' }}
              value={us_numPanels}
              valueLabelDisplay="auto"
              // marks
              step={1}
              min={0}
              max={us_systemRegressionSliderData.length - 1}
              onChangeCommitted={(event, value) => handleSystemSizeSliderChange(event, value as number)}
              disabled={us_updatingPricingNumbers === true}
            />
          </Grid2>
          <Grid2
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="tw-text-center"
          >
            <Typography
              variant="h5"
              sx={{ color: teal, fontWeight: 'bold', marginBottom: '-5px' }}
            >
              {us_numPanels}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: teal }}
            >
              {rLIB('Panels')}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_ProductionSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block"
            sx={{ color: teal }}
          >
            <Icon
              icon="battery-bolt"
              className="tw-mr-2"
            />
            {rLIB('Production')} ({us_selectedSystemAnnualProduction.toFixed(0)} {rLIB('kWh/year')})
            <Icon
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                'Offset is the percentage of your electricity bill that will be covered by solar production. The more panels in your system the greater the offset',
              )}
              onClick={() => {
                openCustomTooltipDialog(
                  rLIB('Production'),
                  rLIB(
                    'Offset is the percentage of your electricity bill that will be covered by solar production. The more panels in your system the greater the offset',
                  ),
                )
              }}
            />
          </Typography>
        </Box>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
          >
            <Box className="tw-mt-3">{rJSX_SystemSizeVisualizationFull()}</Box>
          </Grid2>
          <Grid2
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="tw-text-center"
          >
            <Typography
              variant="h5"
              sx={{ color: yellow, fontWeight: 'bold', marginBottom: '-5px' }}
            >
              {calculateOffset(us_electricityBill, us_selectedSystemAnnualProduction).toFixed(0)}%
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: yellow }}
            >
              {rLIB('Offset')}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_LeasePricingLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else if (us_leasePricingError || us_leaseCost === 0) {
      labelJSX = <>{rLIB('Unknown')}</>
    } else {
      labelJSX = (
        <>
          {formatNumberToDollar(us_leaseCost)} / {rLIB('month')}
        </>
      )
    }
    return labelJSX
  }

  const rJSX_CashPricingLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else {
      labelJSX = <>{formatNumberToDollar(us_currentCashCost)}</>
    }
    return labelJSX
  }

  const rJSX_PricesSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
          sx={{ color: teal }}
        >
          <Icon
            icon="circle-dollar"
            className="tw-mr-2"
          />
          {rLIB('Estimated Prices')}
          <Icon
            icon="info-circle"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB(
              'The estimated prices are based on the system size and production ratio. The final price may vary based on additional adders and incentives',
            )}
            onClick={() => {
              openCustomTooltipDialog(
                rLIB('Estimated Prices'),
                rLIB(
                  'The estimated prices are based on the system size and production ratio. The final price may vary based on additional adders and incentives',
                ),
              )
            }}
          />
          {rJSX_LoadingPriceIcon()}
        </Typography>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{ background: us_updatingPricingNumbers ? darkGray : green, fontSize: '1.2rem', color: white, height: '36px' }}
            >
              {/* <Box>{formatNumberToDollar(us_baseCashCost)}</Box> */}
              {/* <Box>{formatNumberToDollar(us_currentCashCost)}</Box> */}
              {rJSX_CashPricingLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{ color: us_updatingPricingNumbers ? darkGray : green, verticalAlign: 'top', marginTop: '-4px' }}
            >
              {rLIB('Cash')}*
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={
                  <>
                    {rLIB(
                      'Own your system outright with a single upfront payment. The displayed price includes all incentives. Without incentives the price is ',
                    )}
                    {formatNumberToDollar(us_baseCashCost)}
                  </>
                }
                onClick={() => {
                  openCustomTooltipDialog(
                    rLIB('Cash'),
                    <>
                      {rLIB(
                        'Own your system outright with a single upfront payment. The displayed price includes all incentives. Without incentives the price is ',
                      )}{' '}
                      {formatNumberToDollar(us_baseCashCost)}
                    </>,
                  )
                }}
              />
            </Typography>
          </Grid2>
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{
                background: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : green,
                fontSize: '1.2rem',
                color: white,
                height: '36px',
              }}
            >
              {rJSX_LeasePricingLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{
                color: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : green,
                verticalAlign: 'top',
                marginTop: '-4px',
              }}
            >
              {rLIB('Financed')}
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={rLIB('No upfront cost. Make monthly payments that replace part or all of your utility bill.')}
                onClick={() => {
                  openCustomTooltipDialog(rLIB('Financed'), rLIB('No upfront cost. Make monthly payments that replace part or all of your utility bill.'))
                }}
              />
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  // const rJSX_AddersSection = (): JSX.Element => {
  //   // TODO: Batteries
  //   let sectionJSX = <></>
  //   return sectionJSX
  // }

  const rJSX_SystemDetailsTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mx-2">
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_PricesSection()}</Card>
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_UtilityBillSection()}</Card>
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_ProductionSection()}</Card>
        <Card className="tw-pl-4 tw-pr-2 tw-pt-2 tw-mb-2">{rJSX_SystemSizeSection()}</Card>
        {/* <Card className="tw-p-2 tw-mb-2">{rJSX_AddersSection()}</Card> */}
      </Box>
    )
    return tabJSX
  }

  // Customize Tab Content
  const rJSX_CustomizeTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="wrench"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Customize')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_CustomizeTab = (): JSX.Element => {
    let tabJSX = <></>
    let textJSX = <></>
    let disableButton = true
    if (
      us_utilityCompanyName == null ||
      us_utilityCompanyName === '' ||
      us_customerName == null ||
      us_customerName === '' ||
      us_customerEmail == null ||
      us_customerEmail === '' ||
      us_customerPhone == null ||
      us_customerPhone === '' ||
      us_electricityBill == null ||
      us_electricityBill === ''
    ) {
      textJSX = <>{rLIB('If you want to see the exact panel layout on your roof and customize it, please fill out your contact info in the previous tab.')}</>
    } else {
      textJSX = <>{rLIB('If you want to see the exact panel layout on your roof and customize it, confirm your contact information')}</>
      disableButton = false
    }
    tabJSX = (
      <Box className="tw-px-2">
        <Card className="tw-p-2">
          <Box className="tw-mt-2">
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: black, fontWeight: 'bold' }}
            >
              {textJSX}
            </Typography>
          </Box>
          <Divider className="tw-my-2" />
          <Box className="tw-text-left">
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {rLIB('Your Name')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerName}
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="envelope"
                  className="tw-mr-2"
                />
                {rLIB('Your Email')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerEmail}
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="phone"
                  className="tw-mr-2"
                />
                {rLIB('Your Phone')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerPhone}
              </Box>
            </Typography>
          </Box>
          <Divider className="tw-my-2" />
          <Box className="tw-m-auto tw-text-center tw-mt-4">
            <Button
              variant="contained"
              onClick={() => requestProposeDataset()}
              disabled={disableButton}
            >
              <Typography>Customize System</Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Shared Content
  const rJSX_AddressBanner = (): JSX.Element => {
    let bannerJSX = (
      <Box
        className="tw-p-2"
        sx={{ background: teal }}
      >
        <Typography
          variant="h6"
          sx={{ color: white, fontWeight: 'bold' }}
          className="tw-truncate"
        >
          <Icon
            icon="house"
            className="tw-mr-2"
          />
          {us_leadData?.opportunityData.address1} {us_leadData?.opportunityData.city} {us_leadData?.opportunityData.state}{' '}
          {us_leadData?.opportunityData.postal_code}
        </Typography>
      </Box>
    )

    return bannerJSX
  }

  const rJSX_MapImageBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (us_leadData != null && us_leadData.eagleviewProspectData != null && us_leadData.eagleviewProspectData.ev_image_data != null) {
      bannerJSX = (
        <Box
          sx={{
            backgroundImage: `url(${us_leadData.eagleviewProspectData.ev_image_data ? `data:image/jpeg;base64,${us_leadData.eagleviewProspectData.ev_image_data}` : ''})`,
            width: '100%',
            height: '280px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></Box>
      )
    } else {
      bannerJSX = (
        <MapBasic
          mapMarkers={{}}
          mapSettings={{
            center_lat: getProp(us_propertyCoordinates, 'latitude', 0),
            center_lng: getProp(us_propertyCoordinates, 'longitude', 0),
            height: '280px',
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: '100%',
            zoom: 21,
            map_type_id: 'satellite',
            additional_map_options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            },
          }}
        />
      )
    }
    return bannerJSX
  }

  const rJSX_ProspectDataView = (): JSX.Element => {
    return (
      <Box>
        {rJSX_AddressBanner()}
        {rJSX_MapImageBanner()}
        <Box sx={{ maxWidth: '800px', width: '100%', margin: 'auto' }}>
          <TabsBasic
            tabs={[
              {
                tabHeader: rJSX_SystemTabHeader(),
                tabContent: <Box>{rJSX_SystemDetailsTab()}</Box>,
              },
              {
                tabHeader: rJSX_ContactInfoTabHeader(),
                tabContent: <Box>{rJSX_ContactInfoTab()}</Box>,
              },
              {
                tabHeader: rJSX_CustomizeTabHeader(),
                tabContent: <Box>{rJSX_CustomizeTab()}</Box>,
              },
            ]}
            tabsSettings={{ thin: true }}
          />
        </Box>
      </Box>
    )
  }

  // Phone Number Confirmation View
  const rJSX_SaveMoneyBanner = () => {
    let bannerJSX = <></>
    bannerJSX = (
      <Box
        className="tw-p-4"
        sx={{ backgroundColor: teal, width: '100%' }}
      >
        <Typography
          variant="h6"
          className="tw-text-center"
          sx={{ color: white, fontWeight: 'bold', width: '100%' }}
        >
          <Icon
            icon="solar-panel"
            type="solid"
            className="tw-mr-2"
          />
          {rLIB('Save BIG by going solar!')}
          <Icon
            icon="face-party"
            type="solid"
            className="tw-ml-2"
          />
        </Typography>
      </Box>
    )
    return bannerJSX
  }

  const rJSX_PhoneIntroText = (): JSX.Element => {
    let textJSX = <></>
    if (us_leadData != null && us_leadData.opportunityData != null && us_leadData.opportunityData.address1 != null) {
      // Remove substring before first space from address
      let formattedAddress = us_leadData.opportunityData.address1.split(' ').slice(1).join(' ')
      textJSX = (
        <Box className="tw-my-6">
          <Typography
            variant="h5"
            className="tw-text-center"
            sx={{ color: darkGray, fontWeight: 'bold' }}
          >
            <Box className="">
              {rLIB("We've analyzed your home")} {rLIB('at')}{' '}
            </Box>
            <Box sx={{ color: 'black' }}>{formattedAddress}</Box>
            <Box className="">{rLIB('and designed a great custom solar solution for you!')}</Box>
          </Typography>
        </Box>
      )
    } else {
      textJSX = (
        <Box className="tw-my-6">
          <Typography
            variant="h5"
            className="tw-text-center"
            sx={{ color: darkGray, fontWeight: 'bold' }}
          >
            <Box className="tw-inline-block">
              {rLIB("We've analyzed your home")} {rLIB('and designed a great custom solar solution for you!')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return textJSX
  }

  const rJSX_ConfirmPhoneNumberView = (phone: string): JSX.Element => {
    let formattedPhoneNumber = returnStandardizedUSPhoneNumber(phone)
    let confirmPhoneNumberJSX = (
      <Box>
        {rJSX_SaveMoneyBanner()}
        <Box
          sx={{ maxWidth: '600px', margin: 'auto' }}
          className="tw-p-2"
        >
          {rJSX_PhoneIntroText()}
          <Box
            className="tw-p-4 tw-mt-4 tw-rounded-xl"
            sx={{ width: '100%', border: '1px solid ' + lightGray2, backgroundColor: white }}
          >
            <Typography
              variant="h5"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Confirm your phone number')}
            </Typography>
            <Typography
              variant="body1"
              className="tw-text-center"
              sx={{ color: darkGray, fontWeight: 'regular' }}
            >
              {rLIB('In order to view your custom solar layout, please confirm your phone number')}
            </Typography>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-8 tw-text-center">
              <Typography
                variant="h4"
                className="tw-text-center tw-mt-2"
                sx={{ color: darkGray }}
              >
                XXX-XXX-{formattedPhoneNumber.slice(-4)}
              </Typography>
              <FormControl
                className="bp_thin_text_input"
                sx={{ minWidth: '300px', marginRight: '12px', marginBottom: '8px' }}
              >
                <TextField
                  onChange={(event) => {
                    us_setUserPhoneNumber(event.target.value)
                  }}
                  value={us_userPhoneNumber}
                  variant="outlined"
                  className="tw-mt-4"
                  sx={{
                    width: '300px',
                    input: { fontSize: 24 },
                  }}
                />

                <Button
                  sx={{ fontSize: 18 }}
                  className="tw-mt-4"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <Icon
                      icon="badge-check"
                      type="solid"
                    />
                  }
                  // Disable if there are not at least 10 numeric digits
                  disabled={
                    returnStandardizedUSPhoneNumber(us_userPhoneNumber).length < 10 ||
                    returnStandardizedUSPhoneNumber(us_userPhoneNumber) === 'Invalid phone number'
                  }
                  onClick={() => {
                    // Error Message
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: rLIB('Failed to confirm phone number'),
                        details: rLIB("The number we have on file doesn't match the number you entered. Please try again."),
                        code: 'ER-MATCH',
                      },
                    })
                  }}
                >
                  {rLIB('Confirm Phone')}
                </Button>
                {returnStandardizedUSPhoneNumber(us_userPhoneNumber)}
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 126, height: 36, position: 'fixed', bottom: '5px', left: 'calc(50% - 53px)' }}
          alt="logo"
          src={logoSrc}
        />
      </Box>
    )
    return confirmPhoneNumberJSX
  }

  // Loading Data View
  const rJSX_LoadingDataView = (): JSX.Element => {
    return (
      <Box
        className="tw-text-center tw-mt-2"
        sx={{ paddingTop: 'calc(50vh - 100px)' }}
      >
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 189, height: 54 }}
          alt="logo"
          src={logoSrc}
        />
        <CircularProgress
          sx={{ color: teal }}
          size="64px"
        />
      </Box>
    )
  }

  // Eagleview Propose Data View

  // Eagleview Load Prospect Data
  const rJSX_RotatingBanner = () => {
    let bannerJSX = <></>
    bannerJSX = (
      <Box
        className="tw-p-4"
        sx={{ backgroundColor: teal, width: '100%' }}
      >
        <Typography
          variant="h6"
          className="tw-text-center"
          sx={{ color: white, fontWeight: 'bold', width: '100%', fontSize: '18px' }}
        >
          <RotatingLoadingMessages />
        </Typography>
      </Box>
    )
    return bannerJSX
  }

  const rJSX_LoadEagleviewDataView = (titleText: JSX.Element | string): JSX.Element => {
    let viewJSX = <></>
    viewJSX = (
      <Box>
        <Box className="tw-truncate">
          {rJSX_RotatingBanner()}
          <LinearProgress />
        </Box>
        <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
          <Box className="tw-mt-6">
            <Typography
              variant="h5"
              className="tw-text-center"
              sx={{ color: black, fontWeight: 'bold' }}
            >
              {titleText}
            </Typography>
            <Typography
              variant="body1"
              className="tw-text-center"
              sx={{ color: darkGray, fontWeight: 'regular' }}
            >
              {rLIB('This should take about a minute')}
            </Typography>
          </Box>
          <Divider className="tw-my-4" />
          <Box className="tw-text-center">
            <Typography
              variant="h5"
              className="tw-text-center"
              sx={{ color: black, fontWeight: 'bold' }}
            >
              {rLIB('How does our AI work?')}
            </Typography>
            <Divider className="tw-my-4" />
            <Stack
              direction="row"
              spacing="2"
            >
              <Box
                className="tw-m-2 tw-text-center tw-inline-block"
                sx={{
                  border: '3px solid ' + teal,
                  padding: '2px',
                  color: teal,
                  width: '54px',
                  height: '54px',
                  borderRadius: '100%',
                  fontSize: '30px',
                  fontWeight: 'bold',
                }}
              >
                1
              </Box>
              <Box
                className="tw-text-left"
                sx={{ maxWidth: 'calc(100% - 75px)' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: darkGray }}
                >
                  {rLIB("We've partnered with a company who has detailed aerial imagery of the entire United States")}
                </Typography>
              </Box>
            </Stack>
            <Divider className="tw-my-4" />
            <Stack
              direction="row"
              spacing="2"
            >
              <Box
                className="tw-m-2 tw-text-center tw-inline-block"
                sx={{
                  border: '3px solid ' + teal,
                  padding: '2px',
                  color: teal,
                  width: '54px',
                  height: '54px',
                  borderRadius: '100%',
                  fontSize: '30px',
                  fontWeight: 'bold',
                }}
              >
                2
              </Box>
              <Box
                className="tw-text-left"
                sx={{ maxWidth: 'calc(100% - 75px)' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: darkGray }}
                >
                  {rLIB('We calculate the size of your roof and the amount of sunlight it receives throughout the year for every roof plane')}
                </Typography>
              </Box>
            </Stack>
            <Divider className="tw-my-4" />
            <Stack
              direction="row"
              spacing="2"
            >
              <Box
                className="tw-m-2 tw-text-center tw-inline-block"
                sx={{
                  border: '3px solid ' + teal,
                  padding: '2px',
                  color: teal,
                  width: '54px',
                  height: '54px',
                  borderRadius: '100%',
                  fontSize: '30px',
                  fontWeight: 'bold',
                }}
              >
                3
              </Box>
              <Box
                className="tw-text-left"
                sx={{ maxWidth: 'calc(100% - 75px)' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: darkGray }}
                >
                  {rLIB('We determine the optimal panel placement to maximize your solar production. The more efficient the layout, the more you save')}
                </Typography>
              </Box>
            </Stack>
            <Divider className="tw-my-4" />
            <Stack
              direction="row"
              spacing="2"
            >
              <Box
                className="tw-m-2 tw-text-center tw-inline-block"
                sx={{
                  border: '3px solid ' + teal,
                  padding: '2px',
                  color: teal,
                  width: '54px',
                  height: '54px',
                  borderRadius: '100%',
                  fontSize: '30px',
                  fontWeight: 'bold',
                }}
              >
                4
              </Box>
              <Box
                className="tw-text-left"
                sx={{ maxWidth: 'calc(100% - 75px)' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: darkGray }}
                >
                  {rLIB('We calculate different financing options, looking for the best way for you to save you money')}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 126, height: 36, position: 'fixed', bottom: '5px', left: 'calc(50% - 53px)' }}
          alt="logo"
          src={logoSrc}
        />
      </Box>
    )
    return viewJSX
  }

  // Propose
  const rJSX_CustomSystemDialog = (): JSX.Element => {
    return (
      <Dialog
        className="bp_dialog_md_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <DialogContent sx={{ padding: '0px', background: white }}>
          <Box className="tw-p-4">
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Customizing your solar layout')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ color: darkGray }}
            >
              1.{' '}
              {rLIB(
                "Click on the solar panels on the map or the list to the left to add or remove them from your custom solar layout. We'll update your production and cost in real time",
              )}
            </Typography>
            <Typography
              variant="body1"
              className="tw-mt-2"
              sx={{ color: darkGray }}
            >
              2. {rLIB('The most cost efficient layouts use the fewest number of panels to reach your desired production. (Usually 100% offset)')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Notes')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ color: darkGray }}
            >
              1.{' '}
              {rLIB(
                'Our panel overlay may not be aligned with your roof, you can use the yellow arrows to adjust the overlay. (This does not affect the price estimates or final layout)',
              )}
            </Typography>
            <Typography
              variant="body1"
              className="tw-mt-2"
              sx={{ color: darkGray }}
            >
              2. {rLIB("We still require a human to verify the layout before installation but what's shown is usually very close to the final layout")}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const rJSX_SubmitTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="paper-plane"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Submit Design')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_UtilityTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="utility-pole-double"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Utility')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_EagleViewVisualization = (eagleviewProposeData: TsInterface_UnspecifiedObject): JSX.Element => {
    let visualizationJSX = <></>
    if (us_activeProposePanels != null) {
      visualizationJSX = (
        <EagleviewProposePanelsVisualization
          activePanelSelection={us_activeProposePanels}
          mapHeight="calc( 100vh - 400px )"
          mapWidth="100%"
          disablePanelSelection={us_updatingPricingNumbers}
          // panelsLatOffset={0.0000175}
          panelsLatOffset={0}
          // panelsLngOffset={-0.00001}
          panelsLngOffset={0}
          panelData={getProp(eagleviewProposeData, 'dataOutput', {})}
          onChange={(panelData: TsInterface_EagleviewVisualizationCallbackData) => {
            console.log(panelData)
            if (panelData != null && panelData.active_panels != null) {
              updateOpportunity({ active_propose_panels: panelData.active_panels })
              us_setActiveProposePanels(panelData.active_panels)
            }

            // Example of how to use the callback data
            let totalSystemSize = 0
            let totalAnnualSystemSize = 0
            let panelCount = 0
            if (panelData != null && panelData['active_panels'] != null && panelData['panel_data'] != null && panelData['panel_data']['energyTable'] != null) {
              for (let loopPanelIndex in panelData['panel_data']['energyTable']) {
                let loopPanel = panelData['panel_data']['energyTable'][loopPanelIndex]

                if (
                  loopPanel != null &&
                  loopPanel['kW size'] != null &&
                  loopPanel['kWh/yr'] != null &&
                  getProp(panelData['active_panels'], loopPanelIndex, true) === true
                ) {
                  panelCount++
                  totalSystemSize += loopPanel['kW size']
                  totalAnnualSystemSize += loopPanel['kWh/yr']
                }
              }
            }
            if (panelData['event_type'] === 'panel_toggle') {
              submitSystemSizeChange(panelCount, totalAnnualSystemSize, totalSystemSize)
            }
          }}
        />
      )
    } else {
      visualizationJSX = <Box sx={{ background: themeVariables.background_paper, width: '100%', height: 'calc( 100vh - 400px )' }}></Box>
    }
    return visualizationJSX
  }

  const rJSX_ProposeDataView = (eagleviewProposeData: TsInterface_UnspecifiedObject): JSX.Element => {
    let viewJSX = <></>
    viewJSX = (
      <Box>
        {rJSX_AddressBanner()}
        {rJSX_EagleViewVisualization(eagleviewProposeData)}
        <TabsBasic
          tabs={[
            {
              tabHeader: rJSX_CustomizeTabHeader(),
              tabContent: (
                <Box className="tw-mx-2 tw-mt-2">
                  <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_PricesSection()}</Card>
                  <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_ProductionSection()}</Card>
                </Box>
              ),
            },
            {
              tabHeader: rJSX_UtilityTabHeader(),
              tabContent: (
                <Box className="tw-mx-2 tw-mt-2">
                  <Card className="tw-px-2 tw-pt-0 tw-mb-2">{rJSX_UtilityBillSection()}</Card>
                  {rJSX_UtilityCompanyAutocomplete()}
                </Box>
              ),
            },
            {
              tabHeader: rJSX_SubmitTabHeader(),
              tabContent: (
                <Box className="tw-text-center tw-mt-2 tw-mx-2">
                  <Card className="tw-p-2">
                    <Typography
                      variant="h6"
                      sx={{ color: black }}
                    >
                      {rLIB(
                        'Submitting your design for review will alert our team to review your custom solar layout and reach out for further details. This is not a commitment to purchase',
                      )}
                    </Typography>
                    <Button
                      className="tw-mt-4"
                      variant="contained"
                      startIcon={<Icon icon="paper-plane" />}
                    >
                      {rLIB('Submit Design for Review')}
                    </Button>
                  </Card>
                </Box>
              ),
            },
          ]}
          tabsSettings={{ thin: true }}
        />
        <Box sx={{ position: 'fixed', bottom: 8, right: 8 }}>
          <Fab
            color="warning"
            onClick={() => {
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: rJSX_CustomSystemDialog(),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="question"
              type="regular"
              sx={{ fontSize: '34px' }}
            />
          </Fab>
        </Box>
      </Box>
    )
    return viewJSX
  }

  // Page Content
  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataLoadedStatus === 'loading_eagleview_propose_data') {
      contentJSX = rJSX_LoadEagleviewDataView(rLIB('Our AI system is finalizing your custom solar layout'))
    } else if (us_dataLoadedStatus === 'loading') {
      contentJSX = rJSX_LoadingDataView()
    } else if (us_dataLoadedStatus === 'success') {
      if (
        us_confirmedPhoneNumber !== true &&
        us_leadData != null &&
        us_leadData.opportunityData != null &&
        us_leadData.opportunityData.phone != null &&
        returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number'
      ) {
        contentJSX = rJSX_ConfirmPhoneNumberView(us_leadData.opportunityData.phone)
      } else if (us_leadData != null && us_leadData.eagleviewProposeData != null && objectToArray(us_leadData.eagleviewProposeData).length > 0) {
        contentJSX = rJSX_ProposeDataView(us_leadData.eagleviewProposeData)
      } else if (us_leadData != null && (us_leadData.eagleviewProspectData == null || objectToArray(us_leadData.eagleviewProspectData).length === 0)) {
        contentJSX = rJSX_LoadEagleviewDataView(rLIB('Our AI system is designing your custom solar layout'))
      } else {
        contentJSX = rJSX_ProspectDataView()
      }
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = rJSX_ErrorDisplay()
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        <ThemeProvider theme={lightTheme}>
          {rJSX_PageContent()}
          <style>{pageCSS}</style>
        </ThemeProvider>
      </Box>
    )
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}
