///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
    1. clean up ui bugs on comparison page (z index on select menu)
    2. implement dupes integration on comparison page
      - current state of affairs is that if you manipulate dupes side by side, they will each change the same config in the db
      but the changes will not render in the dupe column on the UI
      - need to create new ones and differentiate by sub-attributes, and delete completely identical ones if they are not being rendered side by side
    3. hook up advanced sliders on comparison page (can't until we start calculating the cost)
    4. add add all tooltips on comparison page (talk to Raphael about this)
    5. create other pages/begin api integrations
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Drawer, List, ListItem, MenuItem, Select, Tooltip, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { jwtDecode } from 'jwt-decode'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesEstimates_Collection,
  DatabaseRef_SalesEstimate_Document,
  DatabaseRef_SalesOpportunity_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarkers, TsInterface_MapSettings, TsType_MapOnClick } from 'rfbp_core/components/map'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { getCoordinatesFromAddress } from 'rfbp_core/services/helper_functions/get_coordinates_from_address'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_GenericPromiseReject, TsInterface_GenericPromiseResolve, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { rJSX_Slider } from './components/adjustable_components'
import {
  rJSX_EquipmentSection,
  rJSX_FinancingSection,
  rJSX_SiteDetailsSection,
  rJSX_SystemSizeSection,
  rJSX_WarrantySection,
} from './components/comparison_sections'
import { rJSX_LoadingBox } from './components/helper'
import { formInputs_HomeInfo } from './forms/new_address_utility_form'
import {
  TsInterface_ConfigBundle,
  TsInterface_Coordinates,
  TsInterface_FormattedConfig,
  TsInterface_KeyConfigProps,
  TsInterface_MapIconObject,
  TsInterface_SalesOpportunity,
  TsInterface_SelectedConfigKeys,
  TsInterface_SystemConfig,
} from './interfaces/i_sales_tools'
import { Container as rJSX_AdminViewPage } from './tabs/admin_view'
// import { getEagleViewAccessToken, getEagleViewProspectData, placeEagleViewOrder, rJSX_EagleViewAdjuster } from './tabs/eagleview'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////
type Anchor = 'top' | 'bottom' | 'left' | 'right'
const batteryBrand = 'XX'
const inverterBrand = 'XX'
const moduleBrand = 'XX'
const PROSPECT_PRODUCT_ID = 94

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesOpportunityViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////
export const getMatchingConfig = (configElements: TsInterface_KeyConfigProps, state: TsInterface_ConfigBundle): TsInterface_SystemConfig => {
  let match = {}

  // find the configuration of the visible configurations that matches our selected configuration and return that
  for (const entry of Object.entries(state)) {
    let key = entry[0]
    let value = entry[1] as TsInterface_SystemConfig
    if (
      value['financing_type'] === configElements['financing_type'] &&
      value['battery'] === configElements['battery'] &&
      value['financing_duration'] === configElements['financing_duration']
    ) {
      match = getProp(state, key, null)
    }
  }

  return match
}

const generateDefaultSystemConfigs = (state: TsInterface_SalesOpportunity): TsInterface_ConfigBundle => {
  // key attributes: financing_type, battery, financing_duration
  // TODO: use address/utility info to generate these defaults, may need to have some more complex logic here
  const firstKey = uuidv4()
  const secondKey = uuidv4()
  const thirdKey = uuidv4()
  const fourthKey = uuidv4()
  const fifthKey = uuidv4()
  const sixthKey = uuidv4()
  const seventhKey = uuidv4()
  const eighthKey = uuidv4()
  const ninthKey = uuidv4()
  const tenthKey = uuidv4()

  const defaultConfigs: TsInterface_ConfigBundle = {
    [firstKey]: {
      key: firstKey,
      is_default: true,
      financing_type: 'cash',
      battery: true,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 1,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 0,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [secondKey]: {
      key: secondKey,
      is_default: true,
      financing_type: 'cash',
      battery: false,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 0,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 0,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [thirdKey]: {
      key: thirdKey,
      is_default: true,
      financing_type: 'lease',
      battery: true,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 1,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 25,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [fourthKey]: {
      key: fourthKey,
      is_default: true,
      financing_type: 'lease',
      battery: true,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 1,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 12.5,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [fifthKey]: {
      key: fifthKey,
      is_default: true,
      financing_type: 'lease',
      battery: false,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 0,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 25,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [sixthKey]: {
      key: sixthKey,
      is_default: true,
      financing_type: 'lease',
      battery: false,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 0,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 12.5,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [seventhKey]: {
      key: seventhKey,
      is_default: true,
      financing_type: 'loan',
      battery: true,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 1,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 25,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [eighthKey]: {
      key: eighthKey,
      is_default: true,
      financing_type: 'loan',
      battery: true,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 1,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 12.5,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [ninthKey]: {
      key: ninthKey,
      is_default: true,
      financing_type: 'loan',
      battery: false,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 0,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 25,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
    [tenthKey]: {
      key: tenthKey,
      is_default: true,
      financing_type: 'loan',
      battery: false,
      offset: 100,
      size: getProp(state, 'utility_consumption', '0'),
      battery_brand: 'XX',
      inverter_brand: 'XX',
      module_brand: 'XX',
      battery_quantity: 0,
      equipment_warranty: 'XX',
      extended_warranty: true,
      production_guarantee: 'XX',
      financing_duration: 12.5,
      remove_roof: false,
      remove_trees: false,
      escalator: {
        one: false,
        two: false,
        three: false,
      },
      mount: { ground: false, car_port: false, custom: false },
      amenities: {
        ev: false,
        hot_tub: false,
        pool: false,
      },
      solar_rate: 0,
      epc: 2,
      commission: 0,
      monthly_payment: 0,
      cost: calculateCost(),
      num_panels: 0,
    },
  }

  return defaultConfigs
}

const calculateCost = (): number => {
  return 0
}

const formatSelectConfig = (config: TsInterface_SystemConfig): TsInterface_FormattedConfig => {
  const formattedFinancingType = capitalizeFirstLetterInString(getProp(config, 'financing_type', null))
  if (!formattedFinancingType || formattedFinancingType.toLowerCase() === 'none') {
    return {
      formatted_key: 'None',
      formatted_financing_type: '',
      formatted_battery: '',
      formatted_duration: '',
    }
  }
  const formattedHasBattery = getProp(config, 'battery', false) ? 'Battery' : 'No Battery'
  let duration = getProp(config, 'financing_duration', 0)
  const formattedDuration = duration ? String(duration) + ' years' : '--'
  const formattedKey = `${formattedFinancingType} ${formattedHasBattery} ${formattedDuration}`

  return {
    formatted_financing_type: formattedFinancingType,
    formatted_battery: formattedHasBattery,
    formatted_duration: formattedDuration,
    formatted_key: formattedKey,
  }
}

const parseFormattedConfig = (formattedName: string): TsInterface_KeyConfigProps => {
  let parsedParams: TsInterface_KeyConfigProps = {
    financing_type: '',
    financing_duration: 0,
    battery: false,
  }

  // get the key attributes from the parsed string
  const splitString = formattedName.split(' ')
  parsedParams['financing_type'] = splitString[0].toLowerCase()
  parsedParams['battery'] = formattedName.includes('No Battery') ? false : true
  if (parsedParams['financing_type'] !== 'cash') {
    parsedParams['financing_duration'] = formattedName.includes('25 years') ? 25 : 12.5
  } else {
    parsedParams['financing_duration'] = 0
  }

  return parsedParams
}

const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
  // Nothing
}

const returnMapIcons = (iconKey: string): TsInterface_MapIconObject => {
  let icon: TsInterface_MapIconObject = {
    path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
    scale: 0.05,
    anchor: new google.maps.Point(400, 800),
  }
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
        scale: 1,
        anchor: new google.maps.Point(20, 48),
      }
      break
    case 'circle':
      icon = {
        path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
        scale: 0.6,
        anchor: new google.maps.Point(14, 14),
      }
      break
    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }

  return icon
}

const isTokenExpired = (token: string): boolean => {
  try {
    // decode the token
    const decoded = jwtDecode(token)

    // check if the token has an expiration date
    if (!decoded || !decoded.exp) {
      return true // no expiration information, treat as expired
    }

    // get the current time in seconds since Unix epoch
    const currentTime = Math.floor(Date.now() / 1000)

    // check if the token is expired
    return decoded.exp < currentTime
  } catch (error) {
    // if decoding fails, treat the token as expired
    return true
  }
}

export const updateOpportunityInDatabase = (
  clientKey: string,
  clientKeySetter: any,
  errorDialogSetter: any,
  opportunityKey: string,
  updatedOpportunity: TsInterface_SalesOpportunity,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    getClientKey(clientKey, clientKeySetter)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, opportunityKey), {
          ...updatedOpportunity,
        })
          .then((res_DSMD) => {
            resolve({ success: true, error: {} })
          })
          .catch((rej_DSMD) => {
            errorDialogSetter({ display: true, error: rej_DSMD.error })
            reject(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        errorDialogSetter({ display: true, error: rej_GCK.error })
        reject(rej_GCK)
      })
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const opportunityKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_opportunity, us_setOpportunity] = useState<TsInterface_SalesOpportunity>({})
  const [us_opportunityLoaded, us_setOpportunityLoaded] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const un_routerNavigation = useNavigate()
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_drawerState, us_setDrawerState] = useState({
    top: false,
    bottom: false,
    left: false,
    right: false,
  })
  const ur_mapRef = useRef(null)
  const [us_showSiteDetailsOptions, us_setShowSiteDetailsOptions] = useState<boolean>(false)
  const [us_mapCenter, us_setMapCenter] = useState<TsInterface_Coordinates>({ location_latitude: 0, location_longitude: 0 })
  const [us_mapCenterLoaded, us_setMapCenterLoaded] = useState<boolean>(false)
  const [us_defaultSystemConfigs, us_setDefaultSystemConfigs] = useState({})
  const [us_databaseConfigs, us_setDatabaseConfigs] = useState({})
  const [us_databaseConfigsLoaded, us_setDatabaseConfigsLoaded] = useState<boolean>(false)
  const [us_selectedConfigLeft, us_setSelectedConfigLeft] = useState({})
  const [us_selectedConfigCenter, us_setSelectedConfigCenter] = useState({})
  const [us_selectedConfigRight, us_setSelectedConfigRight] = useState({})
  const [us_selectedConfigKeys, us_setSelectedConfigKeys] = useState<TsInterface_SelectedConfigKeys>({})
  const [us_formSubmitted, us_setFormSubmitted] = useState<boolean>(false)
  const [us_dropdownConfigs, us_setDropdownConfigs] = useState<TsInterface_ConfigBundle>({})
  const [us_selectedConfigsLoaded, us_setSelectedConfigsLoaded] = useState<boolean>(false)
  const [us_googlePanelCount, us_setGooglePanelCount] = useState<number>(3)
  const [us_googleAnnualEnergyOutput, us_setGoogleAnnualEnergyOutput] = useState<number>(0)
  const [us_panelCapacity, us_setPanelCapacity] = useState<number>(410)
  const [us_monthlyBill, us_setMonthlyBill] = useState<number>(20)
  const [us_recommendedPanelCount, us_setRecommendedPanelCount] = useState<number>(0)
  const [us_hideEstimateSection, us_setHideEstimateSection] = useState<boolean>(true)
  const [us_lightreachBatteryIncluded, us_setLightreachBatteryIncluded] = useState<boolean>(false)
  const [us_lightreachEnergyProduction, us_setLightreachEnergyProduction] = useState<boolean>(false)
  const [us_lighreachRatesLoaded, us_setLightreachRatesLoaded] = useState<boolean>(false)
  const [us_lightreachTokenLoaded, us_setLightreachTokenLoaded] = useState<boolean>(false)
  const [us_showLightreachMonthlyPayments, us_setShowLightreachMonthlyPayments] = useState<boolean>(false)
  const [us_lightreachVisibleProductIds, us_setLightreachVisibleProductIds] = useState<string[]>([])
  const [us_lightreachLseNameChanged, us_setLightreachLseNameChanged] = useState<boolean>(false)
  // const [us_eagleViewData, us_setEagleViewData] = useState<>()
  const [us_eagleViewDataLoaded, us_setEagleViewDataLoaded] = useState<boolean>(false)

  // { sort-end } - hook

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunity', false) as string
  }, [])

  // used to update the adjustable component values to match the new state whenever a new option is selected
  useEffect(() => {
    if (!us_selectedConfigsLoaded && us_formSubmitted) {
      us_setSelectedConfigLeft((prev: TsInterface_SystemConfig) => {
        return {
          ...getProp(us_dropdownConfigs, getProp(prev, 'key', ''), {}),
        }
      })
      us_setSelectedConfigCenter((prev: TsInterface_SystemConfig) => {
        return {
          ...getProp(us_dropdownConfigs, getProp(prev, 'key', ''), {}),
        }
      })
      us_setSelectedConfigRight((prev: TsInterface_SystemConfig) => {
        return {
          ...getProp(us_dropdownConfigs, getProp(prev, 'key', ''), {}),
        }
      })
      us_setSelectedConfigsLoaded(true)
    }
  }, [us_selectedConfigsLoaded])

  // step 1: load the opportunity data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_SalesOpportunity) => {
      us_setFormSubmitted(getProp(newData, 'form_submitted', null))

      // set the latitude and longigtude for google maps
      let address = getProp(newData, 'address', '')
      if (address) {
        let lat = getProp(newData['coordinates'], 'location_latitude', 0)
        let lng = getProp(newData['coordinates'], 'location_longitude', 0)
        us_setMapCenter({ location_latitude: lat, location_longitude: lng })
        us_setMapCenterLoaded(true)
      }

      us_setOpportunity({ ...newData })
      us_setOpportunityLoaded(true)

      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, opportunityKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, opportunityKey])

  // step 2: load the database estimates
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_ConfigBundle) => {
      us_setDatabaseConfigs(newData)
      us_setDatabaseConfigsLoaded(true)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesEstimates_Collection(res_GCK.clientKey, opportunityKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [])

  // step 3: once the opportunity and the configs for the estimates are loaded after the form submit, populate the state
  useEffect(() => {
    if (us_opportunityLoaded && us_databaseConfigsLoaded && us_formSubmitted) {
      const defaultEstimates = generateDefaultSystemConfigs(us_opportunity)

      //set the default system configs
      us_setDefaultSystemConfigs(defaultEstimates)

      let visibleEstimates: TsInterface_ConfigBundle = {}
      let orderedConfigKeys: TsInterface_SelectedConfigKeys = {}
      let dropdownEstimates: TsInterface_ConfigBundle = {}

      if (us_opportunity.initial_estimates_populated) {
        // 2. loop through database estimates and determine which templates they would follow
        // FIXME: what about edge case where we compare two plans with identical key attributes?
        // O(n^2), can potentially improve this
        for (const defaultEntry of Object.entries(defaultEstimates)) {
          const defaultKey = defaultEntry[0]
          const defaultConfig = defaultEntry[1] as object
          let match = false
          for (const databaseEntry of Object.entries(us_databaseConfigs)) {
            const databaseKey = databaseEntry[0]
            const databaseConfig = databaseEntry[1] as object

            // 3. merge database estimates with default estimates, excluding default estimates where there is a db match with identical key attributes
            // compare by key attributes: financing type, battery, and financing duration
            if (
              getProp(defaultConfig, 'financing_type', 'default') === getProp(databaseConfig, 'financing_type', 'database') &&
              getProp(defaultConfig, 'battery', 'default') === getProp(databaseConfig, 'battery', 'database')
            ) {
              // if type cash, add the database one
              if (getProp(databaseConfig, 'financing_type', 'database') === 'cash') {
                dropdownEstimates[databaseKey] = { ...databaseConfig }
                match = true
                break
              }
              // if a type of financing, compare duration as well
              if (getProp(defaultConfig, 'financing_duration', 'default') === getProp(databaseConfig, 'financing_duration', 'database')) {
                dropdownEstimates[databaseKey] = { ...databaseConfig }
                match = true
                break
              }
            }
          }
          if (!match) {
            // if there is no matching db config, then insert the default config
            dropdownEstimates[defaultKey] = { ...defaultConfig }
          }
        }
        // set the drop down system estimates
        us_setDropdownConfigs({ ...dropdownEstimates })

        // set the 3 visible estimates in the selects
        let visibleEstimateKeys = us_opportunity['visible_estimates']

        visibleEstimates['position_1'] = dropdownEstimates[getProp(visibleEstimateKeys, 'position_1', {})]
        visibleEstimates['position_2'] = dropdownEstimates[getProp(visibleEstimateKeys, 'position_2', {})]
        visibleEstimates['position_3'] = dropdownEstimates[getProp(visibleEstimateKeys, 'position_3', {})]

        orderedConfigKeys = {
          position_1: getProp(visibleEstimates['position_1'], 'key', 'None'),
          position_2: getProp(visibleEstimates['position_2'], 'key', 'None'),
          position_3: getProp(visibleEstimates['position_3'], 'key', 'None'),
        }
      } else {
        // set the drop down system configs (because there are only default configs created right now)
        us_setDropdownConfigs({ ...defaultEstimates })
        dropdownEstimates = { ...defaultEstimates }

        // set the default visible/selected entries to be cash no batt, lease no batt 25, and loan no batt 25
        for (const entry of Object.entries(defaultEstimates)) {
          const estimate = entry[1]
          if (estimate['financing_type'] === 'cash' && estimate['battery'] === false) {
            visibleEstimates['position_1'] = estimate
          }
          if (estimate['financing_type'] === 'lease' && estimate['battery'] === false && estimate['financing_duration'] === 25) {
            visibleEstimates['position_2'] = estimate
          }
          if (estimate['financing_type'] === 'loan' && estimate['battery'] === false && estimate['financing_duration'] === 25) {
            visibleEstimates['position_3'] = estimate
          }
        }

        // set the ordered keys of the visible estimates so that we can keep track of them in the state and db
        orderedConfigKeys = {
          position_1: visibleEstimates['position_1'].key,
          position_2: visibleEstimates['position_2'].key,
          position_3: visibleEstimates['position_3'].key,
        }
      }

      // set each of the selected configs and their ordered keys in the state
      us_setSelectedConfigLeft(visibleEstimates['position_1'])
      us_setSelectedConfigCenter(visibleEstimates['position_2'])
      us_setSelectedConfigRight(visibleEstimates['position_3'])
      us_setSelectedConfigKeys(orderedConfigKeys)
      us_setSelectedConfigsLoaded(true)

      // set the initial estimate boolean to true and populate visible estimates
      us_setOpportunity((prevState) => {
        return { ...prevState, initial_estimates_populated: true, visible_estimates: orderedConfigKeys }
      })

      // now batch update the DB
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, opportunityKey),
              data: { ...us_opportunity, initial_estimates_populated: true, form_submitted: true, visible_estimates: orderedConfigKeys },
            },
          ]

          // add initial estimates to the batch update as individual docs
          Object.entries(dropdownEstimates).map((estimate) => {
            const estimateKey = estimate[0]
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_SalesEstimate_Document(res_GCK.clientKey, opportunityKey, estimateKey),
              data: estimate[1],
            })
          })

          DatabaseBatchUpdate(updateArray).catch((rej_DBU) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }, [us_formSubmitted])

  // get the eagleview data for the given opportunity
  // useEffect(() => {
  //   if (uc_RootData_ClientKey) {
  //     getEagleViewAccessToken()
  //       .then((res: any) => {
  //         const eagleViewToken = res['access_token']

  //         // place a series of orders at the same address at different TSRF values and use that to calculate the number of panels
  //         const minProductionRatioValues = [0, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7]
  //         let productionRatioConfigs: TsInterface_ProductionRatioProspectBundle = {}

  //         // get the configurations at each production ratio
  //         for (const pr of minProductionRatioValues) {
  //           placeEagleViewOrder(eagleViewToken, pr).then((res: any) => {
  //             getEagleViewProspectData(
  //               res.data['tracking_id'],
  //               PROSPECT_PRODUCT_ID,
  //               eagleViewToken,
  //               us_opportunity,
  //               uc_RootData_ClientKey,
  //               uc_setRootData_ClientKey,
  //               uc_setUserInterface_ErrorDialogDisplay,
  //               opportunityKey,
  //             )
  //               .then((res: any) => {
  //                 productionRatioConfigs[pr.toString()] = res.data
  //               })
  //               .catch((error) => {
  //                 uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
  //               })
  //           })
  //         }
  //         // update the state with the new access token and the new eagleview data
  //         us_setOpportunity({ ...us_opportunity, ev_access_token: eagleViewToken, ev_prospect_data: productionRatioConfigs })
  //         us_setEagleViewDataLoaded(true)
  //       })
  //       .catch((error) => {
  //         uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
  //       })
  //   }
  // }, [us_opportunityLoaded])

  // get the lightreach data for the given opportunity
  useEffect(() => {
    if (uc_RootData_ClientKey) {
      // set the lightreach token in state immediately
      let lightreachToken = getProp(us_opportunity, 'lightreach_access_token', '')

      if (!lightreachToken || isTokenExpired(lightreachToken)) {
        us_setOpportunity({ ...us_opportunity, lightreach_access_token: '' })
      }
      us_setLightreachTokenLoaded(true)
    }
  }, [us_opportunityLoaded])

  // JSX Generation
  const rJSX_AddressUtilityForm = (): JSX.Element => {
    let addressUtilityJSX = <></>
    if (us_opportunityLoaded) {
      addressUtilityJSX = (
        <Box>
          <Form
            formAdditionalData={{}}
            formData={{
              address: getProp(us_opportunity, 'address', ''),
              roof_type: getProp(us_opportunity, 'roof_type', ''),
              utility_bill: getProp(us_opportunity, 'utility_bill', ''),
              utility_company: getProp(us_opportunity, 'utility_company', ''),
              utility_consumption: getProp(us_opportunity, 'utility_consumption', ''),
            }}
            formInputs={formInputs_HomeInfo}
            formOnChange={(
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {
              /* Nothing */
            }}
            formSettings={{ submit_button_alignment: 'center', submit_button_hide: us_formSubmitted }}
            formSubmission={(
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let address = getProp(formSubmittedData, 'address', '')
                    let lat = 0
                    let lng = 0
                    getCoordinatesFromAddress(address)
                      .then((res_GCFA: any) => {
                        lat = res_GCFA['coordinates']['latitude']
                        lng = res_GCFA['coordinates']['longitude']
                        us_setMapCenter({ location_latitude: lat, location_longitude: lng })
                        us_setFormSubmitted(true)
                        DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, opportunityKey), {
                          ...formSubmittedData,
                          initial_estimates_populated: true,
                          form_submitted: true,
                          coordinates: { location_latitude: lat, location_longitude: lng },
                        })
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCFA: any) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCFA.error })
                        reject(rej_GCFA)
                      })
                  })
                  .catch((rej_GCK) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    return addressUtilityJSX
  }

  // used to format the displayed menu item in the select component
  const rJSX_CustomSelectRender = (config: TsInterface_FormattedConfig) => {
    if (Object.keys(config).length === 0) {
      return (
        <Box>
          <em>None</em>
        </Box>
      )
    }
    return (
      <Box>
        <Typography>{getProp(config, 'formatted_financing_type', 'None')}</Typography>
        <Typography>{getProp(config, 'formatted_battery', 'None')}</Typography>
        <Typography>{getProp(config, 'formatted_duration', 'None')}</Typography>
        <Typography>$100.00</Typography>
      </Box>
    )
  }

  const rJSX_SelectOptions = (
    disappearOnShrink: boolean,
    alignment: string,
    selectedConfig: TsInterface_SystemConfig = {},
    selectedConfigSetter: React.Dispatch<React.SetStateAction<TsInterface_SystemConfig>>,
  ) => {
    const display = disappearOnShrink ? 'none' : 'flex'
    let configSetter: React.Dispatch<React.SetStateAction<TsInterface_SystemConfig>> = selectedConfigSetter

    let selectMenuJSX = (
      <Grid2
        xs={6}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        sx={{
          backgroundColor: '#191e21',
          display: { xs: display, sm: display, md: 'flex', lg: 'flex', xl: 'flex' },
          justifyContent: { xs: 'center', sm: 'center', md: alignment, lg: alignment, xl: alignment },
        }}
        container
      >
        {us_selectedConfigsLoaded ? (
          <Select
            label="Configuration"
            variant="filled"
            value={formatSelectConfig(selectedConfig)['formatted_key']}
            className="bp_thin_select_input"
            sx={{ width: { xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' } }}
            onChange={(e) => {
              us_setSelectedConfigsLoaded(false)
              // parse the financing type, battery boolean, and financing duration from the formatted name
              const parsedConfigElements: TsInterface_KeyConfigProps = parseFormattedConfig(e.target.value as string)

              // find the matching config using the parsedConfigElements attributes and set the state
              const matchingConfig: TsInterface_SystemConfig = getMatchingConfig(parsedConfigElements, us_dropdownConfigs)

              // set the state to show the new visible config
              let prevKey = selectedConfig.key
              configSetter({ ...matchingConfig })

              // replace the visible key and upload the new visible keys to the db
              let selectedKeys = { ...us_selectedConfigKeys }

              // replace the previous key with the new one
              if (selectedKeys['position_1'] === prevKey) {
                selectedKeys['position_1'] = matchingConfig.key
              } else if (selectedKeys['position_2'] === prevKey) {
                selectedKeys['position_2'] = matchingConfig.key
              } else if (selectedKeys['position_3'] === prevKey) {
                selectedKeys['position_3'] = matchingConfig.key
              }

              // update the state
              us_setSelectedConfigKeys({ ...selectedKeys })

              // update the visible keys in the db
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, opportunityKey), {
                    visible_estimates: { ...selectedKeys },
                  })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }}
            renderValue={(value: string) => {
              if (value.toLowerCase() === 'none') {
                return (
                  <>
                    <em>None</em>
                  </>
                )
              }
              const formattedObj = formatSelectConfig(selectedConfig)
              return rJSX_CustomSelectRender(formattedObj)
            }}
          >
            {Object.values(us_dropdownConfigs).map((c: any) => {
              // do some formatting based on the key attributes of each select item
              const formatObj = formatSelectConfig(c)
              if (formatObj['formatted_key'].toLowerCase() === 'none') {
                return (
                  <MenuItem
                    value={formatObj['formatted_key']}
                    key={formatObj['formatted_key']}
                  >
                    <Box>
                      <em>None</em>
                    </Box>
                  </MenuItem>
                )
              }
              return (
                <MenuItem
                  value={formatObj['formatted_key']}
                  key={formatObj['formatted_key']}
                >
                  <Box>
                    <Typography>{formatObj['formatted_financing_type']}</Typography>
                    <Typography>{formatObj['formatted_battery']}</Typography>
                    <Typography>{formatObj['formatted_duration']}</Typography>
                    <Typography>$100.00</Typography>
                  </Box>
                </MenuItem>
              )
            })}
          </Select>
        ) : (
          rJSX_LoadingBox('100px', '100%')
        )}
      </Grid2>
    )
    return selectMenuJSX
  }

  const rJSX_CompareSectionBox = (disappearOnShrink: boolean, jsx: JSX.Element, alignment: string) => {
    const display = disappearOnShrink ? 'none' : 'flex'
    let infoJSX = (
      <Grid2
        className="tw-flex-col tw-mt-5 tw-items-center"
        xs={6}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        sx={{ display: { xs: display, sm: display, md: 'flex', lg: 'flex', xl: 'flex' } }}
        container
      >
        {jsx}
      </Grid2>
    )

    return infoJSX
  }

  const rJSX_CompareSection = (
    header: string,
    left: JSX.Element,
    center: JSX.Element,
    right: JSX.Element,
    spanningJSX: JSX.Element = <Box></Box>,
  ): JSX.Element => {
    let sectionJSX = (
      <Box
        className="tw-mt-10 tw-flex-col tw-mx-auto"
        sx={{ width: '100%', marginX: 'auto' }}
      >
        <Box sx={{ width: '73%', marginX: 'auto' }}>
          <Typography variant="h3">{header}</Typography>
          <hr />
        </Box>
        <Box sx={{ width: '80%', marginX: 'auto' }}>
          <Grid2
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                {rJSX_CompareSectionBox(false, left, 'center')}
                {rJSX_CompareSectionBox(false, center, 'center')}
                {rJSX_CompareSectionBox(true, right, 'center')}
              </Box>
              {spanningJSX}
            </Box>
          </Grid2>
        </Box>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_ComparisonPage = (isCustomerFacing: boolean): JSX.Element => {
    let mapMarkers: TsInterface_MapMarkers = {}
    mapMarkers['home'] = {
      position: {
        lat: us_mapCenter.location_latitude,
        lng: us_mapCenter.location_longitude,
      },
      icon: {
        path: returnMapIcons('pin')['path'],
        fillColor: themeVariables.warning_dark,
        fillOpacity: 1,
        strokeWeight: 0.5,
        strokeColor: 'white',
        rotation: 0,
        scale: returnMapIcons('pin')['scale'],
        anchor: returnMapIcons('pin')['anchor'],
      },
      title: 'Home',
      label: '',
    }

    const showMoreSiteDetailsButton = (
      <Button
        onClick={() => {
          us_setShowSiteDetailsOptions(!us_showSiteDetailsOptions)
        }}
      >
        {us_showSiteDetailsOptions ? 'Show Less' : 'Show More'}
      </Button>
    )

    let mapBasicSettings: TsInterface_MapSettings = {
      center_lat: us_mapCenter.location_latitude,
      center_lng: us_mapCenter.location_longitude,
      height: '400px',
      render_clusters: false,
      render_heatmap: false,
      render_markers: true,
      width: '600px',
      zoom: 20,
      map_type_id: 'satellite',
    }

    let configBundle = {
      left: {
        config: us_selectedConfigLeft,
        setter: us_setSelectedConfigLeft,
        db_info: {
          client_key: uc_RootData_ClientKey,
          client_key_setter: uc_setRootData_ClientKey,
          opportunity_key: opportunityKey,
          error_display: uc_setUserInterface_ErrorDialogDisplay,
        },
        dropdown_setter: us_setDropdownConfigs,
        dropdown_state: us_dropdownConfigs,
        visible_keys_setter: us_setSelectedConfigKeys,
        opportunity_setter: us_setOpportunity,
        selected_configs_loaded_setter: us_setSelectedConfigsLoaded,
      },
      center: {
        config: us_selectedConfigCenter,
        setter: us_setSelectedConfigCenter,
        db_info: {
          client_key: uc_RootData_ClientKey,
          client_key_setter: uc_setRootData_ClientKey,
          opportunity_key: opportunityKey,
          error_display: uc_setUserInterface_ErrorDialogDisplay,
        },
        dropdown_setter: us_setDropdownConfigs,
        dropdown_state: us_dropdownConfigs,
        visible_keys_setter: us_setSelectedConfigKeys,
        opportunity_setter: us_setOpportunity,
        selected_configs_loaded_setter: us_setSelectedConfigsLoaded,
      },
      right: {
        config: us_selectedConfigRight,
        setter: us_setSelectedConfigRight,
        db_info: {
          client_key: uc_RootData_ClientKey,
          client_key_setter: uc_setRootData_ClientKey,
          opportunity_key: opportunityKey,
          error_display: uc_setUserInterface_ErrorDialogDisplay,
        },
        dropdown_setter: us_setDropdownConfigs,
        dropdown_state: us_dropdownConfigs,
        visible_keys_setter: us_setSelectedConfigKeys,
        opportunity_setter: us_setOpportunity,
        selected_configs_loaded_setter: us_setSelectedConfigsLoaded,
      },
    }

    let formJSX = (
      <Box>
        {/* <Box> */}
        {/* FIXME: need to get the max number of panels */}
        {/* {us_eagleViewDataLoaded && us_opportunityLoaded && rJSX_EagleViewAdjuster(us_opportunity, 100, {})} */}
        {/* </Box> */}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {us_mapCenterLoaded ? (
            <MapBasic
              ref={ur_mapRef}
              mapSettings={mapBasicSettings}
              mapMarkers={mapMarkers}
              mapOnClick={mapBasicOnClick}
            />
          ) : (
            rJSX_LoadingBox('400px', '100%')
          )}
        </Box>
        <Grid2
          className="tw-mt-5 tw-sticky tw-top-16"
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ zIndex: 10000 }}
        >
          {rJSX_SelectOptions(false, 'end', us_selectedConfigLeft, us_setSelectedConfigLeft)}
          {rJSX_SelectOptions(false, 'center', us_selectedConfigCenter, us_setSelectedConfigCenter)}
          {rJSX_SelectOptions(true, 'start', us_selectedConfigRight, us_setSelectedConfigRight)}
        </Grid2>
        {/* System Size */}
        {rJSX_CompareSection(
          'System Size',
          rJSX_SystemSizeSection(configBundle['left'], us_selectedConfigsLoaded),
          rJSX_SystemSizeSection(configBundle['center'], us_selectedConfigsLoaded),
          rJSX_SystemSizeSection(configBundle['right'], us_selectedConfigsLoaded),
        )}
        {/* Batteries */}
        {rJSX_CompareSection(
          'Equipment',
          rJSX_EquipmentSection(batteryBrand, inverterBrand, moduleBrand, configBundle['left'], us_selectedConfigsLoaded),
          rJSX_EquipmentSection(batteryBrand, inverterBrand, moduleBrand, configBundle['center'], us_selectedConfigsLoaded),
          rJSX_EquipmentSection(batteryBrand, inverterBrand, moduleBrand, configBundle['right'], us_selectedConfigsLoaded),
        )}
        {/*Warranty*/}
        {rJSX_CompareSection(
          'Warranties',
          rJSX_WarrantySection(configBundle['left'], us_selectedConfigsLoaded),
          rJSX_WarrantySection(configBundle['center'], us_selectedConfigsLoaded),
          rJSX_WarrantySection(configBundle['right'], us_selectedConfigsLoaded),
        )}
        {/* Financing */}
        {rJSX_CompareSection(
          'Financing',
          rJSX_FinancingSection(configBundle['left'], us_selectedConfigsLoaded),
          rJSX_FinancingSection(configBundle['center'], us_selectedConfigsLoaded),
          rJSX_FinancingSection(configBundle['right'], us_selectedConfigsLoaded),
        )}
        {/* Site Details */}
        {rJSX_CompareSection(
          'Site Details',
          rJSX_SiteDetailsSection(us_showSiteDetailsOptions, configBundle['left'], us_selectedConfigsLoaded),
          rJSX_SiteDetailsSection(us_showSiteDetailsOptions, configBundle['center'], us_selectedConfigsLoaded),
          rJSX_SiteDetailsSection(us_showSiteDetailsOptions, configBundle['right'], us_selectedConfigsLoaded),
          showMoreSiteDetailsButton,
        )}
        <Box sx={{ backgroundColor: '#191e21', width: '100%', marginTop: 5, marginX: 'auto', position: 'sticky', bottom: 0 }}>{rJSX_ToggleDrawer()}</Box>
      </Box>
    )
    return formJSX
  }

  const rJSX_BackButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all opportunities')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url())
            }}
            disableElevation
            startIcon={<Icon icon="chevron-left" />}
            className="tw-mr-2 bp_icon_only_button"
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url())
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all opportunities')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ToggleDrawer = () => {
    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
        return
      }
      us_setDrawerState({ ...us_drawerState, [anchor]: open })
    }

    const salesSlidersLeft = [
      { title: 'Solar Rate', min: 0, max: 100, step: 1 },
      { title: 'EPC', min: 0, max: 100, step: 1 },
    ]

    const salesSlidersRight = [
      { title: 'Commission', min: 0, max: 100, step: 1 },
      { title: 'Monthly Payment', min: 0, max: 100, step: 1 },
    ]

    const splitSliders = [salesSlidersLeft, salesSlidersRight]

    const list = (anchor: Anchor) => (
      <Grid2
        className="tw-mx-auto"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid2
          xs={12}
          xl={12}
          sx={{ justifyContent: 'center' }}
          container
        >
          <Typography
            variant="h5"
            sx={{ marginTop: '10px', marginBottom: '7px' }}
          >
            Advanced Tools
          </Typography>
        </Grid2>
        <Grid2
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <List sx={{ width: '100%', paddingY: 0 }}>
            {splitSliders[0].map((s) => (
              <ListItem
                key={s.title}
                disablePadding
                sx={{ marginBottom: 1, marginX: 'auto', width: '80%' }}
              >
                {rJSX_Slider(s.title, s.min, s.max, s.step, 0, {}, '', true)}
              </ListItem>
            ))}
          </List>
        </Grid2>
        <Grid2
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <List sx={{ width: '100%', paddingY: 0 }}>
            {splitSliders[1].map((s) => (
              <ListItem
                key={s.title}
                disablePadding
                sx={{ marginBottom: 1, marginX: 'auto', width: '80%' }}
              >
                {rJSX_Slider(s.title, s.min, s.max, s.step, 0, {}, '', true)}
              </ListItem>
            ))}
          </List>
        </Grid2>
      </Grid2>
    )

    const anchor: Anchor = 'bottom'

    return (
      <Box sx={{ width: '100%' }}>
        {
          <Box
            key={anchor}
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button onClick={toggleDrawer(anchor, true)}>Advanced</Button>
            <Drawer
              sx={{ zIndex: 10001 }}
              anchor={anchor}
              open={us_drawerState[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Box>
        }
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunity')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.SalesOpportunityViewPage.url(opportunityKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Tools', false) as string,
              }}
              tabs={[
                {
                  tabHeader: 'Admin View',
                  tabUrlKey: 'admin-view',
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 270 }],
                  tabContent: (
                    <Box>
                      <Box>{rJSX_AdminViewPage({ isAdminView: true })}</Box>
                    </Box>
                  ),
                },
                {
                  tabHeader: 'Address/Utility Form',
                  tabUrlKey: 'address-utility-form',
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 270 }],
                  tabContent: (
                    <Box>
                      <Box sx={{ width: '40%', marginX: 'auto' }}>{rJSX_AddressUtilityForm()}</Box>
                    </Box>
                  ),
                },
                {
                  tabHeader: 'Compare Plans',
                  tabUrlKey: 'compare',
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 270 }],
                  tabContent: <Box className="tw-h-full">{rJSX_ComparisonPage(false)}</Box>,
                },
                // {
                //   tabHeader: 'Google Solar Demo',
                //   tabUrlKey: 'google-solar',
                //   tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 270 }],
                //   tabContent: (
                //     <Box className="tw-h-full">
                //       {us_opportunityLoaded
                //         ? rJSX_EstimatePage({
                //             opportunity_state: us_opportunity,
                //             set_opportunity_state: us_setOpportunity,
                //             auth_info: { client_key: uc_RootData_ClientKey, set_client_key: uc_setRootData_ClientKey },
                //             opportunity_key: opportunityKey,
                //             set_error_display: uc_setUserInterface_ErrorDialogDisplay,
                //             panel_count_state: us_googlePanelCount,
                //             panel_count_setter: us_setGooglePanelCount,
                //             energy_output_state: us_googleAnnualEnergyOutput,
                //             energy_output_setter: us_setGoogleAnnualEnergyOutput,
                //             panel_capacity_state: us_panelCapacity,
                //             panel_capacity_setter: us_setPanelCapacity,
                //             monthly_bill_state: us_monthlyBill,
                //             monthly_bill_setter: us_setMonthlyBill,
                //             recommended_panel_count_state: us_recommendedPanelCount,
                //             recommended_panel_count_setter: us_setRecommendedPanelCount,
                //             hide_section_state: us_hideEstimateSection,
                //             hide_section_setter: us_setHideEstimateSection,
                //           })
                //         : rJSX_LoadingBox('100px', '100%')}
                //     </Box>
                //   ),
                // },
                // {
                //   tabHeader: 'Lightreach',
                //   tabUrlKey: 'lightreach',
                //   tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 270 }],
                //   tabContent: (
                //     <Box className="tw-h-full">
                //       {us_opportunityLoaded
                //         ? rJSX_LightReachForm({
                //             opportunity_state: us_opportunity,
                //             opportunity_setter: us_setOpportunity,
                //             battery_state: us_lightreachBatteryIncluded,
                //             battery_setter: us_setLightreachBatteryIncluded,
                //             energy_state: us_lightreachEnergyProduction,
                //             energy_setter: us_setLightreachEnergyProduction,
                //             client_key: uc_RootData_ClientKey,
                //             client_key_setter: uc_setRootData_ClientKey,
                //             rates_loaded_state: us_lighreachRatesLoaded,
                //             rates_loaded_setter: us_setLightreachRatesLoaded,
                //             show_payments_state: us_showLightreachMonthlyPayments,
                //             show_payments_setter: us_setShowLightreachMonthlyPayments,
                //             visible_product_ids_state: us_lightreachVisibleProductIds,
                //             visible_product_ids_setter: us_setLightreachVisibleProductIds,
                //             form_dialog_display: uc_setUserInterface_FormDialogDisplay,
                //             lse_name_changed_state: us_lightreachLseNameChanged,
                //             lse_name_changed_setter: us_setLightreachLseNameChanged,
                //             db_info: {
                //               client_key: uc_RootData_ClientKey,
                //               client_key_setter: uc_setRootData_ClientKey,
                //               opportunity_key: opportunityKey,
                //               error_display: uc_setUserInterface_ErrorDialogDisplay,
                //             },
                //             opportunity_key: opportunityKey,
                //           })
                //         : rJSX_LoadingBox('100px', '100%')}
                //     </Box>
                //   ),
                // },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
