/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material/'
import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { dynamicSort, getProp, objectToArray, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
// @ts-expect-error - TODO: reason for error
import logoSrc2 from '../../images/logos/logo_temp.png'
import { getStartAndEndOfWeek } from '../payroll/data/payroll_calculations'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OrderSkusDialog {
  clientKey: string
  order: TsInterface_UnspecifiedObject
  skus: TsInterface_UnspecifiedObject
  supplier: TsInterface_UnspecifiedObject
  project: any
}

///////////////////////////////
// Variables
///////////////////////////////

// PDF Generation - Register Font
Font.register({ family: 'Roboto', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf' })
// Font.register({ family: 'Font Awesome', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/fontawesome-webfont.ttf' })
Font.register({ family: 'Font Awesome', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/fontawesome-webfont.ttf' })

// Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 40,
  },
  header: {
    fontSize: 14,
    marginBottom: 4,
    fontFamily: 'Roboto',
  },
  paragraph: {
    paddingLeft: 8,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  row: {
    flexDirection: 'row',
  },
  poNumberCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  poNumberCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  checkCell: {
    fontSize: 11,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderTopWidth: 0,
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCell: {
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  skuCell: {
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameCell: {
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  checkCellHeader: {
    fontSize: 14,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  skuCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  nameCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  opacity_40: {
    opacity: 0.4,
  },
})

///////////////////////////////
// Functions
///////////////////////////////

function getDatesBetween(startDate: string, endDate: string) {
  const dates = []
  let currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate).toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

// eslint-disable-next-line react/prop-types
const OrderSkusDialog: React.FC<TsInterface_OrderSkusDialog> = ({ order, skus, clientKey, supplier, project }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_updatingLockStatus, us_setUpdatingLockStatus] = useState<boolean>(false)
  const [us_updatingSkuStatusObject, us_setUpdatingSkuStatusObject] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Nothing
  }, [])

  // Functions
  const returnPDFDownloadDocument = (order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject) => {
    let addressLineJSX = <></>
    let deliveryNotesJSX = <></>
    if (getProp(order, 'delivery_type', 'pickup') === 'delivery') {
      // eslint-disable-next-line react/prop-types
      let address = project.location_address + ', ' + project.location_city + ', ' + project.location_state + ' ' + project.location_zip
      addressLineJSX = (
        <View style={styles.row}>
          <Text style={styles.pickupDateCell1}>{rLIB('Delivery Location')}:</Text>
          <Text style={styles.pickupDateCell2}>{address}</Text>
        </View>
      )
      deliveryNotesJSX = (
        <View style={styles.row}>
          <Text style={styles.pickupDateCell1}>{rLIB('Delivery Notes')}:</Text>
          <Text style={styles.pickupDateCell2}>{order.delivery_notes}</Text>
        </View>
      )
    }

    // Create PDF
    let qrCodePDFJSX = (
      <Document>
        <Page
          size="A4"
          style={styles.page}
        >
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('Location')}:</Text>
              <Text style={styles.poNumberCell2}>{supplier.name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('Job Code')}:</Text>
              <Text style={styles.poNumberCell2}>{order.associated_project_id_number}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('PO Number')}:</Text>
              <Text style={styles.poNumberCell2}>{order.po_number}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.requestorCell1}>{rLIB('Requestor')}:</Text>
              <Text style={styles.requestorCell2}>{order.associated_user_name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.pickupDateCell1}>{rLIB('Scheduled Date')}:</Text>
              <Text style={styles.pickupDateCell2}>{order.timestamp_scheduled_pickup_date_key}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.pickupDateCell1}>{rLIB('Pickup or Delivery')}:</Text>
              <Text style={styles.pickupDateCell2}>{capitalizeFirstLetterInString(getProp(order, 'delivery_type', 'pickup'))}</Text>
            </View>
            {addressLineJSX}
            {deliveryNotesJSX}
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.checkCellHeader}>{'\uf14a'}</Text>
              <Text style={styles.quantityCellHeader}>{'Qty'}</Text>
              <Text style={styles.skuCellHeader}>{'ID'}</Text>
              <Text style={styles.nameCellHeader}>{'Description'}</Text>
            </View>
            {objectToArray(skus)
              .sort(dynamicSort('associated_sku_unique_identifier', 'asc'))
              .map((skuItem, index) => (
                <View
                  key={index}
                  style={styles.row}
                >
                  <Text style={styles.checkCell}></Text>
                  <Text style={styles.quantityCell}>
                    {getProp(skuItem, 'associated_sku_pick_requested_quantity', 1) * getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
                  </Text>
                  <Text style={styles.skuCell}>
                    <Text>
                      {skuItem['associated_sku_unique_identifier']}
                      {'\n'}
                    </Text>
                    <Text style={styles.opacity_40}>{skuItem['associated_sku_manufacturer_unique_identifier']}</Text>
                  </Text>
                  <Text style={styles.nameCell}>{skuItem['associated_sku_name']}</Text>
                </View>
              ))}
          </View>
        </Page>
      </Document>
    )
    return qrCodePDFJSX
  }

  const updateSkuItemStatus = (skuItem: TsInterface_UnspecifiedObject, newStatus: string) => {
    if (us_updatingSkuStatusObject[skuItem.key] == null) {
      // Update UI
      us_setUpdatingSkuStatusObject((prevState) => {
        return { ...prevState, [skuItem.key]: newStatus }
      })
      // Update Status
      cloudFunctionUnauthenticatedRequests({
        function: 'updateSkuLineItemSupplierStatus',
        client_key: clientKey,
        line_item_key: skuItem.key,
        status: newStatus,
      })
        .then((res_CFUR) => {
          // Update UI
          skus[skuItem.key]['associated_supplier_inventory_status'] = newStatus
          us_setUpdatingSkuStatusObject((prevState) => {
            return { ...prevState, [skuItem.key]: null }
          })
          ur_forceRerender()
        })
        .catch((rej_CFUR) => {
          console.error(rej_CFUR)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to update SKU status'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-USOL-USIS-02',
            },
          })
          us_setUpdatingSkuStatusObject((prevState) => {
            return { ...prevState, [skuItem.key]: null }
          })
          ur_forceRerender()
        })
    }
  }

  // JSX Generation
  const rJSX_DownloadButtonProper = (order: TsInterface_UnspecifiedObject, disabled: boolean, loading: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disabled={disabled || order.status === 'new'}
        startIcon={
          loading ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
      >
        {rLIB('Download PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MaterialSearchResultSkuItemImage = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: '#FFF' }}
          className="tw-mr-2 tw-mt-2 tw-flex tw-items-center tw-justify-center"
        >
          <img
            className="tw-max-w-full tw-max-h-full tw-self-center"
            src={photoUrl}
            alt={photoUrl}
            loading="lazy"
            width={'80px'}
          />
        </Box>
      )
    } else {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: themeVariables.gray_800 }}
          className="tw-mr-2 tw-mt-2 tw-text-center"
        >
          <Box className="tw-pt-3">
            <Icon
              className="tw-opacity-20"
              sx={{ fontSize: '40px' }}
              icon="circle-question"
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-opacity-20"
          >
            {rLIB('No image')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_PDFDownloadButton = (order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <PDFDownloadLink
        document={returnPDFDownloadDocument(order, skus)}
        fileName={order.po_number + '.pdf'}
      >
        {({ blob, url, loading, error }) => (loading ? rJSX_DownloadButtonProper(order, true, loading) : rJSX_DownloadButtonProper(order, false, loading))}
      </PDFDownloadLink>
    )
    return buttonJSX
  }

  const rJSX_LockButton = (order: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <Button
        color="warning"
        variant="contained"
        className="tw-mr-2"
        disabled={us_updatingLockStatus || order.status === 'locked'}
        startIcon={
          us_updatingLockStatus ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="lock" />
          )
        }
        onClick={() => {
          us_setUpdatingLockStatus(true)
          cloudFunctionUnauthenticatedRequests({
            function: 'updateSupplierOrder',
            client_key: clientKey,
            order_key: order.key,
            prop: 'status',
            value: 'locked',
          })
            .then((res_CFUR) => {
              // Update UI
              order['status'] = 'locked'
              us_setUpdatingLockStatus(false)
              ur_forceRerender()
            })
            .catch((rej_CFUR) => {
              console.error(rej_CFUR)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Failed to update SKU status'),
                  details: rLIB('An unknown error occurred'),
                  code: 'ER-D-USOL-USIS-02',
                },
              })
              us_setUpdatingLockStatus(false)
              ur_forceRerender()
            })
        }}
      >
        {rLIB('Lock')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_InStockButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="badge-check"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'in_stock') {
      variant = 'filled'
      color = 'success'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'in_stock') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'in_stock')
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('In Stock')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const rJSX_OnOrderButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="clock"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'on_order') {
      variant = 'filled'
      color = 'warning'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'on_order') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'on_order')
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('On Order')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const rJSX_OutOfStockButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="triangle-exclamation"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'out_of_stock') {
      variant = 'filled'
      color = 'error'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'out_of_stock') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'out_of_stock')
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('Out of Stock')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const rJSX_DeliveryAddress = (): JSX.Element => {
    let addressJSX = <></>
    if (getProp(order, 'delivery_type', 'pickup') === 'delivery') {
      let address = project.location_address + ', ' + project.location_city + ', ' + project.location_state + ' ' + project.location_zip
      addressJSX = (
        <Box>
          <Box className="tw-mb-1">
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block"
            >
              {rLIB('Delivery Location')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block tw-opacity-40"
            >
              {address}
            </Typography>
          </Box>
          <Box className="tw-mb-1">
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block"
            >
              {rLIB('Delivery Notes')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block tw-opacity-40"
            >
              {order.delivery_notes}
            </Typography>
          </Box>
        </Box>
      )
    }
    return addressJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="clipboard-list-check" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{order.po_number}</Box>
              </Typography>
              <Box>
                {rJSX_LockButton(order)}
                {rJSX_PDFDownloadButton(order, skus)}
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4">
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Location')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_supplier_location_name', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Job Code')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_project_id_number', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('PO Number')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'po_number', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Requestor')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_user_name', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Scheduled Date')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'timestamp_scheduled_pickup_date_key', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Pickup or Delivery')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {capitalizeFirstLetterInString(getProp(order, 'delivery_type', 'pickup'))}
                </Typography>
              </Box>
              {rJSX_DeliveryAddress()}
            </Box>
            <Divider className="tw-mb-2" />
            <Box className="tw-p-4">
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Skus List')}:
                </Typography>
              </Box>
              {objectToArray(skus)
                .sort(dynamicSort('associated_sku_unique_identifier', 'asc'))
                .map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
                  <Stack
                    direction="row"
                    key={index}
                  >
                    {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'associated_sku_estimated_photo_url', null))}
                    <Box sx={{ width: 'calc( 100% - 88px )' }}>
                      <Stack
                        direction="row"
                        className="tw-justify-between"
                      >
                        <Box sx={{ width: 'calc( 100% - 100px )' }}>
                          <Box>
                            <Typography
                              variant="body1"
                              className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                            >
                              {getProp(skuItem, 'associated_sku_name', '')}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            className="tw-opacity-40"
                          >
                            {getProp(skuItem, 'associated_sku_unique_identifier', '')}
                          </Typography>
                          <Box>
                            {rLIB('Requested Quantity')}:
                            <Chip
                              className="tw-ml-2"
                              label={
                                <Typography variant="body1">
                                  {getProp(skuItem, 'associated_sku_pick_requested_quantity', 1) *
                                    getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
                                </Typography>
                              }
                            />
                            {rJSX_InStockButton(skuItem)}
                            {rJSX_OnOrderButton(skuItem)}
                            {rJSX_OutOfStockButton(skuItem)}
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

function getDayOfWeek(dateString: string) {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const date = new Date(dateString)
  const dayIndex = date.getDay()
  return daysOfWeek[dayIndex]
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const clientKey: string = params.ck as string
  const supplierKey: string = params.sk as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')
  const [us_loadingOrderDetails, us_setLoadingOrderDetails] = useState<boolean>(false)
  const [us_orderToLoad, us_setOrderToLoad] = useState<string>('loading')
  const [us_ordersList, us_setOrdersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ordersSortedData, us_setOrdersSortedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_selectedLocationFilter, us_setSelectedLocationFilter] = useState<string>('ALL LOCATIONS')
  const [us_supplier, us_setSupplier] = useState<TsInterface_UnspecifiedObject>({})
  const [us_supplierLocations, us_setSupplierLocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string | null>(null)
  const [us_weekStartDateKey, us_setWeekStartDateKey] = useState<string | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('All Orders', false) as string
  }, [])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    us_setWeekStartDateKey(returnFormattedDateKey(weekBoundingDates.startOfWeek))
    us_setWeekEndDateKey(returnFormattedDateKey(weekBoundingDates.endOfWeek))
    return () => {}
  }, [us_selectedDate])

  useEffect(() => {
    if (us_weekStartDateKey != null && us_weekEndDateKey != null) {
      let daysToRender = getDatesBetween(us_weekStartDateKey, us_weekEndDateKey)
      let ordersSortedData: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in daysToRender) {
        let loopDate = daysToRender[loopDateIndex]
        ordersSortedData[loopDate] = {
          date: loopDate,
          orders: {},
        }
        for (let loopOrderKey in us_ordersList) {
          let loopOrder = us_ordersList[loopOrderKey]
          if (loopOrder != null && loopOrder['timestamp_scheduled_pickup_date_key'] === loopDate) {
            if (us_selectedLocationFilter === 'ALL LOCATIONS' || loopOrder['associated_supplier_location_key'] === us_selectedLocationFilter) {
              ordersSortedData[loopDate]['orders'][loopOrderKey] = loopOrder
            }
          }
        }
      }
      us_setOrdersSortedData(ordersSortedData)
    }
  }, [us_ordersList, us_selectedLocationFilter, us_weekEndDateKey, us_weekStartDateKey])

  useEffect(() => {
    us_setDataLoadedStatus('loading')
    if (us_weekStartDateKey != null && us_weekEndDateKey != null) {
      cloudFunctionUnauthenticatedRequests({
        function: 'getSupplierOrdersForDateRange',
        client_key: clientKey,
        supplier_key: supplierKey,
        start_date_key: us_weekStartDateKey,
        end_date_key: us_weekEndDateKey,
      })
        .then((res_CFUR) => {
          us_setDataLoadedStatus('success')
          us_setOrdersList(getProp(getProp(res_CFUR, 'data', {}), 'orders', {}))
          us_setSupplier(getProp(getProp(res_CFUR, 'data', {}), 'supplier', {}))
          let supplierLocations: TsInterface_UnspecifiedObject = {}
          let allLocations = getProp(getProp(getProp(res_CFUR, 'data', {}), 'supplier', {}), 'locations', {})
          for (let loopLocationKey in allLocations) {
            let loopLocation = allLocations[loopLocationKey]
            if (loopLocation != null && loopLocation.status === 'active') {
              supplierLocations[loopLocationKey] = loopLocation
            }
          }
          us_setSupplierLocations(supplierLocations)
        })
        .catch((rej_CFUR) => {
          us_setDataLoadedStatus('error')
        })
    } else {
      us_setDataLoadedStatus('loading')
    }
  }, [clientKey, supplierKey, us_weekEndDateKey, us_weekStartDateKey])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const openOrderDialog = (order: TsInterface_UnspecifiedObject, orderKey: string) => {
    us_setLoadingOrderDetails(true)
    cloudFunctionUnauthenticatedRequests({
      function: 'getSupplierOrderDetails',
      client_key: clientKey,
      supplier_key: supplierKey,
      order_key: order.key,
    })
      .then((res_CFUR) => {
        us_setLoadingOrderDetails(false)
        let skus: TsInterface_UnspecifiedObject = getProp(getProp(res_CFUR, 'data', {}), 'skus', {})
        let project: TsInterface_UnspecifiedObject = getProp(getProp(res_CFUR, 'data', {}), 'project', {})
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <OrderSkusDialog
                order={order}
                skus={skus}
                clientKey={clientKey}
                supplier={us_supplier}
                project={project}
              />
            ),
            settings: {
              max_width: 'lg',
            },
          },
        })
      })
      .catch((rej_CFUR) => {
        us_setLoadingOrderDetails(false)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            message: rLIB('Failed to view order details'),
            details: rLIB('An unknown error occurred'),
            code: 'ER-D-USOL-OOD-02',
          },
        })
      })
  }

  // JSX Generation
  const rJSX_HeaderLocation = (): JSX.Element => {
    let headerJSX = <></>
    if (getProp(us_supplier, 'name', null) != null) {
      headerJSX = (
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.primary_main }}
        >
          {getProp(us_supplier, 'name', '')}
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let headerJSX = (
      <Box className="tw-text-center tw-w-full">
        <Box
          className="tw-cursor-pointer tw-m-auto"
          component="img"
          sx={{ width: 189, height: 54 }}
          alt="logo"
          src={logoSrc2}
        />
        <Typography variant="h5">
          {rLIB('Requested Orders')} {rJSX_HeaderLocation()}
        </Typography>
        <Divider className="tw-my-3" />
      </Box>
    )
    return headerJSX
  }

  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load order data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.white }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  const rJSX_DatePicker = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return buttonJSX
  }

  const rJSX_LocationFilterDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    dropdownJSX = (
      <Select
        autoWidth={true}
        onChange={(event, value) => {
          us_setSelectedLocationFilter(event.target.value)
          // changeIfConditionType(conditionalItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
        className="bp_thin_select_input"
        value={us_selectedLocationFilter || ''}
      >
        <MenuItem
          key={'ALL LOCATIONS'}
          value={'ALL LOCATIONS'}
        >
          {rLIB('All Locations')}
        </MenuItem>
        {objectToArray(us_supplierLocations)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
            >
              {option['name']}
            </MenuItem>
          ))}
      </Select>
    )

    return dropdownJSX
  }

  const rJSX_OrdersForDay = (dateOrders: TsInterface_UnspecifiedObject): JSX.Element => {
    let dateOrdersJSX = <></>
    if (objectToArray(dateOrders).length === 0) {
      dateOrdersJSX = (
        <Box className="tw-opacity-30 tw-mb-3 tw-ml-8">
          <Typography variant="h6">{rLIB('No Orders')}</Typography>
        </Box>
      )
    } else {
      dateOrdersJSX = (
        <Box className="tw-mb-3 tw-ml-8">
          {objectToArray(dateOrders).map((order: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Button
                disabled={us_loadingOrderDetails}
                color={order.status === 'new' ? 'primary' : 'warning'}
                variant="outlined"
                className="tw-my-1 tw-cursor-pointer"
                onClick={() => {
                  us_setOrderToLoad(order.key)
                  openOrderDialog(order, order.key)
                }}
                startIcon={
                  us_orderToLoad === order.key && us_loadingOrderDetails ? (
                    <Icon
                      icon="arrows-rotate"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="cart-shopping" />
                  )
                }
              >
                <Typography variant="h6">{order.po_number}</Typography>
              </Button>
            </Box>
          ))}
        </Box>
      )
    }
    return dateOrdersJSX
  }

  const rJSX_OrdersTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {objectToArray(us_ordersSortedData).map((date: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            <Typography variant="h6">
              {getDayOfWeek(date.date)} {date.date}
            </Typography>
            {rJSX_OrdersForDay(date.orders)}
          </Box>
        ))}
      </Box>
    )
    return tableJSX
  }

  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      contentJSX = (
        <Box className="tw-p-4">
          <Box className="tw-mb-2">
            {rJSX_DatePicker(false)}
            {rJSX_LocationFilterDropdown()}
          </Box>
          {rJSX_OrdersTable()}
        </Box>
      )
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = <Box className="tw-p-4">{rJSX_ErrorDisplay()}</Box>
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        {rJSX_PageHeader()}
        {rJSX_PageContent()}
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
