/*

	__          __     _____  _   _ _____ _   _  _____
	\ \        / /\   |  __ \| \ | |_   _| \ | |/ ____|
	\ \  /\  / /  \  | |__) |  \| | | | |  \| | |  __
	\ \/  \/ / /\ \ |  _  /| . ` | | | | . ` | | |_ |
	\  /\  / ____ \| | \ \| |\  |_| |_| |\  | |__| |
	\/  \/_/    \_\_|  \_\_| \_|_____|_| \_|\_____|


	INCLUDED IN DEPLOY PROCESS
	EDITS TO THIS FILE MUST BE MADE IN BOTH DEV AND PROD FILES

*/

export const devOrProdEnvironment = 'dev'
export const baseCloudFunctionUrl = 'https://us-central1-etw-energy-staging.cloudfunctions.net/'
export const searchEnginePrefix = 'staging_'
