///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import axios from 'axios'
import QRCode from 'qrcode'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesOpportunities_Collection,
  DatabaseRef_SalesOpportunityDiscoverySession_Collection,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { StorageRef_GHL_FSD_CSV_Uploads } from 'rfbp_aux/services/storage_endpoints/integrations'
import { Json } from 'rfbp_core/components/code_display'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  generateDatabaseQuery,
  StorageUploadFile,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_NewSalesOpportunity } from './forms/new_sales_opportunity'
import { formInputs_NewSalesOpportunityDiscoverySession } from './forms/opportunity_discovery_session'
import { tableColumns_SalesOpportunities, tableSettings_SalesOpportunities } from './tables/opportunities'
import { tableColumns_OpportunityDiscovery, tableSettings_OpportunityDiscovery } from './tables/opportunity_discovery_sessions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminSalesToolsIndexPage']['key']

// TODO: TEMP
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: '0.5in',
    paddingBottom: '0.5in',
    paddingLeft: '0.0in',
    paddingRight: '0.0in',
  },
  label: {
    // width: '2.625in', // Width of each label
    width: '2.625in', // Width of each label
    height: '1in', // Height of each label
    marginVertical: 0, // No margin on top and bottom
    marginHorizontal: '0.1875in', // Margin on left and right
    padding: 0,
    border: '0px solid #FFF', // Border for each label
    borderRadius: 8, // Rounded corners
    justifyContent: 'center',
    fontSize: 10,
    flexDirection: 'row', // Arrange QR code and address side by side
  },
  address: {
    flex: 1,
    padding: 8,
    textAlign: 'left',
  },
  row: {
    flexDirection: 'row',
    marginBottom: '0.0in', // Space between rows
  },
  qrCode: {
    width: '1in',
    height: '1in',
    marginRight: 5,
  },
})

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_runningUpload, us_setRunningUpload] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Tools', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setRunningUpload(true)
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        let file = files[0]
        if (file != null && typeof file === 'object') {
          promiseArray.push(
            readAsDataURL(file)
              .then((res_RADURL) => {
                readFiles[0] = {
                  file_name: file.name,
                  file: file,
                  data_url: res_RADURL,
                }
              })
              .catch((rej_RADURL) => {
                // Nothing
              }),
          )
        }
        Promise.all(promiseArray).finally(() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              StorageUploadFile(StorageRef_GHL_FSD_CSV_Uploads(res_GCK.clientKey, readFiles[0].file_name), readFiles[0].file, {})
                .then((res_SUF: any) => {
                  console.log('SUCCESS 1')
                  console.log(getProp(res_SUF, 'url', null))
                  let config = {
                    method: 'GET',
                    url: 'https://fsdupload-ghvejdcija-uc.a.run.app',
                    params: {
                      file: getProp(res_SUF, 'url', null),
                    },
                  }
                  axios
                    .request(config)
                    .then((res_A) => {
                      console.log(res_A)
                      resolve(res_A)
                      us_setRunningUpload(false)
                      ur_forceRerender()
                      // Custom Dialog
                      uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: (
                            <Box>
                              <Json data={res_A} />
                            </Box>
                          ),
                          settings: {
                            max_width: 'md',
                          },
                        },
                      })
                    })
                    .catch((rej_A) => {
                      console.log('ERROR 1')
                      console.error(rej_A)
                      us_setRunningUpload(false)
                      ur_forceRerender()
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_A.error })
                      reject(rej_A)
                    })
                })
                .catch((rej_SUF) => {
                  console.log('ERROR 2')
                  console.error(rej_SUF)
                  us_setRunningUpload(false)
                  ur_forceRerender()
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUF.error })
                  reject(rej_SUF)
                })
            })
            .catch((rej_GCK) => {
              console.log('ERROR 3')
              console.error(rej_GCK)
              us_setRunningUpload(false)
              ur_forceRerender()
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject({ success: false, error: rej_GCK.error })
            })
        })
      } else {
        console.log('ERROR 4')
        let error = {
          message: rLIB('Failed to upload file'),
          details: rLIB('Invalid document selection'),
          code: 'ER-D-STI-FOS-01',
        }
        us_setRunningUpload(false)
        ur_forceRerender()
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        reject({ success: false, error: error })
      }
    })
  }

  const createSalesOpportunity = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewSalesOpportunity,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  formSubmittedData.timestamp_created = new Date()
                  formSubmittedData.created_by_key = getProp(formHooks.uc_RootData_ClientUser, 'key', null)
                  formSubmittedData.created_by_name = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                  formSubmittedData.color_config = {
                    min_panel_count: 15,
                    green_cutoff: 7000,
                    yellow_cutoff: 5000,
                  }
                  // TODO: do this on the server side in the future
                  DatabaseAddDocument(DatabaseRef_SalesOpportunities_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      // hit the google and eagleview (prospect) api endpoints
                      cloudFunctionUnauthenticatedRequests({
                        function: 'generateNewOpportunityData',
                        client_key: res_GCK.clientKey,
                        opportunity_key: res_DSMD.key,
                        formSubmittedData: formSubmittedData,
                        generateFlags: {
                          google: true,
                          ev_prospect: false,
                          ev_propose: false,
                        },
                      })
                        .then((res) => {
                          console.log(res)
                        })
                        .catch((rej) => {
                          console.error(rej)
                        })
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.SalesOpportunityViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Sales Opportunity'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_SalesOpportunities = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunities_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createOpportunityDiscovery = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewSalesOpportunityDiscoverySession,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  formSubmittedData.timestamp_created = new Date()
                  formSubmittedData.created_by_key = getProp(formHooks.uc_RootData_ClientUser, 'key', null)
                  formSubmittedData.created_by_name = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                  DatabaseAddDocument(DatabaseRef_SalesOpportunityDiscoverySession_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Sales Opportunity Discovery Session'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_OpportunityDiscovery = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunityDiscoverySession_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewOpportunityButton = (shrink: boolean): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          createSalesOpportunity()
        }}
      >
        {rLIB('New Sales Opportunity')}
      </Button>
    )
    return newButton
  }

  const rJSX_NewOpportunityDiscoveryButton = (shrink: boolean): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          createOpportunityDiscovery()
        }}
      >
        {rLIB('New Opportunity Discovery Session')}
      </Button>
    )
    return newButton
  }

  const rJSX_OpportunitiesTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_SalesOpportunities}
              tableDatabaseEndpoint={tableDatabaseEndpoint_SalesOpportunities}
              tableSettings={tableSettings_SalesOpportunities}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_OpportunityDiscoveryTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_OpportunityDiscovery}
              tableDatabaseEndpoint={tableDatabaseEndpoint_OpportunityDiscovery}
              tableSettings={tableSettings_OpportunityDiscovery}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  // TODO: TEMP
  const generateQRCodeDataUrl = (data: string) => {
    const canvas = document.createElement('canvas')
    // @ts-ignore
    QRCode.toCanvas(canvas, data, { width: 100, height: 100 })
    return canvas.toDataURL('image/png')
  }

  const returnPDFDownloadDocument = (): JSX.Element => {
    let addresses = [
      { address: '1312 Edgewood Ln', city: 'Northbrook', state: 'IL', zip: '60062', url: 'https://www.etwenergy.com' },
      { address: '2837 12th Ave', city: 'Broadview', state: 'IL', zip: '60155', url: 'https://www.etwenergy.com' },
      { address: '947 W 59th St', city: 'Chicago', state: 'IL', zip: '60621', url: 'https://www.etwenergy.com' },
      { address: '1220 Grove Ave', city: 'Park Ridge', state: 'IL', zip: '60068', url: 'https://www.etwenergy.com' },
      { address: '3409 W 38th Pl', city: 'Chicago', state: 'IL', zip: '60632', url: 'https://www.etwenergy.com' },
      { address: '51 Liberty Blvd', city: 'Machesney Park', state: 'IL', zip: '61115', url: 'https://www.etwenergy.com' },
      { address: '1818 Bittersweet Ln', city: 'Mount Prospect', state: 'IL', zip: '60056', url: 'https://www.etwenergy.com' },
      { address: '11808 S Justine St', city: 'Chicago', state: 'IL', zip: '60643', url: 'https://www.etwenergy.com' },
      { address: '9751 S Green St', city: 'Chicago', state: 'IL', zip: '60643', url: 'https://www.etwenergy.com' },
      { address: '19117 Altenburg Rd', city: 'Harvard', state: 'IL', zip: '60033', url: 'https://www.etwenergy.com' },
      { address: '2215 Walnut St', city: 'Waukegan', state: 'IL', zip: '60087', url: 'https://www.etwenergy.com' },
      { address: ' 3330 Stoneybrook Drive', city: 'Champaign', state: 'IL', zip: '61822', url: 'https://www.etwenergy.com' },

      { address: '1312 Edgewood Ln', city: 'Northbrook', state: 'IL', zip: '60062', url: 'https://www.etwenergy.com' },
      { address: '2837 12th Ave', city: 'Broadview', state: 'IL', zip: '60155', url: 'https://www.etwenergy.com' },
      { address: '947 W 59th St', city: 'Chicago', state: 'IL', zip: '60621', url: 'https://www.etwenergy.com' },
      { address: '1220 Grove Ave', city: 'Park Ridge', state: 'IL', zip: '60068', url: 'https://www.etwenergy.com' },
      { address: '3409 W 38th Pl', city: 'Chicago', state: 'IL', zip: '60632', url: 'https://www.etwenergy.com' },
      { address: '51 Liberty Blvd', city: 'Machesney Park', state: 'IL', zip: '61115', url: 'https://www.etwenergy.com' },
      { address: '1818 Bittersweet Ln', city: 'Mount Prospect', state: 'IL', zip: '60056', url: 'https://www.etwenergy.com' },
      { address: '11808 S Justine St', city: 'Chicago', state: 'IL', zip: '60643', url: 'https://www.etwenergy.com' },
      { address: '9751 S Green St', city: 'Chicago', state: 'IL', zip: '60643', url: 'https://www.etwenergy.com' },
      { address: '19117 Altenburg Rd', city: 'Harvard', state: 'IL', zip: '60033', url: 'https://www.etwenergy.com' },
      { address: '2215 Walnut St', city: 'Waukegan', state: 'IL', zip: '60087', url: 'https://www.etwenergy.com' },
      { address: ' 3330 Stoneybrook Drive', city: 'Champaign', state: 'IL', zip: '61822', url: 'https://www.etwenergy.com' },

      { address: '1312 Edgewood Ln', city: 'Northbrook', state: 'IL', zip: '60062', url: 'https://www.etwenergy.com' },
      { address: '2837 12th Ave', city: 'Broadview', state: 'IL', zip: '60155', url: 'https://www.etwenergy.com' },
      { address: '947 W 59th St', city: 'Chicago', state: 'IL', zip: '60621', url: 'https://www.etwenergy.com' },
      { address: '1220 Grove Ave', city: 'Park Ridge', state: 'IL', zip: '60068', url: 'https://www.etwenergy.com' },
      { address: '3409 W 38th Pl', city: 'Chicago', state: 'IL', zip: '60632', url: 'https://www.etwenergy.com' },
      { address: '51 Liberty Blvd', city: 'Machesney Park', state: 'IL', zip: '61115', url: 'https://www.etwenergy.com' },
    ]

    const rows = []
    for (let i = 0; i < addresses.length; i += 3) {
      rows.push(addresses.slice(i, i + 3))
    }

    return (
      <Document>
        <Page
          size="LETTER"
          style={styles.page}
        >
          {rows.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={styles.row}
            >
              {row.map((address, colIndex) => (
                <View
                  key={colIndex}
                  style={styles.label}
                >
                  <View style={styles.address}>
                    <Text>{address.address}</Text>
                    <Text>{address.city}</Text>
                    <Text>
                      {address.state} {address.zip}
                    </Text>
                  </View>
                  <View style={styles.qrCode}>
                    <Image
                      src={generateQRCodeDataUrl(address.url)}
                      style={styles.qrCode}
                    />
                  </View>
                </View>
              ))}
            </View>
          ))}
        </Page>
      </Document>
    )
  }

  const rJSX_RouteButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="contained"
        className="tw-ml-2"
        startIcon={<Icon icon="map-location-dot" />}
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesDoorhangerRoutePage.url())
        }}
      >
        {rLIB('Paper Route')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DownloadButtonProper = (disabled: boolean, loading: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disabled={disabled}
        startIcon={
          loading ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
      >
        {rLIB('Download PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_PDFDownloadButton = (): JSX.Element => {
    let buttonJSX = (
      <PDFDownloadLink
        document={returnPDFDownloadDocument()}
        fileName={'Test.pdf'}
      >
        {({ blob, url, loading, error }) => (loading ? rJSX_DownloadButtonProper(true, loading) : rJSX_DownloadButtonProper(false, loading))}
      </PDFDownloadLink>
    )
    return buttonJSX
  }

  const rJSX_PdfTestContent = (): JSX.Element => {
    let pdfJSX = (
      <>
        {rJSX_PDFDownloadButton()}
        {rJSX_RouteButton()}
      </>
    )
    return pdfJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Tools')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminSalesToolsIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Tools', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Sales Opportunities'),
                  tabUrlKey: 'opportunities',
                  tabButtons: [{ fullJSX: rJSX_NewOpportunityButton(false), minJSX: rJSX_NewOpportunityButton(true), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_OpportunitiesTable()}</Box>,
                },
                {
                  tabHeader: rLIB('Opportunity Discovery'),
                  tabUrlKey: 'opportunity_finder',
                  tabButtons: [{ fullJSX: rJSX_NewOpportunityDiscoveryButton(false), minJSX: rJSX_NewOpportunityDiscoveryButton(true), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_OpportunityDiscoveryTable()}</Box>,
                },
                {
                  tabHeader: rLIB('FSD Upload'),
                  tabUrlKey: 'fsd_upload',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <FileUploadButton
                        multiple={true}
                        accept=".csv"
                        onChange={fileOnSelect}
                        button={{
                          text: rLIB('FSD Upload') as JSX.Element,
                          icon: (
                            <Icon
                              icon="cloud-arrow-up"
                              className="tw-mr-2"
                            ></Icon>
                          ),
                          color: 'info',
                          className: '',
                          variant: 'contained',
                          disabled: us_runningUpload,
                        }}
                        additionalFileUploadParams={{}}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: 'PDF Test',
                  tabUrlKey: 'pdf',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_PdfTestContent()}</Box>,
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
